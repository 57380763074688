import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuthInterceptor, ErrorInterceptor, SafeHtmlPipe, SafeStylePipe } from './helpers';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminComponent } from './components/admin/admin.component';
import { ToolSelectorComponent } from './components/admin/tool-selector/tool-selector.component';
import { SignaleticsComponent } from './components/admin/tools/signaletics/signaletics.component';
import { ReportsComponent } from './components/admin/tools/reports/reports.component';
import { CompanyComponent } from './components/admin/tools/signaletics/company/company.component';
import { DepartmentsComponent } from './components/admin/tools/signaletics/departments/departments.component';
import { PeopleComponent } from './components/admin/tools/signaletics/people/people.component';
import { ServicesComponent } from './components/admin/tools/signaletics/services/services.component';
import { EmployeesComponent } from './components/admin/tools/signaletics/employees/employees.component';
import { PeopleContractComponent } from './components/admin/tools/signaletics/people-contract/people-contract.component';
import { CommonModule, DatePipe } from '@angular/common';
import { MoveContractComponent } from './components/admin/tools/signaletics/move-contract/move-contract.component';
import { ProfilesComponent } from './components/admin/tools/profiles/profiles.component';
import { ProfileEditorComponent } from './components/admin/tools/profiles/profile-editor/profile-editor.component';
import { DelegationsComponent } from './components/admin/tools/delegations/delegations.component';
import { NewDelegComponent } from './components/admin/tools/delegations/new-deleg/new-deleg.component';
import { LogsComponent } from './components/admin/tools/logs/logs.component';
import { PublicHolidaysComponent } from './components/admin/tools/publicHolidays/publicHolidays.component';

@NgModule({
  declarations: [
    AppComponent,
    SafeHtmlPipe,
    SafeStylePipe,
    LoginComponent,
    SignaleticsComponent,
    ToolSelectorComponent,
    ReportsComponent,
    AdminComponent,
    CompanyComponent,
    DepartmentsComponent,
    PeopleComponent,
    ServicesComponent,
    EmployeesComponent,
    PeopleContractComponent,
    MoveContractComponent,
    ProfilesComponent,
    ProfileEditorComponent,
    DelegationsComponent,
    NewDelegComponent,
    LogsComponent,
    PublicHolidaysComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
