<h1>{{dictionary.selectTool}}</h1>

<div class="nav-container noselect" *ngIf="!dataLoading">
    <div class="nav-item" *ngIf="hasUserAccess(19)" routerLink="/admin/signaletics" routerLinkActive="active">
        <span class="icon"><i class="fas fa-address-card"></i></span>
        <span class="label">{{dictionary.signaletics}}</span>
    </div>
    <div class="nav-item" *ngIf="hasUserAccess(4)" routerLink="/admin/profiles" routerLinkActive="active">
        <span class="icon"><i class="fas fa-window-restore"></i></span> <!--icon fa fa-users-->
        <span class="label">{{dictionary.profilesManagement}}</span>
    </div>
    <div class="nav-item" routerLink="/admin/delegations" routerLinkActive="active">
        <span class="icon"><i class="fas fa-handshake"></i></span>
        <span class="label">{{dictionary.delegations}}</span>
    </div>
    <div class="nav-item" *ngIf="hasUserAccess(9)" routerLink="/admin/logs" routerLinkActive="active">
        <span class="icon"><i class="fas fa-book"></i></span>
        <span class="label">{{dictionary.logs}}</span>
    </div>
    <div class="nav-item" *ngIf="hasUserAccess(24)" routerLink="/admin/publicHolidays" routerLinkActive="active">
        <span class="icon"><i class="fas fa-calendar-alt"></i></span>
        <span class="label">{{dictionary.publicHolidays}}</span>
    </div>
    <!-- <div class="nav-item disabled" routerLink="/admin/reports" routerLinkActive="active">
        <span class="icon"><i class="icon fa fa-reports"></i></span>
        <span class="label">{{dictionary.report}}s</span>-
    </div>-->
</div>