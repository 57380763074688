import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Company, Contract, Department, Employee, Item, NodeSelect, People, Profile, Service, SignaleticDict, TreeNode, WindowProfile } from '@app/models';
import { DictService, TmsApiService, ToastService } from '@app/services';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-signaletics',
  templateUrl: './signaletics.component.html',
  styleUrls: ['./signaletics.component.scss']
})
export class SignaleticsComponent implements OnInit {

  @ViewChild('inputSearch') inputSearch: ElementRef;

  companies: any[];
  departments: any[];
  services: any[];
  employees: any[];
  isLoadingTreeview: boolean;
  treeviewRoot: TreeNode;
  selectedNode: TreeNode;
  showInactive: boolean;
  searchText: string;
  serviceId: number;
  newEmployee: Employee;
  nodeCounter: number;
  newContract: Contract;
  changeService: boolean;
  newService: Service;
  newDepartment: Department;
  newCompany: Company;
  departmentId: number;
  curCompleteDict: any;
  isCheckingModule : boolean;
  countWaitingResp : number;
  isReadOnly : boolean;
  employeeInTS : boolean;

  constructor(private tmsApiService: TmsApiService, private toastService: ToastService, private dictService: DictService) {
   }

  ngOnInit(): void {
    this.isLoadingTreeview = false;
    this.showInactive = false;
    this.selectedNode = null;
    this.searchText = "";
    this.nodeCounter = 0;
    this.treeviewRoot = new TreeNode(this.nodeCounter++, [], null, null, "root", true, true);
    this.newEmployee = null;
    this.newContract = null;
    this.changeService = false;
    this.newService = null;
    this.newDepartment = null;
    this.isCheckingModule = false;
    this.countWaitingResp = 0;
    this.isReadOnly = false;
    this.employeeInTS = false;
    this.getAuthorizedWindows();
    this.getTreeviewData();
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent(this.inputSearch.nativeElement, 'input')
      .pipe(
        filter(Boolean),
        debounceTime(250),
        distinctUntilChanged(),
        tap((event) => {
          this.searchText = this.inputSearch.nativeElement.value;
          this.changeSearch()
        })
      )
      .subscribe();
  }

  get searchMod(): boolean {
    return this.searchText.trim().length > 0
  }

  get dictionary(): SignaleticDict {
    return this.dictService.getSignaleticDict();
  }

  checkisOver() {
    if (this.countWaitingResp > 0) {
      this.countWaitingResp--;
    }
    if (this.countWaitingResp == 0) {
      this.isCheckingModule = false;
    }
  }

  getAuthorizedWindows() {
    this.isCheckingModule = true;
    this.tmsApiService.getUserProfile().subscribe((response) => {
      if (response && response.length > 0) {
        let theProfiles: Profile[] = response;
        this.countWaitingResp = theProfiles.length;
        for (const aProfile of theProfiles) {
          this.tmsApiService.getUserProfileWindows(aProfile.profile_id).subscribe((responsePrf) => {
            if (responsePrf && responsePrf.length) {
              let theWindows: WindowProfile[] = responsePrf;
              for (const window of theWindows) {
                if (window.window_id == 19 && window.read_mode_id == 2)  {
                  this.isReadOnly = true;
                  this.countWaitingResp = 0;
                  this.checkisOver();
                }
              }
              this.checkisOver();
            }
          });
        }
      }
    });
  }

  selectNode(aNode: TreeNode) {
    if (this.selectedNode?.objectId == -1 || this.newContract || this.newService || this.newDepartment) {
      let confirm = window.confirm(this.dictionary.changeLine);
      if (confirm) {
        if (this.newContract) {
          this.newContract = null;
        }
        if (this.newEmployee) {
          this.newEmployee = null;
        }
        if (this.newService) {
          this.newService = null;
        }
        if (this.newDepartment) {
          this.newDepartment = null;
        }

        this.deleteNode(this.selectedNode, this.treeviewRoot);
        this.selectedNode = aNode;
        aNode.isOpen = !aNode.isOpen;
      }
    }
    else {
      if (this.selectedNode?.currentType != "employee" && aNode.currentType == "employee") {
        this.serviceId = aNode.parentNode?.objectId;
      }
      this.selectedNode = aNode;
      aNode.isOpen = !aNode.isOpen;
    }
  }

  deleteNode(aNode: TreeNode, parentNode: TreeNode): boolean {
    for (let i: number = 0; i < parentNode.nodes.length; i++) {
      let theCurrentNode = parentNode.nodes[i];

      if (theCurrentNode.id == aNode.id) {
        parentNode.nodes.splice(i, 1);
        return true;
      }
      let result = this.deleteNode(aNode, theCurrentNode);
      if (result) return true;
    }
    return false;
  }

  searchNode(aObjectType: string, aObjectId: number, parentNode: TreeNode): TreeNode {

    if (parentNode.currentType == aObjectType && parentNode.objectId == aObjectId) {
      return parentNode;
    } else {
      for (let i: number = 0; i < parentNode.nodes.length; i++) {
        let theCurrentNode = parentNode.nodes[i];

        if (theCurrentNode.currentType == aObjectType && theCurrentNode.objectId == aObjectId) {
          return theCurrentNode;
        }
        let result = this.searchNode(aObjectType, aObjectId, theCurrentNode);
        if (result) return result;
      }
    }
    return null;
  }

  searchNodes(aObjectType: string, aObjectId: number, parentNode: TreeNode): TreeNode[] {

    let results: TreeNode[] = [];

    for (let i: number = 0; i < parentNode.nodes.length; i++) {
      let theCurrentNode = parentNode.nodes[i];

      if (theCurrentNode.currentType == aObjectType && theCurrentNode.objectId == aObjectId) {
        results.push(theCurrentNode);
      }
      results = results.concat(this.searchNodes(aObjectType, aObjectId, theCurrentNode));

    }
    return results;
  }

  getTreeviewData(oldObj?: NodeSelect) {
    this.isLoadingTreeview = true;

    this.tmsApiService.getGrhOrgData().subscribe((response) => {
      this.buildTreeview(response, oldObj);
      this.isLoadingTreeview = false;
    });
  }

  buildTreeview(data: any, oldObj?: NodeSelect) {
    let companies = data[0];
    let departments = data[1];
    let services = data[2];
    let employees = data[3];

    this.nodeCounter = 0;
    this.treeviewRoot = new TreeNode(this.nodeCounter++, [], null, null, "root", true, true);

    for (const company of companies) {
      this.treeviewRoot.nodes.push(new TreeNode(this.nodeCounter++, [], this.treeviewRoot, new Company(company.company_id, company.company_name, company.company_has_timesheet, company.company_has_hr_interface, company.company_has_parking), "company", false, false));
    }

    for (const dep of departments) {
      for (const com of this.treeviewRoot.nodes) {
        let theCompany: Company = com.currentObject;
        if (dep.company_id == theCompany.id) {
          let theNewDepartment = new Department(dep.department_id, dep.department_name, theCompany.id, theCompany.name, dep.active_flag, theCompany.hasTimesheet, theCompany.hasHRInterface, theCompany.hasParking);
          let theNewNode = new TreeNode(this.nodeCounter++, [], com, theNewDepartment, "department", false, false);
          com.nodes.push(theNewNode);
        }
      }
    }

    for (const ser of services) {
      for (const com of this.treeviewRoot.nodes) {
        for (const dep of com.nodes) {
          let theDepartment: Department = dep.currentObject;
          if (ser.department_id == theDepartment.id) {
            let theNewService = new Service(ser.service_id, ser.service_name, ser.department_id, ser.department_name, ser.company_id, ser.company_name, ser.active_flag, theDepartment.hasTimesheet, theDepartment.hasHRInterface, theDepartment.hasParking);
            let theNewNode = new TreeNode(this.nodeCounter++, [], dep, theNewService, "service", false, false)
            dep.nodes.push(theNewNode);
          }
        }
      }
    }

    for (const emp of employees) {
      for (const com of this.treeviewRoot.nodes) {
        for (const dep of com.nodes) {
          for (const ser of dep.nodes) {
            let theService: Service = ser.currentObject;
            if (emp.service_id == theService.id) {
              let theNewEmployee = new Employee(emp.person_id, emp.external_user_code, emp.fullname, emp.service_id, emp.service_name, emp.department_id, emp.department_name, emp.company_id, emp.company_name, emp.active_flag, theService.hasTimesheet, theService.hasHRInterface, theService.hasParking)
              let theNewNode = new TreeNode(this.nodeCounter++, [], ser, theNewEmployee, "employee", false, false)
              ser.nodes.push(theNewNode);
            }
          }
        }
      }
    }

    if (oldObj) {
      this.filterTreeview();
      this.openPathTreeview(oldObj.objectType, oldObj.objectId);
      this.toastService.pushSucess(this.dictionary.finished + " !");
    }
  }

  filterTreeview(): void {

    if (this.searchMod) {
      //this.selectedNode = null;
      for (const com of this.treeviewRoot.nodes) {
        com.isFiltered = com.nodeTitle.toUpperCase().includes(this.searchText.toUpperCase());
        for (const dep of com.nodes) {
          let theDepartment: Department = dep.currentObject as Department;
          dep.isFiltered = ((dep.nodeTitle.toUpperCase().includes(this.searchText.toUpperCase())) && (theDepartment.activeFlag || this.showInactive));
          for (const ser of dep.nodes) {
            let theService: Service = ser.currentObject as Service;
            ser.isFiltered = ((ser.nodeTitle.toUpperCase().includes(this.searchText.toUpperCase())) && (theService.activeFlag || this.showInactive));
            for (const emp of ser.nodes) {
              let theEmployee: Employee = emp.currentObject as Employee;
              emp.isFiltered = ((emp.nodeTitle.toUpperCase().includes(this.searchText.toUpperCase())) && (theEmployee.activeFlag || this.showInactive));
              if (emp.isFiltered) {
                ser.isFiltered = emp.isFiltered;
                ser.isOpen = emp.isFiltered;
              }
            }
            if (ser.isFiltered) {
              dep.isFiltered = ser.isFiltered;
              dep.isOpen = ser.isFiltered;
            }
          }
          if (dep.isFiltered) {
            com.isFiltered = dep.isFiltered;
            com.isOpen = dep.isFiltered;
          }
        }
      }

    } else {
      for (const com of this.treeviewRoot.nodes) {
        com.isFiltered = false;
        com.isOpen = false;
        for (const dep of com.nodes) {
          dep.isFiltered = false;
          dep.isOpen = false;
          for (const ser of dep.nodes) {
            ser.isFiltered = false;
            ser.isOpen = false
            for (const emp of ser.nodes) {
              emp.isFiltered = false;
              emp.isOpen = false;
            }
          }
        }
      }

      if (this.selectedNode) {
        this.openPathTreeview(this.selectedNode.currentType, this.selectedNode.objectId);
      }

    }
  }

  openPathTreeview(aType: string, anId: number) {

    let theNodesToOpen: TreeNode[] = this.searchNodes(aType, anId, this.treeviewRoot);

    for (const theNodeToOpen of theNodesToOpen) {
      if (theNodeToOpen) {
        theNodeToOpen.isOpen = true;
        let node = theNodeToOpen.parentNode;
        while (node != null) {
          node.isOpen = true;
          node = node.parentNode
        }

        if ((this.selectedNode.currentType == aType && theNodeToOpen.parentNode.currentType == this.selectedNode.parentNode.currentType &&
          theNodeToOpen.parentNode.objectId == this.selectedNode.parentNode.objectId)
          || (this.selectedNode.currentType != aType && theNodeToOpen.currentType == this.selectedNode.parentNode.currentType &&
            theNodeToOpen.objectId == this.selectedNode.parentNode.objectId)) {
          this.selectedNode = theNodeToOpen;

        }
      }
    }
  }

  getCountPeopleCompany(aNode: TreeNode) {
    if(aNode.currentType == "company") {
      //return aNode.nodes.filter(e => (e.currentObject as Department).activeFlag).length;
      return aNode.nodes.filter(e => (e.currentObject as Department).activeFlag).map(e => e.nodes.filter(e => (e.currentObject as Service).activeFlag).map(e => e.nodes.filter(e => (e.currentObject as Employee).activeFlag).length).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0);
    }
    return 0;
  }

  getCountPeopleDepartment(aNode: TreeNode) {
    if(aNode.currentType == "department") {
      //return aNode.nodes.filter(e => (e.currentObject as Service).activeFlag).length;
      return aNode.nodes.filter(e => (e.currentObject as Service).activeFlag).map(e => e.nodes.filter(e => (e.currentObject as Employee).activeFlag).length).reduce((a, b) => a + b, 0);
    }
    return 0;
  }

  getCountPeopleService(aNode: TreeNode) {
    if(aNode.currentType == "service") {
      return aNode.nodes.filter(e => (e.currentObject as Employee).activeFlag).length;
    }
    return 0;
  }

  changeSearch() {
    this.filterTreeview();
  }

  getActive(aNode: TreeNode): TreeNode[] {
    return aNode.nodes.filter((n) => {
      return (n.currentObject as Item).activeFlag || this.showInactive;
    });
  }

  isInactive(aNode: TreeNode): boolean {
    return !(aNode.currentObject as Item).activeFlag;
  }

  initNewContract(aSerId: number, aSerName: string, aDepId: number, aDepName: string, aCompId: number, aCompName: string): void {
    this.newContract = new Contract();
    this.newContract.person_contract_id = -1;
    this.newContract.person_id = -1;
    this.newContract.service_id = aSerId;
    this.newContract.service_name = aSerName;
    this.newContract.department_id = aDepId;
    this.newContract.department_name = aDepName;
    this.newContract.company_id = aCompId;
    this.newContract.company_name = aCompName;
    this.newContract.etp_contract_val = 100;
    this.newContract.prest_init_val = "POT";
  }

  createCompany(): void {
    let theCurrentObject: any = null;
    let theNewNode: TreeNode = null;
    theCurrentObject = this.selectedNode.currentObject as Company;
    theNewNode = new TreeNode(this.nodeCounter++, [], this.treeviewRoot, null, "company", true, false, false);
    theNewNode.currentObject = new Company(-1, "New Company", theCurrentObject.hasTimesheet, theCurrentObject.hasHRInterface, theCurrentObject.hasParking);
    this.treeviewRoot.nodes.push(theNewNode);
    this.newCompany = theNewNode.currentObject as Company;
    //this.companyId = -1;
    if (!theNewNode.parentNode.isOpen) theNewNode.parentNode.isOpen = true;
    this.selectedNode = theNewNode;
  }

  createDepartment(): void {
    let theCurrentObject: any = null;
    let theNewNode: TreeNode = null;
    theCurrentObject = this.selectedNode.currentObject as Company;
    theNewNode = new TreeNode(this.nodeCounter++, [], this.selectedNode, null, "department", true, false, false);
    theNewNode.currentObject = new Department(-1, "New Department", theCurrentObject.id, theCurrentObject.name, true,
      theCurrentObject.hasTimesheet, theCurrentObject.hasHRInterface, theCurrentObject.hasParking);
    this.selectedNode.nodes.push(theNewNode);
    this.newDepartment = theNewNode.currentObject as Department;
    this.serviceId = -1;
    if (!theNewNode.parentNode.isOpen) theNewNode.parentNode.isOpen = true;
    this.selectedNode = theNewNode;
  }

  createService(): void {
    let theCurrentObject: any = null;
    let theNewNode: TreeNode = null;
    theCurrentObject = this.selectedNode.currentObject as Department;
    theNewNode = new TreeNode(this.nodeCounter++, [], this.selectedNode, null, "service", true, false, false);
    theNewNode.currentObject = new Service(-1, "New Service", theCurrentObject.id, theCurrentObject.name, theCurrentObject.company_id, theCurrentObject.company_name, true,
      theCurrentObject.hasTimesheet, theCurrentObject.hasHRInterface, theCurrentObject.hasParking);
    this.selectedNode.nodes.push(theNewNode);
    this.newService = theNewNode.currentObject as Service;
    this.departmentId = -1;
    if (!theNewNode.parentNode.isOpen) theNewNode.parentNode.isOpen = true;
    this.selectedNode = theNewNode;
  }

  createEmployee(): void {
    let theCurrentObject: any = null;
    let theNewNode: TreeNode = null;
    if (this.selectedNode.currentType == "employee") {
      theCurrentObject = this.selectedNode.currentObject as Employee;
      theNewNode = new TreeNode(this.nodeCounter++, null, this.selectedNode.parentNode, null, "employee", true, false, false);
      theNewNode.currentObject = new Employee(-1, "", "Person Person", theCurrentObject.serviceId, theCurrentObject.serviceName,
        theCurrentObject.departmentId, theCurrentObject.departmentName, theCurrentObject.companyId, theCurrentObject.companyName, true,
        theCurrentObject.hasTimesheet, theCurrentObject.hasHRInterface, theCurrentObject.hasParking);
      this.selectedNode.parentNode.nodes.push(theNewNode);
    }
    else if (this.selectedNode.currentType == "service") {
      theCurrentObject = this.selectedNode.currentObject as Service;
      theNewNode = new TreeNode(this.nodeCounter++, null, this.selectedNode, null, "employee", true, false, false);
      theNewNode.currentObject = new Employee(-1, "", "Person Person", theCurrentObject.id, theCurrentObject.name,
        theCurrentObject.departmentId, theCurrentObject.departmentName, theCurrentObject.companyId, theCurrentObject.companyName, true,
        theCurrentObject.hasTimesheet, theCurrentObject.hasHRInterface, theCurrentObject.hasParking);
      this.selectedNode.nodes.push(theNewNode);
    }

    this.newEmployee = theNewNode.currentObject as Employee;
    this.initNewContract(this.newEmployee.serviceId, this.newEmployee.serviceName, this.newEmployee.departmentId,
      this.newEmployee.departmentName, this.newEmployee.companyId, this.newEmployee.companyName);
    this.serviceId = this.newEmployee.serviceId;
    this.selectedNode = theNewNode;
  }

  modifyEmployee(aPeople: People) {
    let theNodes = this.searchNodes("employee", aPeople.person_id, this.treeviewRoot);
    let theSelectedObject: any = null;
    if (theNodes.length) {
      let theNode: TreeNode = null;
      let theChosenEmployee: Employee = null;
      if (this.selectedNode.currentType == "employee") {
        theSelectedObject = this.selectedNode.currentObject as Employee;
        for (const aNode of theNodes) {
          theChosenEmployee = aNode.currentObject as Employee;

          if (aNode.parentNode == this.selectedNode.parentNode) {
            theNode = aNode;
            this.employeeInTS = aPeople.timesheet_flag;
          }
        }
      }
      else if (this.selectedNode.currentType == "service") {
        theSelectedObject = this.selectedNode.currentObject as Service;
        for (const aNode of theNodes) {
          theChosenEmployee = aNode.currentObject as Employee;

          if (aNode.parentNode == this.selectedNode) {
            theNode = aNode;
            this.employeeInTS = aPeople.timesheet_flag;
          }
        }
      }

      if (theChosenEmployee) {
        if (this.selectedNode.currentType == "employee") {
          this.initNewContract(theSelectedObject.serviceId, theSelectedObject.serviceName,
            theSelectedObject.departmentId, theSelectedObject.departmentName, theSelectedObject.companyId, theSelectedObject.companyName);
          this.serviceId = theSelectedObject.serviceId;
        }
        else if (this.selectedNode.currentType == "service") {
          this.initNewContract(theSelectedObject.id, theSelectedObject.name,
            theSelectedObject.departmentId, theSelectedObject.departmentName, theSelectedObject.companyId, theSelectedObject.companyName);
          this.serviceId = theSelectedObject.id;
        }

        if (theNode) {
          this.selectedNode = theNode;
        } else {
          this.changeService = true;
          let theNewNode: TreeNode = null;

          if (this.selectedNode.currentType == "employee") {
            theNewNode = new TreeNode(this.nodeCounter++, null, this.selectedNode.parentNode, null, "employee", true, false, false);
            theNewNode.currentObject = new Employee(theChosenEmployee.personId, theChosenEmployee.externalUserCode, theChosenEmployee.fullname, theSelectedObject.serviceId,
              theSelectedObject.serviceName, theSelectedObject.departmentId, theSelectedObject.departmentName, theSelectedObject.companyId, theSelectedObject.companyName, true,
              theSelectedObject.hasTimesheet, theSelectedObject.hasHRInterface, theSelectedObject.hasParking);
            this.selectedNode.parentNode.nodes.push(theNewNode);
          }
          else if (this.selectedNode.currentType == "service") {
            theNewNode = new TreeNode(this.nodeCounter++, null, this.selectedNode, null, "employee", true, false, false);
            theNewNode.currentObject = new Employee(theChosenEmployee.personId, theChosenEmployee.externalUserCode, theChosenEmployee.fullname, theSelectedObject.id,
              theSelectedObject.name, theSelectedObject.departmentId, theSelectedObject.departmentName, theSelectedObject.companyId, theSelectedObject.companyName, true,
              theSelectedObject.hasTimesheet, theSelectedObject.hasHRInterface, theSelectedObject.hasParking);
            this.selectedNode.nodes.push(theNewNode);
          }

          this.selectedNode = theNewNode;
        }
      }
    }
    else {
      if (aPeople.person_id == null) aPeople.person_id = -1;
      this.employeeInTS = aPeople.timesheet_flag;
      let theNewNode: TreeNode = null;
      if (this.selectedNode.currentType == "employee") {
        theSelectedObject = this.selectedNode.currentObject as Employee;
        theNewNode = new TreeNode(this.nodeCounter++, null, this.selectedNode.parentNode, null, "employee", true, false, false);

        theNewNode.currentObject = new Employee(aPeople.person_id, aPeople.external_code, aPeople.person_name, theSelectedObject.serviceId,
          theSelectedObject.serviceName, theSelectedObject.departmentId, theSelectedObject.departmentName, theSelectedObject.companyId, theSelectedObject.companyName, true,
          theSelectedObject.hasTimesheet, theSelectedObject.hasHRInterface, theSelectedObject.hasParking);
        this.selectedNode.parentNode.nodes.push(theNewNode);
        this.initNewContract(theSelectedObject.serviceId, theSelectedObject.serviceName,
          theSelectedObject.departmentId, theSelectedObject.departmentName, theSelectedObject.companyId, theSelectedObject.companyName);
        this.serviceId = theSelectedObject.serviceId;
      }
      else if (this.selectedNode.currentType == "service") {
        theSelectedObject = this.selectedNode.currentObject as Service;
        theNewNode = new TreeNode(this.nodeCounter++, null, this.selectedNode, null, "employee", true, false, false);

        theNewNode.currentObject = new Employee(aPeople.person_id, aPeople.external_code, aPeople.person_name, theSelectedObject.id,
          theSelectedObject.name, theSelectedObject.departmentId, theSelectedObject.departmentName, theSelectedObject.companyId, theSelectedObject.companyName, true,
          theSelectedObject.hasTimesheet, theSelectedObject.hasHRInterface, theSelectedObject.hasParking);
        this.selectedNode.nodes.push(theNewNode);
        this.initNewContract(theSelectedObject.id, theSelectedObject.name,
          theSelectedObject.departmentId, theSelectedObject.departmentName, theSelectedObject.companyId, theSelectedObject.companyName);
        this.serviceId = theSelectedObject.id;
      }

      this.selectedNode = theNewNode;
    }
  }

  refreshTree(data: NodeSelect) {
    switch (data.objectType) {
      case "employee":
        this.newEmployee = null;
        this.newContract = null;
        break;
      case "service":
        if (this.newService) { this.showInactive = true; }
        this.newService = null;
      case "department":
        if (this.newDepartment) { this.showInactive = true; }
        this.newDepartment = null;
        break;
      case "company":
        if (this.newCompany) { this.showInactive = true; }
        this.newCompany = null;
        break;

      default:
        break;
    }
    this.getTreeviewData(data);
  }

  cancelCompany() {

    for (let index = 0; index < this.treeviewRoot.nodes.length; index++) {
      let theNode = this.treeviewRoot.nodes[index] as TreeNode;
      if (theNode.id == this.selectedNode.id) {
        if (this.newCompany) {
          this.treeviewRoot.nodes.splice(index, 1);
          this.newCompany = null;
        }
        this.selectedNode = null;
        break;
      }
    }
  }

  cancelDepartment() {
    for (const com of this.treeviewRoot.nodes) {
      for (let index = 0; index < com.nodes.length; index++) {
        let theNode = com.nodes[index] as TreeNode;
        if (theNode.id == this.selectedNode.id) {
          if (this.newDepartment) {
            com.nodes.splice(index, 1);
            this.newDepartment = null;
          }
          this.selectedNode = com;
          break;
        }
      }

    }
  }

  cancelService() {
    for (const com of this.treeviewRoot.nodes) {
      for (const dep of com.nodes) {
        for (let index = 0; index < dep.nodes.length; index++) {
          let theNode = dep.nodes[index] as TreeNode;
          if (theNode.id == this.selectedNode.id) {
            if (this.newService) {
              dep.nodes.splice(index, 1);
              this.newService = null;
            }

            this.selectedNode = dep;
            break;
          }
        }
      }
    }
  }

  cancelEmployee() {

    for (const com of this.treeviewRoot.nodes) {
      for (const dep of com.nodes) {
        for (const ser of dep.nodes) {
          for (let index = 0; index < ser.nodes.length; index++) {
            let theNode = ser.nodes[index] as TreeNode;
            if (theNode.id == this.selectedNode.id) {
              if (this.newEmployee || !this.employeeInTS) {
                ser.nodes.splice(index, 1);
                this.newEmployee = null;
              }
              if (this.newContract) {
                this.newContract = null;
                if (this.changeService) {
                  ser.nodes.splice(index, 1);
                  this.changeService = false;
                }
              }

              this.selectedNode = ser;
              break;
            }
          }
        }
      }
    }
  }

}
