import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { Contract, ContractDict, ContractMove, NodeSelect } from '@app/models';
import { DictService, TmsApiService } from '@app/services';

@Component({
  selector: 'app-move-contract',
  templateUrl: './move-contract.component.html',
  styleUrls: ['./move-contract.component.scss']
})
export class MoveContractComponent implements OnInit {

  @Input() contractToMove: Contract;
  @Output() closeMoveContract = new EventEmitter();
  @Output() validateMoveContract = new EventEmitter<NodeSelect>();

  curContractMoves : ContractMove[];
  curContractToMoveId : number;
  isLoadingMoves : boolean;

  constructor(private tmsApiService: TmsApiService, private dictService : DictService) { }

  ngOnInit(): void {
    this.isLoadingMoves = false;
    this.curContractMoves = [];
    this.curContractToMoveId = this.contractToMove.service_id;
    this.getMoveContractDestList(this.contractToMove.person_contract_id);
  }

  get dictionary(): ContractDict {
    return this.dictService.getContractDict();
  }

  getMoveContractDestList(aPersContId: number) {
    this.isLoadingMoves = true;
    this.curContractMoves = [];
    this.tmsApiService.getMoveContractDestList(aPersContId).subscribe((response) => {
      if (response) {
        this.curContractMoves = response;
        this.curContractToMoveId = this.contractToMove.service_id;
      }
      this.isLoadingMoves = false;
    });
  }

  moveContractToService()
  {
    let data : any = {person_contract_id : this.contractToMove.person_contract_id, service_id : this.curContractToMoveId };
    this.tmsApiService.moveContractToService(data).subscribe((response) => {
      if (response) {
        this.validateMoveContract.emit(new NodeSelect(this.contractToMove.person_id, "employee"));
      }
      this.isLoadingMoves = false;
    });
  }

  close() {
    this.curContractToMoveId = -1; 
    this.closeMoveContract.emit();
  }

}
