import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import {  People, PeopleDict } from '@app/models';
import { DictService, TmsApiService } from '@app/services';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-people-contract',
  templateUrl: './people-contract.component.html',
  styleUrls: ['./people-contract.component.scss']
})
export class PeopleContractComponent implements OnInit {

  @ViewChild('inputSearch') inputSearch: ElementRef;

  @Input() archiveFlag: boolean;
  @Output() close = new EventEmitter();
  @Output() modify = new EventEmitter<People>();
  @Output() create = new EventEmitter();

  curPeople: People[];
  selectedPeople: People;
  searchText: string;
  curCreateMode : string;
  peopleLoading : boolean;
  constructor(private tmsApiService: TmsApiService, private dictService : DictService) { }

  ngOnInit(): void {
    this.curPeople = [];
    this.searchText = "";
    this.curCreateMode = "new";
    this.peopleLoading = false;
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent(this.inputSearch.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(250),
        distinctUntilChanged(),
        tap((event) => {
          this.searchText = this.inputSearch.nativeElement.value;
          this.changeSearch()
        })
      )
      .subscribe();
  }

  get searchMod(): boolean {
    return this.searchText.trim().length > 0
  }

  get dictionary(): PeopleDict {
    return this.dictService.getPeopleDict();
  }

  changeSearch() {
    if (this.searchMod) {
      this.filterNameList();
    }
  }

  filterNameList() {
    if (this.searchMod) {
      for (const people of this.curPeople) {
        people.isFiltered = people.person_name.toUpperCase().includes(this.searchText.toUpperCase());
      }
    }
  }

  getPeopleFiltered(): People[] {
    if (this.searchMod) {
      return this.curPeople.filter((p) => {
        return p.isFiltered && (this.archiveFlag || (!this.archiveFlag && !p.archive_flag))
      })
    } else {
      return this.curPeople.filter((p) => {
        return this.archiveFlag || (!this.archiveFlag && !p.archive_flag )
      })
    }
  }

  changeCreateMode() {
    if(this.curCreateMode == "new")
    {
      this.selectPeople(null);
      this.curPeople = [];
    }
    else
    {
      this.getAllEmployees();
    }
  }

  selectPeople(aPeople: People) {
    this.selectedPeople = aPeople;
  }

  getAllEmployees() {
    this.peopleLoading = true;
    this.tmsApiService.getAllEmployees().subscribe((people) => {
     
      this.peopleLoading = false;
      if (people.length) {
        this.curPeople = people.map((p) => {
          p.isFiltered = false;
          return p;
        });

        this.curPeople = this.curPeople.sort((p1: People, p2: People) => {
          if ( p1.person_name < p2.person_name) return -1;
          if ( p1.person_name > p2.person_name) return 1;
          return 0;
        });

      }
    });
  }

  closeWindow() {
    this.close.emit();
  }

  select(aPeople: People) {
    if (aPeople == null)
    {
      this.create.emit();
    }
    else
    {
      this.modify.emit(aPeople);
    }
    
    this.closeWindow();
  }

}
