export class Profile {
    profile_id: number;
    profile_name: string;
    is_deleted_flag: boolean;
    create_date: string
    create_by: number
    mod_date: string;
    mod_by: number;
    person_flag: boolean;
    group_flag: boolean;
    window_flag: boolean;
    isFiltered: boolean;

    constructor() {
        this.profile_id = -1;
        this.profile_name = "";
        this.is_deleted_flag = false;
        this.create_date = "";
        this.create_by = -1;
        this.mod_date = "";
        this.mod_by = -1;
        this.person_flag = false;
        this.group_flag = false;
        this.window_flag = false;
        this.isFiltered = false;
    }

}

export class WindowProfile {
    profile_window_id: number;
    profile_id: number;
    window_id: number;
    read_mode_id: number;
    is_deleted_flag: false;
    create_date: string;
    create_by: number;
    mod_date: string;
    mod_by: number;
    window_name: string;
    window_title: string;

    constructor() { }
}

export class WindowParam {
    window_id: number;
    window_name: string;
    window_title: string;
    profile_flag: number;
    profile_desc: string;
    is_deleted_flag: boolean;
}

export class ProfileDict {

    profilesWindows: string;
    profilesUsers: string;
    onTimeEdition: string;
    enterProfileName: string;
    edit: string;
    save: string;
    userProfiles: string;
    new : string;
    profiles: string;
    linkedWindows: string;
    total : string;
    mode: string;
    readonly: string;
    windows: string;
    onTimeWindows: string;
    onTimeUsers: string;
    users: string;
    linkedProfiles: string;
    profile_s: string;
    profile: string;
    showDeletedProfiles: string;
    showInactiveUsers: string;
    labelSearch : string;

    constructor() {
        this.profilesWindows = "";
        this.profilesUsers = "";
        this.onTimeEdition = "";
        this.enterProfileName = "";
        this.edit = "";
        this.save = "";
        this.userProfiles = "";
        this.new ="";
        this.profiles = "";
        this.linkedWindows = "";
        this.total ="";
        this.mode = "";
        this.readonly = "";
        this.windows = "";
        this.onTimeWindows = "";
        this.onTimeUsers = "";
        this.users = "";
        this.linkedProfiles = "";
        this.profile_s = "";
        this.profile = "";
        this.showDeletedProfiles = "";
        this.showInactiveUsers = "";
        this.labelSearch = "";
    }
}