import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, switchMap } from 'rxjs/operators';
import { UserService } from '@app/services/user.service';
import { AuthenticationService } from '@app/services/authentication.service';
import { ToastService } from '@app/services/toast.service';
import { DictService, TmsApiService } from '@app/services';
import { CompanyDict, LoggedUser, TmsUser } from '@app/models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  hide: boolean;
  loading: boolean;
  moduleDest: string;
  returnUrl: string;
  dictionary: CompanyDict;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private toastService: ToastService,
    private dictService: DictService,
    private tmsApiService: TmsApiService
  ) {
    // redirect to home if already logged in
    if (this.userService.currentUserValue && !this.route.snapshot.queryParams['ac']) {
      this.dictService.loadDict();
      this.router.navigate(['/admin']);
    }

  }

  ngOnInit() {
    this.moduleDest = '/admin';
    this.loading = false;
    this.hide = true;
    this.loginForm = this.formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });

    //AutoLogin 
    const autoconnect = this.route.snapshot.queryParams['ac'];
    if (autoconnect && autoconnect === 'tms') {
      const token = this.route.snapshot.queryParams['t'];
      const module = this.route.snapshot.queryParams['m'];
      if (module) {
        this.moduleDest = '/admin/' + module
      }
      if (token) {
        this.loading = true;
        this.authenticationService.autoLogin(token);

        this.tmsApiService.getUser().subscribe(
          user => {
            this.setUser(user);
          },
          error => {
            this.loading = false;
          });
      }
    }
  }

  get f() { return this.loginForm.controls; }

  submit() {
    if (!this.loading) {
      // stop here if form is invalid
      /*if (this.loginForm.invalid) {
        console.log("Login invalid")
        this.toastService.pushAlert('Please check your credentials');
        return;
      }*/
      this.loading = true;
      this.authenticationService.login(this.f.login.value, window.btoa(this.f.password.value))
        .pipe(first())
        .pipe(
          switchMap(data => {
            if (data) {
              return this.tmsApiService.getUser();
            }
            else {
              return null
            }
          })
        )
        .subscribe(
          user => {
            this.setUser(user);
          },
          error => {
            this.toastService.pushAlert('Please check your credentials');
            this.loading = false;
          });

    }
  }

  private setUser(aUser: TmsUser) {

    let theLoggedUser: LoggedUser = new LoggedUser();
    theLoggedUser.userId = aUser.person_id;
    theLoggedUser.login = aUser.login;
    theLoggedUser.authdata = this.userService.currentUserValue.authdata;
    theLoggedUser.issa = aUser.roles.findIndex(role => role.role_val == "SUPERADMIN") > -1;
    theLoggedUser.language = aUser.language_val;
    theLoggedUser.firstName = aUser.first_name;
    theLoggedUser.lastName = aUser.last_name;
    theLoggedUser.hasTimesheet = aUser.has_timesheet;
    //theLoggedUser.windows = aUser.windows;

    this.userService.setUser(theLoggedUser);
    this.dictService.loadDict();

    //To not display in localStorage
    //theLoggedUser.windows = aUser.windows;

    //this.toastService.pushSucess('Welcome !');
    this.returnUrl = this.route.snapshot.queryParams['returnUrl']
    if (this.returnUrl) {
      this.router.navigateByUrl(this.returnUrl);
    } else {
      this.router.navigate([this.moduleDest]);
    }

  }
}
