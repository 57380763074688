import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ProfileDict } from '@app/models';
import { DictService } from '@app/services';
import { stringify } from 'querystring';

@Component({
  selector: 'app-profile-editor',
  templateUrl: './profile-editor.component.html',
  styleUrls: ['./profile-editor.component.scss']
})
export class ProfileEditorComponent implements OnInit {

  @ViewChild('inputName') inputName: ElementRef;

  @Output() close = new EventEmitter();
  @Output() add = new EventEmitter<string>();

  curName: string;

  constructor(private dictService : DictService) { }

  ngOnInit(): void {
    this.curName = null;
  }

  ngAfterViewInit() {
    this.inputName.nativeElement.focus();
  }

  get dictionary(): ProfileDict {
    return this.dictService.getProfileDict();
  }

  addProfile(): void {
    if (this.curName) {
      this.add.emit(this.curName);
    }
  }

  closeWindow() {
    this.close.emit();
  }


}
