<div id="dlg-cont" class="grh-fullcontainer noselect">
    <div id="dlg-main">
        <div id="dlg-top">
            <div id="dlg-tab">
                <div *ngIf="hasTimesheet || isUserSA" id="dlg-mine" class="dlg-tab-menu" (click)="selectTab('tms')" [class.selected]="curTab == 'tms'">
                    <label>
                        <span>{{dictionary.timesheet}}</span>
                    </label>
                </div>
                <div id="dlg-rights" class="dlg-tab-menu" (click)="selectTab('plan')" [class.selected]="curTab == 'plan'">
                    <label>
                        <span>{{dictionary.menuLabelPlanning}}</span>
                    </label>
                </div>
                
            </div>
            <div id="dlg-admin" *ngIf="isUserSA">
                <div id="dlg-ina">
                    <label for="chk-adm">Admin</label>
                    <input type="checkbox" id="chk-adm" [(ngModel)]="isAdmin"
                        (change)="getData(userId,curTab,displayInactive)">
                </div>
            </div>
        </div>
        <app-people *ngIf="isPeopleDisplayed" [archiveFlag]="true" [managedPeopleFlag]="false"  (close)="closePeople()" (add)="addPeople($event)">
        </app-people>
        <app-new-deleg *ngIf="isNewDelegDisplayed" [delegation]="curDelegation" [isAdmin]="isAdmin"
            (close)="closeNewDeleg()" (save)="saveDeleg($event)">
        </app-new-deleg>
        <div id="dlg-panel">
            <div id="dlg-pnl-top">
                <div id="dlg-left" *ngIf="!isAdmin">
                    <div class="dlg-head-title">
                        <div class="dlg-title-new">
                            <h3 class="title-label">{{dictionary.myDelegates}}</h3>
                            <span id="dlg-new-right" (click)="displayPeople()"> (+) {{dictionary.new}} </span>
                        </div>
                    </div>
                    <div class="dlg-table">
                        <table>
                            <tbody>
                                <tr *ngFor="let delegate of curDelegates">
                                    <td><label>{{delegate.delegate_name}}</label></td>
                                    <td><label>{{delegate.from_date | date : 'dd/MM/yyyy'}} => {{delegate.to_date | date
                                            : 'dd/MM/yyyy'}} </label></td>
                                    <td><label></label></td>
                                    <td class="actions"><span class="action-item"
                                            (click)="delete(delegate.delegation_id,curTab)">
                                            <i class="fa fa-trash"></i>
                                        </span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="dlg-right" *ngIf="!isAdmin">
                    <div class="dlg-head-title">
                        <div class="dlg-title-new">
                            <h3 class="title-label">{{dictionary.mySupplementaryRights}}</h3>
                        </div>
                    </div>
                    <div class="dlg-table">
                        <table>
                            <tbody>
                                <tr *ngFor="let holder of curHolders">
                                    <td><label>{{holder.titulaire_name}}</label></td>
                                    <td><label>{{holder.from_date | date : 'dd/MM/yyyy'}}</label></td>
                                    <td><label> => </label></td>
                                    <td><label>{{holder.to_date | date : 'dd/MM/yyyy'}}</label></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="dlg-cont-adm" *ngIf="isAdmin">
                    <div class="dlg-head-title">
                        <div class="dlg-title-new">
                            <h3 class="title-label">{{dictionary.myDelegates}}</h3>
                            <span id="dlg-new-right" (click)="openNewDeleg()"> (+) {{dictionary.new}} </span>
                        </div>
                        <div id="dlg-ina">
                            <label for="chk-inact">{{dictionary.showInactive}}</label>
                            <input type="checkbox" id="chk-inact" [(ngModel)]="displayInactive"
                                (change)="getData(userId,curTab,displayInactive)">
                            <input type="search" placeholder="{{dictionary.labelSearch}}" #inputSearch>
                        </div>
                    </div>
                    <div class="dlg-table">
                        <table>
                            <thead>
                                <tr class="center-col">
                                    <th (click)="sortTable('titulaire_name', sortOrder)">
                                        <label>{{dictionary.holder}}</label>
                                        <span *ngIf="sortColumn == 'titulaire_name' && !sortOrder" class="caret">
                                            <i class="fa fa-caret-up"></i>
                                        </span>
                                        <span *ngIf="sortColumn == 'titulaire_name' && sortOrder" class="caret">
                                            <i class="fa fa-caret-down"></i>
                                        </span>
                                    </th>
                                    <th (click)="sortTable('delegate_name', sortOrder)">
                                        <label>{{dictionary.delegate}}</label>
                                        <span *ngIf="sortColumn == 'delegate_name' && !sortOrder" class="caret">
                                            <i class="fa fa-caret-up"></i>
                                        </span>
                                        <span *ngIf="sortColumn == 'delegate_name' && sortOrder" class="caret">
                                            <i class="fa fa-caret-down"></i>
                                        </span>
                                    </th>
                                    <th (click)="sortTable('from_date', sortOrder)">
                                        <label>{{dictionary.start}} => {{dictionary.end}}</label>
                                        <span *ngIf="sortColumn == 'from_date' && !sortOrder" class="caret">
                                            <i class="fa fa-caret-up"></i>
                                        </span>
                                        <span *ngIf="sortColumn == 'from_date' && sortOrder" class="caret">
                                            <i class="fa fa-caret-down"></i>
                                        </span>
                                    </th>
                                    <th><label></label></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="center-col" *ngFor="let delegation of getFilteredDelegations()">
                                    <td class="txt-col" [class.deleted]="!delegation.active_flag">
                                        <label>{{delegation.titulaire_name}}</label>
                                    </td>
                                    <td class="txt-col" [class.deleted]="!delegation.active_flag">
                                        <label>{{delegation.delegate_name}}</label>
                                    </td>
                                    <td class="date-col" [class.deleted]="!delegation.active_flag"><label>{{delegation.from_date | date :
                                            'dd/MM/yyyy'}} => {{delegation.to_date | date : 'dd/MM/yyyy'}}</label></td>
                                    <td class="actions">
                                        <span class="action-item"
                                            (click)="delete(delegation.delegation_id,curTab)">
                                            <i class="fa fa-trash"></i>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>