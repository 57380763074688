<div class="fullsize-popup-container">
    <div id="ppl-main">
        <div id="ppl-header">
            <span class="ppl-header-title">
                <img>
                <label>{{dictionary.searchingForAPerson}}</label>
            </span>
            <button (click)="closeWindow()">X</button>
        </div>
        <div id="ppl-search">
            <input type="search" placeholder={{dictionary.labelSearch}} #inputSearch>
        </div>
        <div id="ppl-list">
            <div id="ppl-title">
                <label>{{dictionary.employeesList}}</label>
            </div>
            <div id="ppl-names" class="noselect">
                <div class="local-loader" *ngIf="peopleLoading">
                    <img src="./assets/img/loader.gif" alt="loading...">
                </div>
                <table *ngIf="!peopleLoading">
                    <thead>
                        <tr>
                            <th>{{dictionary.rptName}}</th>
                            <th>{{dictionary.archived}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let people of getPeopleFiltered()" [class.inactive]="people.archive_flag" [class.selected]="people.person_id == selectedPeople?.person_id" (click)="selectPeople(people)">
                            <td>{{people.person_name}}</td>
                            <td><input type="checkbox" [(ngModel)]="people.archive_flag" disabled></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div id="ppl-bottom">
            <label for="chk-archive"><input type="checkbox" id="chk-archive" [(ngModel)]="archiveFlag" (change)="getEmployees(archiveFlag)">{{dictionary.tmgShowInactPpl}}</label>
            <button (click)="select(selectedPeople)">{{dictionary.select}}</button>
        </div>
    </div>
</div>