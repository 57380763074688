import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ParamProfile, Person, PersonParamProfile, Profile, ProfileDict, WindowParam, WindowProfile } from '@app/models';
import { DictService, TmsApiService } from '@app/services';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss']
})
export class ProfilesComponent implements OnInit {

  @ViewChild('inputSearch') inputSearch: ElementRef;

  //PROFILE-WINDOW
  curProfileList: Profile[];
  curProfileWinList: WindowProfile[];
  curWindowList: WindowParam[];
  curTab: string;
  searchText: string;
  isLoadingProfile: boolean;
  isLoadingProfileWindows: boolean;
  isLoadingWindows: boolean;
  curProfile: Profile;
  curWindowProfile: WindowProfile;
  curWindowParam: WindowParam;
  displayProfileEditor: boolean;
  deletedProfile: boolean;

  //PROFILE-PERSON
  curPersonList: Person[];
  curPersonParamProfList: PersonParamProfile[];
  curParamProfileList: ParamProfile[];
  isLoadingPerson: boolean;
  isLoadingPersParamProf: boolean;
  isLoadingParamProfile: boolean;
  curPerson: Person;
  curPersParamProf: PersonParamProfile;
  curParamProfile: ParamProfile;
  showInactiveUsers: boolean;

  constructor(private tmsApiService: TmsApiService, private dictService: DictService) { }

  ngOnInit(): void {
    this.curTab = null;
    this.searchText = "";
    //PROFILE-WINDOW
    this.curProfileList = [];
    this.curProfileWinList = [];
    this.curWindowList = [];
    this.curProfile = null;
    this.curWindowProfile = null;
    this.curWindowParam = null;
    this.isLoadingProfile = false;
    this.isLoadingProfileWindows = false;
    this.isLoadingWindows = false;
    this.displayProfileEditor = false;
    this.deletedProfile = false;
    this.selectTab("winProfile");
    //PROFILE-PERSON
    this.curPersonList = [];
    this.curPersonParamProfList = []
    this.curParamProfileList = [];
    this.isLoadingPerson = false;
    this.isLoadingPersParamProf = false;
    this.isLoadingParamProfile = false;
    this.curPerson = null;
    this.curPersParamProf = null;
    this.curParamProfile = null;
    this.showInactiveUsers = false;

  }

  ngAfterViewInit() {
    // server-side search
    fromEvent(this.inputSearch.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(250),
        distinctUntilChanged(),
        tap((event) => {
          this.searchText = this.inputSearch.nativeElement.value;
          this.changeSearch()
        })
      )
      .subscribe();
  }

  get searchMod(): boolean {
    return this.searchText.trim().length > 0
  }

  get dictionary(): ProfileDict {
    return this.dictService.getProfileDict();
  }

  changeSearch() {
    if (this.searchMod) {
      if (this.curTab == "winProfile") {
        this.filterProfileList();
      }
      else {
        this.filterPersonList();
      }

    }
  }

  //PROFILE-WINDOW
  filterProfileList() {
    for (const profile of this.curProfileList) {
      profile.isFiltered = profile.profile_name.toUpperCase().includes(this.searchText.toUpperCase());
    }
  }

  getFilteredProfileList() {
    if (this.searchMod) {
      return this.curProfileList.filter((p) => {
        return p.isFiltered;
      })
    } else {
      return this.curProfileList;
    }
  }

  public selectProfile(aProf: Profile) {
    this.curProfile = aProf;
    this.getProfileWindowsList(this.curProfile.profile_id);
    this.getWindowsList(this.curProfile.profile_id);
  }

  public selectTab(aTabName: string) {
    this.curTab = aTabName;

    if (this.searchMod) {
      this.searchText = "";
      this.inputSearch.nativeElement.value = "";
    }

    if (this.curTab == 'winProfile') {
      this.getProfileList(this.deletedProfile);
    }
    else {
      this.getPersonList(this.showInactiveUsers);
    }
  }

  public displayNew() {
    if (!this.displayProfileEditor) this.displayProfileEditor = true;
  }

  public closeEditor() {
    this.displayProfileEditor = false;
  }

  public addNewProfile(aName: string) {
    this.closeEditor();
    this.addProfile(aName);
  }

  public getNonSelectedWindow(): WindowParam[] {
    return this.curWindowList.filter((w: WindowParam) => {
      return w.profile_flag == 1;
    });
  }

  //PROFILE-PERSON

  filterPersonList() {
    for (const person of this.curPersonList) {
      person.isFiltered = person.person_name.toUpperCase().includes(this.searchText.toUpperCase());
    }
  }

  getFilteredPersonList() {
    if (this.searchMod) {
      return this.curPersonList.filter((p) => {
        return p.isFiltered;
      })
    } else {
      return this.curPersonList;
    }
  }

  public selectPerson(aPers: Person) {
    this.curPerson = aPers;
    this.getPersonParamProfile(this.curPerson.person_id);
    this.getParamProfiles(this.curPerson.person_id);
  }

  public getNonSelectedParamProfile(): ParamProfile[] {
    return this.curParamProfileList.filter((pp: ParamProfile) => {
      return pp.person_flag == false;
    });
  }

  //API CALLS

  //PROFILES-WINDOW

  getProfileList(aFlag: boolean): void {
    this.isLoadingProfile = true;
    this.tmsApiService.getProfileList(aFlag).subscribe((response) => {
      if (response.length) {
        this.curProfileList = response;

        this.curProfileList = this.curProfileList.sort((p1: Profile, p2: Profile) => {
          if (p1.profile_name < p2.profile_name) return -1;
          if (p1.profile_name > p2.profile_name) return 1;
          return 0;
        });

        this.changeSearch();

        this.selectProfile(this.curProfileList[0]);
      }
      this.isLoadingProfile = false;
    });
  }

  getProfileWindowsList(aProfId: number): void {
    this.isLoadingProfileWindows = true;
    this.curProfileWinList = [];
    this.tmsApiService.getProfileWindowsList(aProfId).subscribe((response) => {
      if (response.length) {
        this.curProfileWinList = response;
      }
      this.isLoadingProfileWindows = false;
    });
  }

  getWindowsList(aProfId: number): void {
    this.isLoadingWindows = true;
    this.curWindowList = [];
    this.tmsApiService.getWindowsList(aProfId).subscribe((response) => {
      if (response.length) {
        this.curWindowList = response;
      }
      this.isLoadingWindows = false;
    });
  }

  addWindowProfile(aWindowParam: WindowParam): void {
    let data: any = {
      window_id: aWindowParam.window_id,
      profile_id: this.curProfile.profile_id
    }
    this.tmsApiService.postAddWindowPrf(data).subscribe((response) => {
      if (response) {
        this.getProfileWindowsList(this.curProfile.profile_id);
        this.getWindowsList(this.curProfile.profile_id);
      }
    });
  }

  removeWindowProfile(aWindowProfile: WindowProfile): void {
    let data: any = {
      profile_window_id: aWindowProfile.profile_window_id
    }
    this.tmsApiService.postRemoveWindowPrf(data).subscribe((response) => {
      if (response) {
        this.getProfileWindowsList(this.curProfile.profile_id);
        this.getWindowsList(this.curProfile.profile_id);
      }
    });
  }

  addProfile(aName: string): void {
    let data: any = {
      profile_name: aName
    }

    this.tmsApiService.postAddProfile(data).subscribe((response) => {
      if (response) {
        this.getProfileList(false);
      }
    });
  }

  updateReadMode(winProfile: WindowProfile): void {
    let data: any = {
      read_mode_id: winProfile.read_mode_id,
      profile_window_id: winProfile.profile_window_id
    }

    this.tmsApiService.postUpdateReadMode(data).subscribe((response) => {
      if (response) {
        this.getProfileWindowsList(this.curProfile.profile_id);
      }
    });

  }

  //PROFILES-PERSON

  getPersonList(aFlag: boolean): void {
    this.isLoadingPerson = true;
    this.tmsApiService.getPrfPplList(aFlag).subscribe((response) => {
      if (response.length) {
        this.curPersonList = response;
        this.curPersonList = this.curPersonList.sort((p1: Person, p2: Person) => {
          if (p1.person_name < p2.person_name) return -1;
          if (p1.person_name > p2.person_name) return 1;
          return 0;
        });

        this.changeSearch();

        this.selectPerson(this.curPersonList[0]);
      }
      this.isLoadingPerson = false;
    });
  }

  getPersonParamProfile(aPersId: number): void {
    this.isLoadingPersParamProf = true;
    this.curParamProfileList = [];
    this.tmsApiService.getPersAssocProfiles(aPersId).subscribe((response) => {
      if (response) {
        this.curPersonParamProfList = response;
      }
      this.isLoadingPersParamProf = false;
    });
  }

  getParamProfiles(aPersId: number): void {
    this.isLoadingParamProfile = true;
    this.curParamProfileList = [];
    this.tmsApiService.getPersProfiles(aPersId).subscribe((response) => {
      if (response.length) {
        this.curParamProfileList = response;

        this.curParamProfileList = this.curParamProfileList.sort((p1: ParamProfile, p2: ParamProfile) => {
          if (p1.profile_name < p2.profile_name) return -1;
          if (p1.profile_name > p2.profile_name) return 1;
          return 0;
        });

      }
      this.isLoadingParamProfile = false;
    });
  }

  addPersonParam(aParamProfile: ParamProfile): void {
    let data: any = {
      profile_id: aParamProfile.profile_id,
      person_id: this.curPerson.person_id
    }
    this.tmsApiService.postAddPersPrf(data).subscribe((response) => {
      if (response) {
        this.getPersonParamProfile(this.curPerson.person_id);
        this.getParamProfiles(this.curPerson.person_id);
      }
    });
  }

  removePersonParam(aPersonParamProf: PersonParamProfile): void {
    let data: any = {
      person_profile_id: aPersonParamProf.person_profile_id
    }
    this.tmsApiService.postRemovePersPrf(data).subscribe((response) => {
      if (response) {
        this.getPersonParamProfile(this.curPerson.person_id);
        this.getParamProfiles(this.curPerson.person_id);
      }
    });
  }

}
