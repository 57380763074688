import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { TmsApiService, UserService } from '@app/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RightsGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private userService: UserService,
    private tmsApiService: TmsApiService
  ) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    switch (childRoute.routeConfig.path) {
      case 'signaletics':
        /*if(this.userService.currentUserValue.windows.indexOf(19)>-1){
          return true;
        } else {
          this.router.navigate(['/admin']);
          return false;
        }*/
        return this.tmsApiService.canAccessWindow(19).pipe(map(response => {
          if (response) {
            // Consume data here
            return true;
          }
          this.router.navigate(['/admin']);
          return false;
        }
        ));

      case 'reports':
        return true

      case 'profiles':
        return this.tmsApiService.canAccessWindow(4).pipe(map(response => {
          if (response) {
            // Consume data here
            return true;
          }
          this.router.navigate(['/admin']);
          return false;
        }
        ));

      case 'logs':
        return this.tmsApiService.canAccessWindow(9).pipe(map(response => {
          if (response) {
            // Consume data here
            return true;
          }
          this.router.navigate(['/admin']);
          return false;
        }));
        
        case 'publicHolidays':
          return this.tmsApiService.canAccessWindow(24).pipe(map(response => {
            if (response) {
              return true;
            }
            this.router.navigate(['/admin']);
            return false;
          }));

      case 'delegations':
        return true;

      case 'tools':
        return true

      default:
        return false
    }
  }

}
