<div id="dep-main" *ngIf="dataLoading">
    <div class="loader">
        <img src="./assets/img/loader.gif" alt="loading...">
    </div>
</div>
<app-people *ngIf="isPeopleDisplayed" [archiveFlag]="true" [managedPeopleFlag]="false" (close)="closePeople()" (add)="addPeople($event)">
</app-people>
<div id="dep-main" class="noselect" *ngIf="!dataLoading">
    <div id="dep-top">
        <div id="dep-left">
            <div id="dep-data">
                <div id="dep-sheet" class="grh-section dep-item">
                    <h2 class="grh-section-title">
                        <label>
                            <span>{{dictionary.sheets}}</span>
                        </label>
                    </h2>
                    <div class="grh-section-content" *ngIf="!curDepartmentInfos">
                        <span>No data ! </span>
                    </div>
                    <div class="grh-section-content" *ngIf="curDepartmentInfos">
                        <div class="dep-field">
                            <label>{{dictionary.rptName}}</label>
                            <input type="text" [disabled]="isRO" [(ngModel)]="curDepartmentInfos.department_name">
                        </div>
                        <div class="dep-field">
                            <label>{{dictionary.abreviation}}</label>
                            <input type="text" [disabled]="isRO" [(ngModel)]="curDepartmentInfos.department_shortname">
                        </div>
                        <div class="dep-field">
                            <label>{{dictionary.responsible}}</label>
                            <select [disabled]="isRO" [(ngModel)]="curDepartmentInfos.responsible_id">
                                <option *ngFor="let resp of curResponsible" [ngValue]="resp.person_id" (click)="test(resp.person_id)">
                                    {{resp.person_name}} 
                                </option>
                            </select>
                            <label for="chk-archived">{{dictionary.archived}}</label>
                            <input type="checkbox" id="chk-archived" [disabled]="isRO" [(ngModel)]="curDepartmentInfos.archive_flag">
                        </div>
                    </div>
                </div>
                <div id="dep-mails" class="grh-section dep-item" *ngIf="hasTS">
                    <h2 class="grh-section-title">
                        <label>
                            <span>{{dictionary.mailPeriodEnd}}</span>
                        </label>
                    </h2>
                    <div class="grh-section-content" *ngIf="!curValidateurN2 || !curValidateurN1">
                        <span>No data ! </span>
                    </div>
                    <div class="grh-section-content" *ngIf="curValidateurN2 && curValidateurN1">
                        <div class="dep-header">
                            <label for="chk-val2-mail">{{dictionary.mailReminderVal2}}</label>
                            <input type="checkbox" id="chk-val2-mail" [disabled]="isRO" [(ngModel)]="curValidateurN2.send_mail_flag">
                        </div>
                        <div class="dep-field" *ngIf="curValidateurN2.send_mail_flag" [disabled]="isRO">
                            <label>{{dictionary.delayValLimit}}</label>
                            <input type="number" min="0" [(ngModel)]="curValidateurN2.nb_days" [disabled]="isRO">
                            <label>{{dictionary.labelDays}}</label>
                        </div>
                        <div class="dep-field" *ngIf="curValidateurN2.send_mail_flag">
                            <label>{{dictionary.frequency}}</label>
                            <select [disabled]="isRO" [(ngModel)]="curValidateurN2.mail_frequency">
                                <option value="ONCE">{{dictionary.once}}</option>
                                <option value="EVERYDAY">{{dictionary.everyday}}</option>
                            </select>
                        </div>
                        <div class="dep-field" *ngIf="curValidateurN2.send_mail_flag">
                            <label for="chk-val2-sup">{{dictionary.onlyIfSup}}</label>
                            <input type="checkbox" id="chk-val2-sup" [disabled]="isRO" [(ngModel)]="curValidateurN2.only_if_sup_flag">
                        </div>

                        <div class="dep-header">
                            <label for="chk-val1-mail">{{dictionary.mailAllSrvVal}}</label>
                            <input type="checkbox" id="chk-val1-mail" [disabled]="isRO" [(ngModel)]="curValidateurN1.send_mail_flag">
                        </div>
                        <div class="dep-field" *ngIf="curValidateurN1.send_mail_flag">
                            <label>{{dictionary.recipient}}</label>
                            <select [disabled]="isRO" [(ngModel)]="curValidateurN1.dest_type">
                                <option value="GDT">{{dictionary.timeManagers}}</option>
                                <option value="CTR">{{dictionary.controllers}}</option>
                                <option value="GDTCTR">{{dictionary.GDTCTR}}</option>
                            </select>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div id="dep-right">
            <div id="dep-ppl">
                <div id="dep-time" class="grh-section dep-item">
                    <h2 class="grh-section-title" (click)="toggleSection('dep-time')">
                        <label>
                            <span *ngIf="!isSectionOpened('dep-time')" class="caret">
                                <i class="fa fa-caret-right"></i>
                            </span>
                            <span *ngIf="isSectionOpened('dep-time')" class="caret">
                                <i class="fa fa-caret-down"></i>
                            </span>
                            <span>{{dictionary.timeManagers}}</span>
                        </label>
                        <div class="actions">
                            <span class="action-item" *ngIf="!isRO" (click)="showPeople('TIME'); $event.stopPropagation();"> (+) {{dictionary.new}}
                            </span>
                        </div>
                    </h2>
                    <div class="grh-section-content" *ngIf="!curDepartmentRoleTime">
                        <span>No data ! </span>
                    </div>
                    <div class="grh-section-content" *ngIf="curDepartmentRoleTime && isSectionOpened('dep-time')">
                        <div class="list">
                            <div class="list-item" *ngFor="let time of curDepartmentRoleTime">
                                <div class="data">{{time.person_name}}</div>
                                <div class="actions">
                                    <span class="action-item" *ngIf="!isRO" (click)="deleteRole(time,'TIME')"><i
                                            class="fa fa-trash"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="dep-cont" class="grh-section dep-item">
                    <h2 class="grh-section-title" (click)="toggleSection('dep-cont')">
                        <label>
                            <span *ngIf="!isSectionOpened('dep-cont')" class="caret"><i
                                    class="fa fa-caret-right"></i></span>
                            <span *ngIf="isSectionOpened('dep-cont')" class="caret"><i
                                    class="fa fa-caret-down"></i></span>
                            <span>{{dictionary.controllers}}</span>
                        </label>
                        <div class="actions">
                            <span class="action-item" *ngIf="!isRO"  (click)="showPeople('CTR'); $event.stopPropagation();"> (+) {{dictionary.new}}
                            </span>
                        </div>
                    </h2>
                    <div class="grh-section-content" *ngIf="!curDepartmentRoleCTR">
                        <span>No data ! </span>
                    </div>
                    <div class="grh-section-content" *ngIf="curDepartmentRoleCTR && isSectionOpened('dep-cont')">
                        <div class="list">
                            <div class="list-item" *ngFor="let cont of curDepartmentRoleCTR">
                                <div class="data">{{cont.person_name}}</div>
                                <div class="actions">
                                    <span class="action-item" *ngIf="!isRO"  (click)="deleteRole(cont,'CTR')"><i
                                            class="fa fa-trash"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="dep-hrad" class="grh-section dep-item">
                    <h2 class="grh-section-title" (click)="toggleSection('dep-hrad')">
                        <label>
                            <span *ngIf="!isSectionOpened('dep-hrad')" class="caret"><i
                                    class="fa fa-caret-right"></i></span>
                            <span *ngIf="isSectionOpened('dep-hrad')" class="caret"><i
                                    class="fa fa-caret-down"></i></span>
                            <span>{{dictionary.adminsRH}}</span>
                        </label>
                        <div class="actions">
                            <span class="action-item" *ngIf="!isRO"  (click)="showPeople('ADMINRH'); $event.stopPropagation();"> (+)
                                {{dictionary.new}} </span>
                        </div>
                    </h2>
                    <div class="grh-section-content" *ngIf="!curDepartmentRoleHR">
                        <span>No data ! </span>
                    </div>
                    <div class="grh-section-content" *ngIf="curDepartmentRoleHR && isSectionOpened('dep-hrad')">
                        <div class="list">
                            <div class="list-item" *ngFor="let hrad of curDepartmentRoleHR">
                                <div class="data">{{hrad.person_name}}</div>
                                <div class="actions">
                                    <span class="action-item" *ngIf="!isRO"  (click)="deleteRole(hrad,'ADMINRH')"><i
                                            class="fa fa-trash"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="dep-bottombar">
        <div id="left-btn-grp" class="button-group">
            <button [disabled]="isRO" *ngIf="!newDepartment" (click)="newService()">{{dictionary.newService}}</button>
            <button *ngIf="newDepartment"(click)="cancelAction()">{{dictionary.cancel}}</button>
            <button [disabled]="isRO" (click)="saveDepartment()">{{dictionary.tmgSave}}</button>
            <button *ngIf="!newDepartment && isUserSA" (click)="delete()">{{dictionary.titleDelete}}</button>
        </div>
    </div>
</div>