export class People {
    archive_flag: boolean;
    person_id: number;
    person_name: string;
    timesheet_flag: boolean;
    external_code: string;
    isFiltered: boolean;

    constructor() {
        this.archive_flag = false;
        this.person_id = -1;
        this.person_name = '';
        this.timesheet_flag = false;
        this.external_code = "";
        this.isFiltered = false;
    }

}

export class PeopleDict {
    searchingForAPerson: string;
    labelSearch:string;
    newPerson: string;
    selectAPersonInTheList: string;
    employeesList: string;
    rptName: string;
    HRCode: string;
    inTimesheet: string;
    archived: string;
    tmgShowInactPpl: string;
    select: string;

    constructor() {
        this.searchingForAPerson = "";
        this.labelSearch = "";
        this.newPerson = "";
        this.selectAPersonInTheList = "";
        this.employeesList = "";
        this.rptName = "";
        this.HRCode = "";
        this.inTimesheet = "";
        this.archived = "";
        this.tmgShowInactPpl = "";
        this.select = "";

    }
}
