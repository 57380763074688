import { Role } from "./role";

export class LoggedUser {
    userId: number;
    login: string;
    roleVal: string;
    authdata?: string;
    issa : boolean;
    language : string;
    firstName : string;
    lastName : string;
    hasTimesheet: boolean;
    //windows: number[];

    constructor() {
        this.issa = false;
        this.hasTimesheet = false;
        //this.windows = [];
    }
}

export class User {
    userId: number;
    login: string;
    roleVal: string;

    constructor() {
        this.userId = -1;
        this.login = '';
        this.roleVal = 'USER';
    }
}

export class TmsUser {
    comma_separator_flag: boolean;
    company_id: number;
    connection_sync_flag: boolean;
    department_id: number;
    email_address: string;
    first_name: string;
    language_val: string;
    last_name: string;
    login: string;
    period_type: string;
    person_id: number;
    roles: Role[];
    windows: number[];
    service_id: number;
    session_token: string;
    sort_type: string;
    timesheet_flag: boolean;
    has_timesheet: boolean;
    tms_connection_mode_id: number;

    constructor() {
        this.windows = [];
    }

}


export class UserProfile {
    profile_id: number;
}

export class UserProfileWindows {
    window_id: number[];
}