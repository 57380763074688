import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Delegation, DelegationDict, People } from '@app/models';
import { DictService } from '@app/services';

@Component({
  selector: 'app-new-deleg',
  templateUrl: './new-deleg.component.html',
  styleUrls: ['./new-deleg.component.scss']
})
export class NewDelegComponent implements OnInit {

  @Input() delegation: Delegation;
  @Input() isAdmin: boolean;
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter<Delegation>();

  curFromDate: string;
  curToDate: Date;
  isPeopleDisplayed: boolean;
  inputType: string;

  constructor(private datepipe: DatePipe, private dictService : DictService) { }

  ngOnInit(): void {
    this.curFromDate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.curToDate = null;
    this.isPeopleDisplayed = false;
    this.inputType = "";
  }

  get dictionary(): DelegationDict {
    return this.dictService.getDelegationDict();
  }

  choosePeople(aType: string) {
    this.inputType = aType;
    this.isPeopleDisplayed = true;
  }

  closePeople() {
    this.isPeopleDisplayed = false;
  }

  addPeople(aPeople: People) {
    if (this.inputType == "titulaire") {
      this.delegation.titulaire_id = aPeople.person_id;
      this.delegation.titulaire_name = aPeople.person_name;
    }
    else {
      this.delegation.delegate_id = aPeople.person_id;
      this.delegation.delegate_name = aPeople.person_name;
    }
    this.isPeopleDisplayed = false;
  }

  closeWindow() {
    this.close.emit();
  }

  select() {
    if (this.curToDate && this.delegation.titulaire_name && this.delegation.delegate_name ) {
      this.delegation.from_date = this.datepipe.transform(this.curFromDate, "yyyy-MM-dd");
      this.delegation.to_date = this.datepipe.transform(this.curToDate, "yyyy-MM-dd");
      this.save.emit(this.delegation);
    }
    else {
      window.alert("Vous n'avez pas rempli la date de fin.")
    }
  }
}
