export class LogDict {
    logType: string;
    people: string;
    year: string;
    period: string;
    from: string;
    to: string;

    constructor() {
        this.logType="";
        this.people="";
        this.year="";
        this.period="";
        this.from="";
        this.to="";
    }
}