import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { People, Period, Responsible, ServiceGrid, ServiceHeader, ServiceInfos, ServiceMail, ServiceMember, SupplementParam, Task, NodeSelect, Service, ServiceTM, ServiceDict } from '@app/models';
import { DictService, TmsApiService, UserService } from '@app/services';


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  @Input() serviceId: number;
  @Input() newService: Service;
  @Input() hasTS: boolean;
  @Input() isRO : boolean;
  @Output() createEmployee = new EventEmitter();
  @Output() updateEmployee = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() refreshTree = new EventEmitter<any>();

  curServiceHeader: ServiceHeader;
  curServiceInfos: ServiceInfos;
  curServiceTime: ServiceTM[];
  curServiceGrid: ServiceGrid[];
  curServiceMember: ServiceMember[];
  curServiceMail: ServiceMail[];
  curMailEncoding: ServiceMail;
  curMailValidation: ServiceMail;
  curServiceTask: Task[];
  curSupplementParams: SupplementParam;
  curSupplementPeriod: Period[];
  curResponsible: Responsible[];
  curYear: number;
  selectedResp: Responsible;
  selectedPeriod: Period;
  openedSection: string[];
  isPeopleDisplayed: boolean;
  deleteTime: ServiceTM[];
  isPeopleContractDisplayed: boolean;
  isLoadingServiceHeader: boolean;
  isLoadingServiceInfos: boolean;
  isLoadingServiceTime: boolean;
  isLoadingServiceGrid: boolean;
  isLoadingServiceMember: boolean;
  isLoadingServiceMail: boolean;
  isLoadingYearPeriods: boolean;
  isLoadingServiceTasks: boolean;
  isLoadingSupParams: boolean;
  isLoadingSupPeriods: boolean;
  isLoadingResponsible: boolean;
  //hasTimesheet: boolean;
  restGDTToAdd: number;
  restGDTToDel: number;
  countWaitingCommits: number;

  constructor(private tmsApiService: TmsApiService, private userService: UserService, private dictService: DictService) {
    this.curServiceInfos = null;
    this.openedSection = [];
  }

  ngOnInit(): void {
    this.toggleSection("ser-various");
    this.isPeopleDisplayed = false;
    this.isPeopleContractDisplayed = false;
    this.curServiceHeader = null;
    this.curServiceTime = [];
    this.curServiceGrid = [];
    this.curServiceMember = [];
    this.curServiceMail = [];
    this.curMailEncoding = new ServiceMail();
    this.curMailValidation = new ServiceMail();
    this.curServiceTask = [];
    this.curSupplementPeriod = [];
    this.curResponsible = [];
    this.deleteTime = [];
    this.isLoadingServiceHeader = false;
    this.isLoadingServiceInfos = false;
    this.isLoadingServiceTime = false;
    this.isLoadingServiceGrid = false;
    this.isLoadingServiceMember = false;
    this.isLoadingServiceMail = false;
    this.isLoadingYearPeriods = false;
    this.isLoadingServiceTasks = false;
    this.isLoadingSupParams = false;
    this.isLoadingSupPeriods = false;
    this.isLoadingResponsible = false;
    this.restGDTToAdd = 0;
    this.restGDTToDel = 0;
    this.countWaitingCommits = 0;
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName === 'serviceId') {
        if (this.newService == null && this.serviceId > -1) {
          this.openedSection = [];
          this.getServices(null, this.serviceId);
        }
        else {
          this.initNew();
        }
      }
    }
  }

  public get isUserSA(): boolean {
    return this.userService.isSa;
  }

  public get dataLoading(): boolean {
    return (this.isLoadingServiceHeader || this.isLoadingServiceInfos || this.isLoadingServiceTime || this.isLoadingServiceGrid
      || this.isLoadingServiceMember || this.isLoadingServiceMail || this.isLoadingYearPeriods || this.isLoadingServiceTasks
      || this.isLoadingSupParams || this.isLoadingSupPeriods || this.isLoadingResponsible);
  }

  get dictionary(): ServiceDict {
    return this.dictService.getServiceDict();
  }

  initNew() {
    this.openedSection = [];
    this.curServiceInfos = new ServiceInfos();
    this.curServiceInfos.department_id = this.newService.departmentId;
    this.curServiceHeader = null;
    this.curServiceMail = [];
    this.curMailEncoding = new ServiceMail();
    this.curMailValidation = new ServiceMail();
    this.curServiceTime = [];
    this.curServiceGrid = [];
    this.curServiceMember = [];
    this.curServiceTask = [];
    this.curSupplementParams = new SupplementParam();
    this.curSupplementPeriod = [];
    this.openedSection = [];
    this.getResponsible();
  }

  //PEOPLE

  closePeople() {
    this.isPeopleDisplayed = false;
  }
  showPeople() {
    this.isPeopleDisplayed = true;
  }

  addPeople(aPerson: People) {
    let theNewTime: ServiceTM = new ServiceTM(aPerson.person_id, aPerson.person_name, true);
    this.curServiceTime.push(theNewTime);
    this.openSection("ser-time")
  }

  deleteRole(aPerson: ServiceTM) {
    let confirm = window.confirm(this.dictionary.confirmDeleteRole + " " + this.dictionary.timeManagers.toLowerCase());
    if (confirm) {
      this.deleteTime.push(aPerson);
      this.curServiceTime.splice(this.curServiceTime.indexOf(aPerson), 1);
    }
  }

  //PEOPLE CONTRACT

  closePeopleContract() {
    this.isPeopleContractDisplayed = false;
  }
  showPeopleContract() {
    this.isPeopleContractDisplayed = true;
  }

  createNewEmployee() {
    this.createEmployee.emit();
  }

  modifyEmployee(aPeople: People) {
    this.updateEmployee.emit(aPeople);
  }

  toggleSection(aSectionName: string): void {
    let i = this.openedSection.indexOf(aSectionName);
    if (i < 0) {
      this.openedSection.push(aSectionName);
    } else {
      this.openedSection.splice(i, 1);
    }
  }

  openSection(aSectionName: string): void {
    if (!this.isSectionOpened(aSectionName)) {
      this.openedSection.push(aSectionName);
    }
  }

  closeSection(aSectionName: string): void {
    let i = this.openedSection.indexOf(aSectionName);
    if (i > -1) {
      this.openedSection.splice(i, 1);
    }
  }

  isSectionOpened(aSectionName: string): boolean {
    return this.openedSection.indexOf(aSectionName) > -1;
  }

  checkFields(): string {

    if (this.curServiceInfos.service_name == "" || this.curServiceInfos.service_name == null) return this.dictionary.fillServiceName
    if (this.curServiceInfos.responsible_id == null || this.curServiceInfos.responsible_id <= 0) return this.dictionary.serviceManagerNotSpecified
    return "";
  }

  saveService() {
    let errorMessage: string = this.checkFields();

    if (errorMessage != "") {
      window.alert(errorMessage);
    }
    else {
      this.tmsApiService.getServUnicity(this.curServiceInfos.department_id, this.curServiceInfos.service_name, this.curServiceInfos.service_id).subscribe((response) => {
        if (response.length) {
          if (!response[0].ok_flag) {
            window.alert(this.dictionary.nameAlreadyExist);
          }
          else {
            let theData: any = {
              service_id: this.curServiceInfos.service_id,
              service_name: this.curServiceInfos.service_name,
              service_desc: this.curServiceInfos.service_desc,
              department_id: this.curServiceInfos.department_id,
              section_num: this.curServiceInfos.section_num,
              responsible_id: this.curServiceInfos.responsible_id,
              period_type_val: this.curServiceInfos.period_type_val,
              next_period_flag: this.curServiceInfos.next_period_flag,
              next_period_val: this.curServiceInfos.next_period_val,
              next_period_type_val: this.curServiceInfos.next_period_type_val,
              period_flag: this.curServiceInfos.period_flag,
              pointing_flag: this.curServiceInfos.pointing_flag,
              am_from_time: this.curServiceInfos.am_from_time,
              am_to_time: this.curServiceInfos.am_to_time,
              pm_from_time: this.curServiceInfos.pm_from_time,
              pm_to_time: this.curServiceInfos.pm_to_time,
              fact_length_flag: this.curServiceInfos.fact_length_flag,
              presta_flag: this.curServiceInfos.presta_flag,
              only_sup_presta_flag: this.curServiceInfos.only_sup_presta_flag,
              validation_level: this.curServiceInfos.validation_level,
              archive_flag: this.curServiceInfos.archive_flag,
              is_service_automanaged: this.curServiceInfos.is_service_automanaged,
              mailsParamsValidation: this.curMailValidation,
              mailsParamsEncoding: this.curMailEncoding
            }

            this.postSaveService(theData);
          }
        }
      });
    }
  }

  delete() {
    let confirm = window.confirm(this.dictionary.deleteServiceQuestion);
    if (confirm) {
      this.postDeleteService(this.curServiceInfos.service_id, this.curServiceInfos.department_id);
    }
  }

  cancelAction() {
    this.cancel.emit();
  }

  //API CALLS

  getSerHeader(aSerId: number): void {
    this.isLoadingServiceHeader = true;
    this.tmsApiService.getSerHeader(aSerId).subscribe((response) => {
      if (response.length) {
        this.curServiceHeader = response[0];
      }
      this.isLoadingServiceHeader = false;
    });
  }

  getSerInfos(aSerId: number): void {
    this.isLoadingServiceInfos = true;
    this.tmsApiService.getSerInfos(aSerId).subscribe((response) => {
      if (response.length) {
        this.curServiceInfos = response[0][0];

        if (this.curServiceInfos.period_type_val == "4WEEK") { //Values are "1YEAR" or "4WEEK"
          this.curServiceInfos.period_type_desc = this.dictionary.fourWeeksPeriod;
        } else {
          this.curServiceInfos.period_type_desc = this.dictionary.oneYearPeriod;
        }

        this.curServiceMail = response[1];
        for (const mail of this.curServiceMail) {
          if (mail.mail_type.indexOf('FINISH') >= 0) {
            this.curMailEncoding = mail
          }
          else {
            this.curMailValidation = mail
          }
        }

        this.curServiceInfos.am_from_time = this.curServiceInfos.am_from_time == null ? "00:00" : this.curServiceInfos.am_from_time.substring(11, 16);
        this.curServiceInfos.am_to_time = this.curServiceInfos.am_to_time == null ? "00:00" : this.curServiceInfos.am_to_time.substring(11, 16);
        this.curServiceInfos.pm_from_time = this.curServiceInfos.pm_from_time == null ? "00:00" : this.curServiceInfos.pm_from_time.substring(11, 16);
        this.curServiceInfos.pm_to_time = this.curServiceInfos.pm_to_time == null ? "00:00" : this.curServiceInfos.pm_to_time.substring(11, 16);

      }
      this.isLoadingServiceInfos = false;
    });
  }

  getSerMembers(aSerId: number): void {
    this.isLoadingServiceMember = true;
    this.tmsApiService.getSerMembers(aSerId).subscribe((response) => {
      if (response.length) {
        this.curServiceMember = response;
      }
      this.isLoadingServiceMember = false;
    });
  }

  getSerTasks(aSerId: number): void {
    this.isLoadingServiceTasks = true;
    this.tmsApiService.getSerTasks(aSerId).subscribe((response) => {
      if (response.length) {
        this.curServiceTask = response;
      }
      this.isLoadingServiceTasks = false;
    });
  }

  getSerTime(aSerId: number): void {
    this.isLoadingServiceTime = true;
    this.tmsApiService.getSerGDTs(aSerId).subscribe((response) => {
      if (response.length) {
        this.curServiceTime = response;
        this.toggleSection("ser-time");
      }
      this.isLoadingServiceTime = false;
    });
  }

  getSerGrid(aSerId: number): void {
    this.isLoadingServiceGrid = true;
    this.tmsApiService.getSerGrid(aSerId).subscribe((response) => {
      if (response.length) {
        this.curServiceGrid = response;
      }
      this.isLoadingServiceGrid = false;
    });
  }

  getSupParams(aSerList: any, aSerId: number): void {
    this.isLoadingSupParams = true;
    this.tmsApiService.getSupParams(aSerList, aSerId).subscribe((response) => {
      if (response) {
        this.curSupplementParams = response;
      }
      this.isLoadingSupParams = false;
    });
  }

  postServiceSupplementsParameters(data: any, aServId: number): void {
    this.tmsApiService.postServiceSupplementsParameters(data).subscribe((response) => {
      if (response) {
        this.getServices(null, aServId);
        this.refreshTree.emit(new NodeSelect(aServId, "service"));
      }
    });
  }


  getSupPeriods(aYear: number): void {
    this.isLoadingResponsible = true;
    this.tmsApiService.getSupPeriods(aYear).subscribe((response) => {
      if (response.length) {
        this.curSupplementPeriod = response;
      }
      this.isLoadingResponsible = false;
    });
  }

  getResponsible(): void {
    this.isLoadingResponsible = true;
    this.tmsApiService.getResp().subscribe((response) => {
      if (response.length) {
        this.curResponsible = response;
      }
      this.isLoadingResponsible = false;
    });
  }

  /*getCurYearPeriod(): void {
    this.isLoadingYearPeriods = true;
    this.tmsApiService.getCurYearPeriod().subscribe((response) => {
      if (response) {
        this.curYear = response.year_val;
        this.getSupPeriods(this.curYear);
      }
      else {
        let currentDate : Date = new Date();
        this.curYear = currentDate.getFullYear();
      }
      this.isLoadingYearPeriods = false;
    });
  }*/

  getServUnicity(aDepId: number, aSerName: string, aSerId: number) {
    this.tmsApiService.getServUnicity(aDepId, aSerName, aSerId).subscribe((response) => {
      if (response) {

      }
    });
  }

  saveServiceSuppParams(aServId: number) {
    let theData: any = {
      service_id: aServId,
      work_hours_flag: this.curSupplementParams.work_hours_flag,
      overtime_flag: this.curSupplementParams.overtime_flag,
      bank_holiday_flag: this.curSupplementParams.bank_holiday_flag,
      night_hours_flag: this.curSupplementParams.night_hours_flag,
      on_call_hours_flag: this.curSupplementParams.on_call_hours_flag,
      work_hours_on_call_flag: this.curSupplementParams.work_hours_on_call_flag,
      sunday_hours_flag: this.curSupplementParams.sunday_hours_flag,
      nights_flag: this.curSupplementParams.nights_flag,
      hpylone_flag: this.curSupplementParams.hpylone_flag,
      mpylone_flag: this.curSupplementParams.mpylone_flag,
      fmaj_flag: this.curSupplementParams.fmaj_flag,
    }
    let paramSup: any = { paramSup: theData };
    this.postServiceSupplementsParameters(paramSup, aServId);
  }

  manageServiceTime(aServId: number) {

    this.restGDTToAdd = this.curServiceTime.filter(time => time.to_add == true).length;
    this.restGDTToDel = this.deleteTime.length;
    this.countWaitingCommits = this.curServiceTime.filter(time => time.to_add == true).length + this.deleteTime.length;

    if (this.countWaitingCommits == 0) {
      this.saveServiceSuppParams(aServId);
    }
    else {
      for (const aTime of this.curServiceTime) {
        if (aTime.to_add == true) {
          this.postAddServTime(aTime.person_id, aServId);
        }
      }

      for (const aTime of this.deleteTime) {
        this.postDeleteServTime(aTime.person_id, aServId);
      }
    }
  }

  checkisOver(aServId: number) {
    this.countWaitingCommits--;
    if (this.countWaitingCommits == 0) {
      this.saveServiceSuppParams(aServId);
    }
  }

  postSaveService(aService: any) {
    this.tmsApiService.postSaveService(aService).subscribe((response) => {
      if (response) {
        let theNewServId: number = response[0].service_id;
        this.manageServiceTime(theNewServId);
      }
    });
  }

  postDeleteService(aServId: any, aDepId: number) {
    this.tmsApiService.postDeleteService({ service_id: aServId }).subscribe((response) => {
      if (response) {
        this.refreshTree.emit(new NodeSelect(aDepId, "department"));
      }
    });
  }

  postAddServTime(aPersId: number, aServId: number) {
    let theData: any = {
      person_id: aPersId,
      department_id: this.curServiceInfos.department_id,
      service_id: aServId
    }
    this.tmsApiService.postAddSrvGDT(theData).subscribe((response) => {
      if (response) {
        this.checkisOver(aServId);
      }
    });
  }

  postDeleteServTime(aPersId: number, aServId: number) {
    let theData: any = {
      person_id: aPersId,
      service_id: this.curServiceInfos.service_id
    }
    this.tmsApiService.postDeleteSrvGDT(theData).subscribe((response) => {
      if (this.restGDTToDel > 0) this.restGDTToDel--;
      if (response) {
        this.checkisOver(aServId);
      }
    });
  }

  public getServices(aSerList: any, aSerId: number): void {
    //this.getCurYearPeriod();
    this.getSerHeader(aSerId);
    this.getSerInfos(aSerId);
    this.getSerMembers(aSerId)
    this.getSerTasks(aSerId);
    this.getSerTime(aSerId);
    this.getSerGrid(aSerId);
    this.getSupParams(aSerList, aSerId);
    this.getResponsible();
  }

}
