<div class="fullsize-popup-container">
    <div id="ppc-main">
        <div id="ppc-header">
            <span class="ppc-header-title">
                <img>
                <label>{{dictionary.searchingForAPerson}}</label>
            </span>
            <button (click)="closeWindow()">X</button>
        </div>
        <div id="ppc-search">
            <label><input type="radio" name="newPerson" [(ngModel)]="curCreateMode" value="new" (change)="changeCreateMode()"> {{dictionary.newPerson}}</label>
            <label><input type="radio" name="existingPerson" [(ngModel)]="curCreateMode" value="existing" (change)="changeCreateMode()">{{dictionary.selectAPersonInTheList}}</label>
            <input type="search" placeholder="{{dictionary.labelSearch}}" [disabled]="curCreateMode=='new'" #inputSearch>
        </div>
        <div id="ppc-list">
            <div id="ppc-title">
                <label>{{dictionary.employeesList}}</label>
            </div>
            <div id="ppc-names" class="noselect">
                
                <div class="local-loader" *ngIf="peopleLoading">
                    <img src="./assets/img/loader.gif" alt="loading...">
                </div>
                
                <table *ngIf="!peopleLoading">
                    <thead>
                        <tr>
                            <th>{{dictionary.rptName}}</th>
                            <th>{{dictionary.HRCode}}</th>
                            <th class="centered">{{dictionary.inTimesheet}}</th>
                            <th class="centered">{{dictionary.archived}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let people of getPeopleFiltered()" [class.inactive]="people.archive_flag" [class.selected]="selectedPeople && (people == selectedPeople)" (click)="selectPeople(people)">
                            <td>{{people.person_name}}</td>
                            <td>{{people.external_code}}</td>
                            <td class="centered"><input type="checkbox" [(ngModel)]="people.timesheet_flag" disabled></td>
                            <td class="centered"><input type="checkbox" [(ngModel)]="people.archive_flag" disabled></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div id="ppc-bottom">
            <label for="chk-archive"><input type="checkbox" id="chk-archive" [(ngModel)]="archiveFlag">{{dictionary.tmgShowInactPpl}}</label>
            <button (click)="select(selectedPeople)" [disabled]="curCreateMode=='existing' && selectedPeople==null">{{dictionary.select}}</button>
        </div>
    </div>
</div>