<div class="fullsize-popup-container">
    <div id="mvc-main">
        <div id="mvc-header">
           <label>{{dictionary.moveContract}} </label> 
        </div>
        <div id="mvc-content">
            <label> {{dictionary.department}}/{{dictionary.service}}</label>
            <select [(ngModel)]="curContractToMoveId">
                <option *ngFor="let contract of curContractMoves" [ngValue]="contract.service_id">{{contract.service_name}}</option>
            </select>
        </div>
        <div id="mvc-bottom">
            <button (click)="close()">{{dictionary.cancel}}</button>
            <button [disabled]="curContractToMoveId==contractToMove.service_id" (click)="moveContractToService()">{{dictionary.validate}}</button>
        </div>
    </div>
</div>
