import { Data } from "@angular/router";

export class Delegate {
	delegation_id: number;
	delegate_id: number;
	from_date: string;
	to_date: string;
	delegate_name: string;
	active_flag: boolean;

	constructor() { }
}

export class Holder {
	delegation_id: number;
	titulaire_id: number;
	from_date: string;
	to_date: string;
	titulaire_name: string;
	active_flag: boolean;

	constructor() { }
}

export class Delegation {
	delegation_id: number;
	delegate_id: number;
	delegate_name: string;
	titulaire_id: number;
	titulaire_name: string;
	from_date: string;
	to_date: string;
	active_flag: boolean;
	isFiltered: boolean;
	constructor() { }
}

export class DelegationDict {
	timesheet: string;
	menuLabelPlanning: string;
	myDelegates: string;
	mySupplementaryRights: string;
	new: string;
	holder: string;
	delegate: string;
	start: string;
	end: string;
	newRightsDelegation: string;
	from: string;
	until: string;
	tmgSave: string;
	showInactive : string;
	labelSearch : string;
	deleteDelegQuestion : string;
	
	constructor() {
		this.timesheet = "";
		this.menuLabelPlanning = "";
		this.myDelegates = "";
		this.mySupplementaryRights = "";
		this.new = "";
		this.holder = "";
		this.delegate = "";
		this.start = "";
		this.end = "";
		this.newRightsDelegation = "";
		this.from = "";
		this.until = "";
		this.tmgSave = "";
		this.showInactive = "";
		this.labelSearch = "";
		this.deleteDelegQuestion ="";
	}
}