import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoggedUser } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUserSubject: BehaviorSubject<LoggedUser>;
  public currentUser: Observable<LoggedUser>;


  constructor() {
    this.currentUserSubject = new BehaviorSubject<LoggedUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): LoggedUser {
    return this.currentUserSubject.value;
  }

  /*
  get isAdm(): boolean {
    return this.currentUserSubject.value.roleVal === 'ADMIN' || this.currentUserSubject.value.roleVal === 'SUPERADMIN';
  }
  get isSa(): boolean {
    return this.currentUserSubject.value.roleVal === 'SUPERADMIN';
  }
  */

  get isSa(): boolean {
    return this.currentUserSubject.value.issa;
  }

  get hasTimesheet(): boolean {
    return this.currentUserSubject.value.hasTimesheet;
  }

  get userId(): number {
    return this.currentUserSubject.value.userId;
  }

  get language(): string {
    return this.currentUserSubject.value.language;
  }

  get fullName(): string {
    return this.currentUserSubject.value.firstName + " " + this.currentUserSubject.value.lastName;
  }

  private setCookie(cname, cvalue, exhours) {
    const d = new Date();
    d.setTime(d.getTime() + (exhours * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  private getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setUser(aUser: LoggedUser) {
    localStorage.setItem('currentUser', JSON.stringify(aUser));
    //this.setCookie("authdata",aUser.authdata,4);
    this.currentUserSubject.next(aUser);
  }

  setIsSA(aIsSA: boolean) {
    this.currentUserSubject.value.issa = aIsSA;
    localStorage.setItem('currentUser', JSON.stringify(this.currentUserSubject.value));
    //this.setCookie("authdata",aUser.authdata,4);
    this.currentUserSubject.next(this.currentUserSubject.value);
  }

  getUserData() {
    let usrData = localStorage.getItem('userData');
    if (usrData) {
      return JSON.parse(localStorage.getItem('userData'));
    }
    return null;
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    //this.setCookie("authdata","",0);
    this.currentUserSubject.next(null);
    //window.location.reload();
    window.location.replace(location.pathname);
  }

}
