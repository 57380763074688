import { Task, TaskDetail } from ".";

export class Contract {
    am_from_time: string;
    am_to_time: string;
    company_id: number;
    company_name: string;
    contract_status_desc: string;
    contract_status_id: number;
    create_by: number;
    create_date: string;
    del_enabled_flag: boolean;
    department_id: number;
    department_name: string;
    enabled_flag: boolean;
    etp_contract_val: number;
    from_date: string;
    function_desc: string;
    is_deleted_flag: boolean;
    master_enabled_flag: boolean;
    master_flag: boolean;
    matricule_nr: string;
    mod_by: number;
    mod_date: string;
    order_val: number;
    person_contract_id: number;
    person_id: number;
    pm_from_time: string;
    pm_to_time: string;
    pointing_flag: boolean;
    pointing_service_flag: boolean;
    prest_function_id: number;
    prest_init_val: string;
    prest_period_flag: boolean;
    prest_task_id: number;
    service_am_from_time: string;
    service_am_to_time: string;
    service_id: number;
    service_name: string;
    service_pm_from_time: string;
    service_pm_to_time: string;
    task_desc: string;
    to_date: string;
    to_date_modified: boolean;
    shift_work_flag: boolean;
    //taskList: TaskDetail[];

    constructor() {
        this.am_from_time = null;
        this.am_to_time = null;
        this.company_id = -1;
        this.company_name = "";
        this.contract_status_desc = "";
        this.contract_status_id = -1;
        this.create_by = -1;
        this.create_date = null;
        this.del_enabled_flag = false;
        this.department_id = -1;
        this.department_name = "";
        this.enabled_flag = false;
        this.etp_contract_val = 0;
        this.from_date = null;
        this.function_desc = "";
        this.is_deleted_flag = false;
        this.master_enabled_flag = false;
        this.master_flag = false;
        this.matricule_nr = "";
        this.mod_by = -1;
        this.mod_date = null;
        this.order_val = -1;
        this.person_contract_id = -1;
        this.person_id = -1;
        this.pm_from_time = null;
        this.pm_to_time = null;
        this.pointing_flag = false;
        this.pointing_service_flag = false;
        this.prest_function_id = -1;
        this.prest_init_val = "";
        this.prest_period_flag = false;
        this.prest_task_id = null;
        this.service_am_from_time = null;
        this.service_am_to_time = null;
        this.service_id = -1;
        this.service_name = "";
        this.service_pm_from_time = null;
        this.service_pm_to_time = null;
        this.task_desc = "";
        this.to_date = null;
        this.to_date_modified = false;
        this.shift_work_flag = false;
        //this.taskList = [];
    }

}

export class ContractMove {
    service_id: number;
    service_name: string;

    constructor() { }
}

export class ContractDict {
    moveContract: string;
    department: string;
    service : string;
    cancel: string;
    validate: string;

    constructor() {
        this.moveContract = "";
        this.department = "";
        this.service = "";
        this.cancel = "";
        this.validate = "";
    }
}