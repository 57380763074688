import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ConnectionMode, Contract, Employee, EmployeeAutoComp, EmployeeDict, EmployeeGrid, EmployeeInfos, EmployeeSupplementParam, NodeSelect, ParkPool, People, Signaletic, SignaleticHist, Task, TaskDetail, TmsUser, Toast } from '@app/models';
import { DictService, TmsApiService, ToastService, UserService } from '@app/services';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {

  @Input() employeeId: number;
  @Input() serviceId: number;
  @Input() extCode: string;
  @Input() newEmployee: Employee;
  @Input() newContract: Contract;
  @Input() hasTS: boolean
  @Input() hasHR: boolean;
  @Input() hasPark: boolean;
  @Input() isRO : boolean;
  @Output() create = new EventEmitter();
  @Output() update = new EventEmitter<People>();
  @Output() cancel = new EventEmitter();
  @Output() refreshTree = new EventEmitter<any>();

  formEmployeeInfo: FormGroup;
  //formContractInfo : FormGroup;
  openedSection: string[];
  curEmployeeInfo: EmployeeInfos;
  curEmployeeSuppParams: EmployeeSupplementParam[];
  curContracts: Array<Contract>;
  curSigHistory: SignaleticHist[];
  curSignaletic: Signaletic;
  curTaskPersonGrid: Task[];
  curPersonGrid: EmployeeGrid[];
  //curDetailTask: TaskDetail[];
  curConnectionModes: ConnectionMode[];
  curParkPools: ParkPool[];
  displayPasswordField: boolean;
  isPeopleContractDisplayed: boolean;
  isLoadingPersonInfo: boolean;
  isLoadingPersonSuppParams: boolean;
  isLoadingAutocompletePersonInfo: boolean;
  isLoadingPPlContract: boolean;
  isLoadingSignaletic: boolean;
  isLoadingTask: boolean;
  isLoadingPersonGrid: boolean;
  isLoadingConnectionList: boolean;
  isLoadingUser: boolean;
  isLoadingParkPools: boolean;
  contractRestCount: number;
  isMoveContractDisplayed: boolean;
  contractToMove: Contract;
  pwdValue: string;

  contractStatusInvalid: boolean[] = [];
  contractFromDateInvalid: boolean[] = [];
  contractMasterFlagInvalid: boolean[] = [];
    
  get employeeInfo_firstname(): FormControl {
    return this.formEmployeeInfo.get('firstname') as FormControl;
  }

  get employeeInfo_lastname(): FormControl {
    return this.formEmployeeInfo.get('lastname') as FormControl;
  }

  get employeeInfo_email(): FormControl {
    return this.formEmployeeInfo.get('email') as FormControl;
  }

  get employeeInfo_externalemail(): FormControl {
    return this.formEmployeeInfo.get('externalemail') as FormControl;
  }

  get employeeInfo_login(): FormControl {
    return this.formEmployeeInfo.get('login') as FormControl;
  }

  get employeeInfo_sexval(): FormControl {
    return this.formEmployeeInfo.get('sexval') as FormControl;
  }

  get employeeInfo_titleid(): FormControl {
    return this.formEmployeeInfo.get('titleid') as FormControl;
  }

  get employeeInfo_matriculenr(): FormControl {
    return this.formEmployeeInfo.get('matriculenr') as FormControl;
  }

  get employeeInfo_externalflag(): FormControl {
    return this.formEmployeeInfo.get('externalflag') as FormControl;
  }

  get employeeInfo_externalcode(): FormControl {
    return this.formEmployeeInfo.get('externalcode') as FormControl;
  }

  get employeeInfo_Tel(): FormControl{
    return this.formEmployeeInfo.get('Tel') as FormControl;
  }

  constructor(
    private tmsApiService: TmsApiService, 
    private datepipe: DatePipe, 
    private userService: UserService, 
    private dictService: DictService, 
    private fb: FormBuilder,
    private toastService : ToastService
  ) {
    this.openedSection = [];
    this.curEmployeeInfo = null;
    this.curEmployeeSuppParams = [];
    this.curContracts = [];
    this.curSigHistory = [];
    this.pwdValue = "";   
  }

  ngOnInit(): void {
    this.curSignaletic = null;
    this.curTaskPersonGrid = [];
    this.curPersonGrid = [];
    this.curConnectionModes = [];
    this.curParkPools = [];
    this.displayPasswordField = false;
    this.isPeopleContractDisplayed = false;
    this.isLoadingPersonInfo = false;
    this.isLoadingPersonSuppParams = false;
    this.isLoadingAutocompletePersonInfo = false;
    this.isLoadingPPlContract = false;
    this.isLoadingSignaletic = false;
    this.isLoadingTask = false;
    this.isLoadingPersonGrid = false;
    this.isLoadingConnectionList = false;
    this.isLoadingUser = false;
    this.isLoadingParkPools = false;
    this.contractRestCount = 0;
    this.isMoveContractDisplayed = false;
    this.contractToMove = null;
    this.pwdValue = "";
    this.getConModeList();
    this.getUser();

    this.formEmployeeInfo = this.fb.group({
      lastname: [{value : this.curEmployeeInfo?.last_name, disabled : this.isRO}, Validators.required],
      firstname: [{value : this.curEmployeeInfo?.first_name, disabled : this.isRO}, Validators.required],
      email: [{value : this.curEmployeeInfo?.email_address, disabled : this.isRO}, Validators.required],
      externalemail: [{value : this.curEmployeeInfo?.external_email_address, disabled : this.isRO}, ],
      login: [{value : this.curEmployeeInfo?.login, disabled : this.isRO}, Validators.required],
      sexval: [{value : this.curEmployeeInfo?.sex_val, disabled : this.isRO}, ],    
      titleid: [{value : this.curEmployeeInfo?.title_id, disabled : this.isRO}, ],
      matriculenr: [{value : this.curEmployeeInfo?.matricule_nr, disabled : this.isRO}, Validators.required],
      externalflag: [{value : this.curEmployeeInfo?.external_flag, disabled : this.isRO}, ],
      externalcode: [{value : this.curEmployeeInfo?.external_code, disabled : this.isRO}, ],
      Tel: [{value : this.curEmployeeInfo?.Tel, disabled : this.isRO}, ]
    });
  }

  initNew() {
    this.openedSection = [];
    this.toggleSection("emp-serv");
    this.curEmployeeInfo = new EmployeeInfos();
    this.curEmployeeInfo.person_id = -1;
    this.curEmployeeInfo.sex_val = "H";
    this.curEmployeeInfo.title_id = 2;
    this.curEmployeeInfo.tms_connection_mode_id = 1;
    this.curEmployeeInfo.pwd_val = "";
    this.curEmployeeInfo.week_rate_val = 40;
    this.curEmployeeInfo.connection_sync_flag = true;
    this.curEmployeeInfo.Tel = "";
    this.curEmployeeSuppParams = [];
    this.curContracts = [];
    this.curSigHistory = [];
    this.curSignaletic = null;
    this.curTaskPersonGrid = [];
    this.curPersonGrid = [];
    this.displayPasswordField = false;
    this.curParkPools = [];
    this.pwdValue = "";
    this.getParkPools(-1, this.serviceId)
    this.getPersonSupplementsParams(-1, this.serviceId);
    this.updateEmployeeInfoFormData();
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName === 'employeeId') {
        if (this.newEmployee == null && this.employeeId > -1) {
          this.getEmployee(this.employeeId, this.serviceId, this.extCode);
        }
        else if (this.extCode) {
          this.getAutocompletePersonInfos(this.extCode);
        }
        else {
          this.initNew();
        }
      }

      if (propName === "newContract") {
        this.insertNewContract();
        if (changes[propName].previousValue && changes[propName].currentValue == null) {
          this.getPplContracts(this.employeeId, this.serviceId);
        }
      }
      this.resetDisplayPassword();
    }
  }

  public get dataLoading(): boolean {
    return (this.isLoadingPPlContract || this.isLoadingPersonGrid || this.isLoadingPersonInfo || this.isLoadingPersonSuppParams || this.isLoadingSignaletic
      || this.isLoadingTask || this.isLoadingConnectionList || this.isLoadingAutocompletePersonInfo || this.isLoadingUser || this.isLoadingParkPools);
  }

  public get dataEmpty(): boolean {
    return this.newEmployee != null || (this.curEmployeeInfo == null && this.curContracts.length == 0 && this.curSigHistory.length == 0 && this.curSignaletic == null
      && this.curTaskPersonGrid.length == 0 && this.curPersonGrid.length == 0 && this.curParkPools.length == 0)
  }

  public get isUserSA(): boolean {
    return this.userService.isSa;
  }

  get dictionary(): EmployeeDict {
    return this.dictService.getEmployeeDict();
  }

  closePeopleContract(): void {
    this.isPeopleContractDisplayed = false;
  }

  showPeopleContract(): void {
    this.isPeopleContractDisplayed = true;
  }

  closeMoveContract(): void {
    this.isMoveContractDisplayed = false;
  }

  showMoveContract(aContract: Contract): void {
    this.contractToMove = aContract;
    this.isMoveContractDisplayed = true;
  }

  toggleSection(aSectionName: string): void {
    let i = this.openedSection.indexOf(aSectionName);
    if (i < 0) {
      this.openedSection.push(aSectionName);
    } else {
      this.openedSection.splice(i, 1);
    }
  }

  insertNewContract() {
    if (this.newContract) {
      this.curContracts.unshift(this.newContract);
      this.openSection("emp-serv");
    }
  }

  isSectionOpened(aSectionName: string): boolean {
    return this.openedSection.indexOf(aSectionName) > -1;
  }

  openSection(aSectionName: string): void {
    if (!this.isSectionOpened(aSectionName)) {
      this.openedSection.push(aSectionName);
    }
  }

  closeSection(aSectionName: string): void {
    let i = this.openedSection.indexOf(aSectionName);
    if (i > -1) {
      this.openedSection.splice(i, 1);
    }
  }

  setPassword(): void {
    this.displayPasswordField = true;
    this.pwdValue = "";
  }

  /*LAURENT*/
  generateMatricule(): void{
    this.tmsApiService.getEmployeesMatricule().subscribe((response) => {
    if (response) {
      this.curEmployeeInfo.matricule_nr = response[0].Matricule; 
      
      this.updateEmployeeInfoFormDataMatricule();

      }
    });
  }

  changeConnectionMode() {
    if (this.curEmployeeInfo.tms_connection_mode_id != 1) {
      this.displayPasswordField = false;
      this.pwdValue = "";
    }
  }

  isFutureEl(line: SignaleticHist): boolean {
    let now: Date = new Date()
    let fd: Date = new Date(line.from_date);
    if (typeof fd.getTime != 'function') {
      fd = new Date(fd)
    }
    return now.getTime() < fd.getTime()
  }

  isPastEl(line: SignaleticHist): boolean {
    let now: Date = new Date()
    let fd: Date = new Date(line.from_date);
    let td: Date = new Date(line.to_date);
    if (typeof fd.getTime != 'function') {
      fd = new Date(fd)
      td = new Date(td)
    }
    if (td == null || td.getFullYear() < 1971) td = new Date(2099, 0, 1)
    return now.getTime() > td.getTime()
  }

  orderHistory = (line1?: SignaleticHist, line2?: SignaleticHist): number => {
    if (line1.signal_field_name) {
      let v1: SignaleticHist = line1;
      let v2: SignaleticHist = line2;
      let fd1: Date = new Date(v1.from_date);
      let td1: Date = new Date(v1.to_date);
      let fd2: Date = new Date(v2.from_date);
      let td2: Date = new Date(v2.to_date);
      if (typeof fd1.getTime != 'function') {
        fd1 = new Date(fd1)
        td1 = new Date(td1)
        fd2 = new Date(fd2)
        td2 = new Date(td2)
      }
      if (td1 == null || td1.getFullYear() == 1970) td1 = new Date(2099, 0, 1)
      if (td2 == null || td2.getFullYear() == 1970) td2 = new Date(2099, 0, 1)

      //1. active,fieldname 2.inactive date début, fieldname, 3.inactive date fin
      if (this.isActiveEl(v1) && !this.isActiveEl(v2)) return -1
      if (!this.isActiveEl(v1) && this.isActiveEl(v2)) return 1
      if (fd1.getTime() > fd2.getTime()) return -1
      if (fd1.getTime() < fd2.getTime()) return 1
      if (td1.getTime() > td2.getTime()) return -1
      if (td1.getTime() < td2.getTime()) return 1
      return v1.signal_field_name <= v2.signal_field_name ? -1 : 1
    }
  }

  isActiveEl(line: SignaleticHist): boolean {
    let now: Date = new Date()
    let fd: Date = new Date(line.from_date);
    let td: Date = new Date(line.to_date);
    if (typeof fd.getTime != 'function') {
      fd = new Date(fd)
      td = new Date(td)
    }
    if (td == null || td.getFullYear() < 1971) td = new Date(2099, 0, 1)
    return now.getTime() >= fd.getTime() && now.getTime() <= td.getTime()
  }

  checkMoveContract(aContract: Contract): boolean {
    let checkStatus: boolean = ((aContract.contract_status_id === 3) || (aContract.contract_status_id === 7));
    let checkDates: boolean = aContract.to_date === null || aContract.to_date === "" || new Date(aContract.to_date) > new Date();
    return checkStatus && checkDates;
  }

  checkContractDate(aDate: string): boolean {
    let isCorrectYear: boolean = false;
    let theDate: Date = new Date(aDate);
    if (theDate.getFullYear() > 1900) {
      isCorrectYear = true;
    }
    return isCorrectYear;
  }

  // API CALLS

  getUser() {
    this.isLoadingUser = true;
    this.tmsApiService.getUser().subscribe((response: TmsUser) => {
      this.isLoadingUser = false;
    });
  }

  getPersonInfos(aPersId: number) {
    this.isLoadingPersonInfo = true;
    this.curEmployeeInfo = new EmployeeInfos();
    this.updateEmployeeInfoFormData();
    this.tmsApiService.getPersonInfos(aPersId).subscribe((response: EmployeeInfos) => {
      if (response) {
        this.curEmployeeInfo = response;
        this.updateEmployeeInfoFormData();
      }
      this.isLoadingPersonInfo = false;
    });
  }

  getAutocompletePersonInfos(anExtCode: string) {
    this.isLoadingAutocompletePersonInfo = true;
    this.curEmployeeInfo = null;
    this.updateEmployeeInfoFormData();
    this.curContracts = [];
    this.tmsApiService.getAutocompletePersonInfos(anExtCode).subscribe((response) => {
      if (response) {
        let theAutoCompleteEmp: EmployeeAutoComp = response;
        this.curEmployeeInfo = new EmployeeInfos();
        this.openedSection = [];
        this.toggleSection("emp-serv");
        this.curEmployeeInfo.person_id = -1;
        this.curEmployeeInfo.sex_val = theAutoCompleteEmp.gender == "F" ? "F" : "H";
        this.curEmployeeInfo.title_id = this.curEmployeeInfo.sex_val == "F" ? 1 : 2;
        this.curEmployeeInfo.last_name = theAutoCompleteEmp.last_name;
        this.curEmployeeInfo.first_name = theAutoCompleteEmp.first_name;
        this.curEmployeeInfo.login = theAutoCompleteEmp.login;
        this.curEmployeeInfo.email_address = theAutoCompleteEmp.email_address;
        this.curEmployeeInfo.external_email_address = theAutoCompleteEmp.external_email_address;  
        this.curEmployeeInfo.external_flag = true;
        this.curEmployeeInfo.matricule_nr = theAutoCompleteEmp.matricule_nr;
        this.curEmployeeInfo.external_code = theAutoCompleteEmp.external_code;
        this.curEmployeeInfo.tms_connection_mode_id = this.curEmployeeInfo.login == null ? 1 : 2;
        this.curEmployeeInfo.week_rate_val = 40;
        this.curSigHistory = [];
        this.curSignaletic = null;
        this.curTaskPersonGrid = [];
        this.curPersonGrid = [];
        this.displayPasswordField = false;
        if (this.curEmployeeInfo.external_code) {
          this.getSigHistory(this.curEmployeeInfo.external_code);
        }
        this.getPersonSupplementsParams(-1, this.serviceId);
        this.updateEmployeeInfoFormData();
      }
      this.isLoadingAutocompletePersonInfo = false;
    });
  }

  postPersonInfos(anEmployee: any) {
    this.tmsApiService.postPersonInfos(anEmployee).subscribe((response) => {
      if (response) {
        let theNewPersId: number = response.person_id;
        this.saveContracts(theNewPersId);
        this.postSavePersonSupplementsParams(theNewPersId, this.serviceId, this.curEmployeeSuppParams);
      }
    });
  }

  deletePerson(aPersonId: number, aServId: number) {
    this.tmsApiService.deletePerson({ person_id: aPersonId }).subscribe((response) => {
      if (response) {
        if (response.success) {
          this.refreshTree.emit(new NodeSelect(aServId, "service"));
        }
      }
    }, (error) => {
      console.error(error);
    });
  }

  getPersonSupplementsParams(aPersId: number, aServiceId: number) {
    this.isLoadingPersonSuppParams = true;
    this.curEmployeeSuppParams = [];
    this.tmsApiService.getPersonSuppParams(aPersId, aServiceId).subscribe((response: EmployeeSupplementParam[]) => {
      if (response) {
        this.curEmployeeSuppParams = response;
      }
      this.isLoadingPersonSuppParams = false;
    });
  }

  postSavePersonSupplementsParams(aPersonId: number, aServiceId: number, aPersonSuppParams: EmployeeSupplementParam[]) {
    this.tmsApiService.postSavePersonSuppParams(aPersonId, aServiceId, aPersonSuppParams).subscribe((response) => {
      if (response) {
        //let theNewComId: number = response[0].company_id;
        //this.getCompanyInfo(theNewComId);
        //this.refreshTree.emit(new NodeSelect(theNewComId, "company"));
      }
    });
  }

  getPplContracts(aPersId: number, aSerId: number) {
    this.isLoadingPPlContract = true;
    this.curContracts = [];
    this.tmsApiService.getPplContracts(aPersId, aSerId).subscribe((response: Contract[]) => {
      if (response.length) {
        this.curContracts = this.getOrderedContracts(response)
        for (const contract of this.curContracts) {

          contract.from_date = this.datepipe.transform(contract.from_date, "yyyy-MM-dd");
          contract.to_date = this.datepipe.transform(contract.to_date, "yyyy-MM-dd");
          /*contract.taskList = [];
          this.tmsApiService.getDetailTask(aPersId, aSerId).subscribe((response) => {
            if (response.length) {
              contract.taskList = response;
            }
          });*/
        }
      }
      this.insertNewContract();
      this.isLoadingPPlContract = false;
    });

  }

  postSavePersonContract(aContract: any, aCallback?: Function) {
    this.tmsApiService.postSavePersonContract(aContract).subscribe((response) => {
      this.contractRestCount--;
      if (response) {
        if (response.success) {
          aCallback(this.contractRestCount == 0);
        }
        else {
          aCallback(false);
        }
      }
    });
    aCallback(false);
  }

  getSigHistory(anExtUserCode: string) {
    this.isLoadingSignaletic = true;
    this.curSigHistory = [];
    this.curSignaletic = null;
    this.tmsApiService.getSigHistory(anExtUserCode).subscribe((response) => {
      if (response) {
        this.curSigHistory = response.history.sort(this.orderHistory);
        this.curSignaletic = response.signalSync;
      }
      this.isLoadingSignaletic = false;
    });
  }

  getTaskPerson(aPersId: number, aSerId: number) {
    this.isLoadingTask = true;
    this.curTaskPersonGrid = [];
    this.tmsApiService.getTaskPerson(aPersId, aSerId).subscribe((response) => {
      if (response.length) {
        this.curTaskPersonGrid = response;
      }
      this.isLoadingTask = false;
    });
  }

  getPersonGrid(aPersId: number) {
    this.isLoadingPersonGrid = true;
    this.curPersonGrid = [];
    this.tmsApiService.getPersonGrid(aPersId).subscribe((response) => {
      if (response.length) {
        this.curPersonGrid = response;
      }
      this.isLoadingPersonGrid = false;
    });
  }

  /*getDetailTask(aPersId: number, aSerId: number) {
    this.tmsApiService.getDetailTask(aPersId, aSerId).subscribe((response) => {
      if (response.length) {
        this.curDetailTask = response;
      }
    });
  }*/

  getConModeList() {
    this.isLoadingConnectionList = true;
    this.curConnectionModes = [];
    this.tmsApiService.getConModeList().subscribe((response) => {
      if (response.length) {
        this.curConnectionModes = response;

      }
      this.isLoadingConnectionList = false;
    });
  }

  getParkPools(aPersId: number, aSerId: number) {
    this.isLoadingParkPools = true;
    this.curParkPools = [];
    this.tmsApiService.getParkPool(aPersId, aSerId).subscribe((response) => {
      if (response.length) {
        this.curParkPools = response;
        let emptyPool: ParkPool = new ParkPool();
        emptyPool.park_pool_name = "None / Aucun";
        this.curParkPools.unshift(emptyPool)
      }
      this.isLoadingParkPools = false;
    });
  }

  getEmployee(aPersId: number, aSerId: number, anExtUserCode: string) {
    this.getPersonInfos(aPersId);
    this.getPersonSupplementsParams(aPersId, aSerId);
    this.getPplContracts(aPersId, aSerId);
    this.getSigHistory(anExtUserCode);
    this.getTaskPerson(aPersId, aSerId);
    this.getPersonGrid(aPersId);
    this.getParkPools(aPersId, aSerId);
  }

  getOrderedContracts(aContracts: Contract[]): Contract[] {
    return aContracts.sort((c1: Contract, c2: Contract) => {
      if (c1.from_date == null) { return -1; }
      return (new Date(c2.from_date).getTime() - new Date(c1.from_date).getTime());
    })
  }

  checkMasterService(): boolean {
    for (const contract of this.curContracts) {
      if (contract.master_flag == true) return true;
    }
    return false;
  }

  checkDataFields(){
    this.contractMasterFlagInvalid = [];
    let masterServices: number = 0;
    for (const theContract of this.curContracts) {
      this.contractStatusInvalid[theContract.person_contract_id] = false;
      this.contractFromDateInvalid[theContract.person_contract_id] = false;
      this.contractMasterFlagInvalid[theContract.person_contract_id] = false; 
      
      if (theContract.contract_status_id < 0) {
        this.contractStatusInvalid[theContract.person_contract_id] = true;
      } 
      if (theContract.from_date == null || theContract.from_date == ""){
        this.contractFromDateInvalid[theContract.person_contract_id] = true;
      }

      /*if ((theContract.to_date != null && theContract.to_date != "") && (theContract.master_flag)){
        this.contractMasterFlagInvalid[theContract.person_contract_id] = true; 
      }*/
      if (theContract.master_flag) masterServices++;
    }

   /* if (masterServices = 0 ) {
      for (const theContract of this.curContracts) {
        this.contractMasterFlagInvalid[theContract.person_contract_id] = true; 
      }
    }
    else */if (masterServices > 1) {
      for (const theContract of this.curContracts) {
        if (theContract.master_flag){
          this.contractMasterFlagInvalid[theContract.person_contract_id] = true; 
        }
      }
    }
  }

  checkFields(): string {
    this.contractMasterFlagInvalid = [];

    let haserror = false;
    if (this.curEmployeeInfo.last_name == "" || this.curEmployeeInfo.last_name == null) return this.dictionary.fillInName;
    if (this.curEmployeeInfo.login == "" || this.curEmployeeInfo.login == null) return this.dictionary.fillInLogin;
    if (this.curEmployeeInfo.matricule_nr == "" || this.curEmployeeInfo.matricule_nr == null) return this.dictionary.fillInMatricule;
    if ((this.curEmployeeInfo.external_flag) && (this.curEmployeeInfo.external_code == "" || this.curEmployeeInfo.external_code == null || isNaN(Number(this.curEmployeeInfo.external_code.substring(this.curEmployeeInfo.external_code.length - 8))))) return this.dictionary.externalCodeNotValid;

    let masterServices: number = 0;
    let i: number = 1;
    /*let DateCurrent = new Date();*/
    for (const theContract of this.curContracts) {
      if (theContract.contract_status_id < 0) {
        this.contractStatusInvalid[theContract.person_contract_id] = true;
        haserror = true;
        return this.dictionary.contractNumberStatus + " " + (i) + " " + this.dictionary.isNotCorrectlyFilled
      } 
      if (theContract.from_date == null || theContract.from_date == ""){
        haserror = true;
        this.contractFromDateInvalid[theContract.person_contract_id] = true;
        return this.dictionary.entryDateOfContractN + " " + (i) + " " + this.dictionary.isNotCorrectlySpeciefied;
      }
/*
      if ((theContract.to_date != null && theContract.to_date != "" /*&& (new Date(theContract.to_date) < DateCurrent)*//*) && (theContract.master_flag)){
        haserror= true;
        this.contractMasterFlagInvalid[theContract.person_contract_id] = true; 
        return "";//this.dictionary.entryMasterflagnotOK + " " + (i) + " " + this.dictionary.isNotCorrectlySpeciefied;
      }*/

      if (theContract.etp_contract_val < 0) return this.dictionary.occupationRateContractNumber + " " + (i) + " " + this.dictionary.isNotCorrectlySpeciefied;
      if (theContract.to_date != "" && theContract.from_date > theContract.to_date) return this.dictionary.entryAndExitDateOfCN + " " + (i) + " " + this.dictionary.isNotCorrectlyFilled;
      if (theContract.master_flag) masterServices++;
      i++;
    }
    if (masterServices == 0) return this.dictionary.masterServiceForDateOfContract;
    if (masterServices > 1) {
      for (const theContract of this.curContracts) {
        if (theContract.master_flag){
          this.contractMasterFlagInvalid[theContract.person_contract_id] = true; 
        }
        i++;
      }

      return this.dictionary.onlyOneMasterService;
    }
    if (this.curEmployeeInfo.tms_connection_mode_id == 1 && this.displayPasswordField && this.pwdValue == "") return this.dictionary.fillInPassword;

    return "";
  }

  resetDisplayPassword() {
    if (this.displayPasswordField) this.displayPasswordField = false;
    this.pwdValue = "";
  }

  setCurrentProcents() {
    for (const employeeSuppParam of this.curEmployeeSuppParams) {
      if(employeeSuppParam.enable_base){
        switch (employeeSuppParam.paid_base_type) {
          case 'REPORT':
            employeeSuppParam.paid_base_procent = 0;
            break;
          case 'PAID':
            employeeSuppParam.paid_base_procent = 100;
            break;
        }
      }

      if(employeeSuppParam.enable_supp){
        switch (employeeSuppParam.paid_supp_type) {
          case 'REPORT':
            employeeSuppParam.paid_supp_procent = 0;
            break;
          case 'PAID':
            employeeSuppParam.paid_supp_procent = 100;
            break;
          case 'WKPAID':
            employeeSuppParam.paid_supp_procent = -50;
        }
      }
    }
  }

  saveContracts(aNewPersId: number) {
    this.contractRestCount = this.curContracts.length;
    for (const aContract of this.curContracts) {
      if (aContract.person_contract_id == -1){
        if (!aContract.from_date || aContract.from_date == "" ){
          this.toastService.pushAlert('from date manquante');


        }


      }
      let theContract = {
        person_contract_id: aContract.person_contract_id,
        person_id: aContract.person_id,
        new_person_id: aNewPersId,
        company_id: aContract.company_id,
        department_id: aContract.department_id,
        service_id: aContract.service_id,
        contract_status_id: aContract.contract_status_id,
        master_flag: aContract.master_flag,
        matricule_nr: this.curEmployeeInfo.matricule_nr,
        external_code: this.curEmployeeInfo.external_code,
        from_date: aContract.from_date == "" ? null : aContract.from_date,
        to_date: aContract.to_date == "" ? null : aContract.to_date,
        etp_contract_val: aContract.etp_contract_val,
        prest_period_flag: aContract.prest_period_flag,
        prest_init_val: aContract.prest_init_val,
        prest_task_id: aContract.prest_task_id,
        pointing_service_flag: aContract.pointing_service_flag,
        shift_work_flag: aContract.shift_work_flag
      };

      this.postSavePersonContract(theContract, (finished) => {
        if (finished) {
          this.getEmployee(aNewPersId, this.serviceId, this.extCode);
          this.refreshTree.emit(new NodeSelect(aNewPersId, "employee"));
         
        }
      })
    }
    this.resetDisplayPassword();
  }

  saveEmployee(): void {
    this.updateEmployeeInfoFromForm();
    let errorMessage: string = this.checkFields();

    if (errorMessage != "") {
      window.alert(errorMessage);
    }
    else {
      //this.updateEmployeeInfoFromForm();
      this.tmsApiService.getPersonUnicity(this.curEmployeeInfo.person_id, this.curEmployeeInfo.matricule_nr, this.curEmployeeInfo.login, this.curEmployeeInfo.email_address).subscribe((response) => {
        if (response.length) {
          if (!response[0].ok_flag) {
            switch (response[0].text_error_id) {
              case 1:
                window.alert(this.dictionary.matriculeUsed);
                break;
              case 2:
                window.alert(this.dictionary.loginUsed);
                break;
              case 3:
                window.alert(this.dictionary.emailUsed);
                break;
            }
          }
          else {
            this.setCurrentProcents();
            if (this.curEmployeeInfo.tms_connection_mode_id === 1) {
              if (this.pwdValue !== '' && this.displayPasswordField) {
                this.curEmployeeInfo.pwd_val = this.pwdValue;
              } else {
                this.curEmployeeInfo.pwd_val = '-1';
              }
            } else {
              this.curEmployeeInfo.pwd_val = '-1';
            }
            let theNewEmploye = {
              person_id: this.curEmployeeInfo.person_id,
              last_name: this.curEmployeeInfo.last_name,
              first_name: this.curEmployeeInfo.first_name,
              login: this.curEmployeeInfo.login,
              email_address: this.curEmployeeInfo.email_address,
              sex_val: this.curEmployeeInfo.sex_val,
              title_id: this.curEmployeeInfo.title_id,
              tms_connection_mode_id: this.curEmployeeInfo.tms_connection_mode_id,
              pwd_val: this.curEmployeeInfo.pwd_val,
              export_paid_flag: this.curEmployeeInfo.export_paid_flag,
              export_holiday_flag: this.curEmployeeInfo.export_holiday_flag,
              connection_sync_flag: this.curEmployeeInfo.connection_sync_flag,
              pylon_flag: this.curEmployeeInfo.pylon_flag,
              astreinte_flag: this.curEmployeeInfo.astreinte_flag,
              week_rate_val: this.curEmployeeInfo.week_rate_val,
              edit_table_flag: this.curEmployeeInfo.edit_table_flag,
              language_val: this.userService.currentUserValue.language,
              external_flag: this.curEmployeeInfo.external_flag,
              park_pool_id: this.curEmployeeInfo.park_pool_id,
              has_parking: this.hasPark,
              external_email_address: this.curEmployeeInfo.external_email_address,
              Tel: this.curEmployeeInfo.Tel
            }
            this.postPersonInfos(theNewEmploye);
          }
        }
      })
    }
  }

  createNew() {
    this.create.emit();
  }

  modify(aPeople: People) {
    this.update.emit(aPeople);
  }

  cancelAction() {
    this.cancel.emit();
  }

  delete() {
    let confirm = window.confirm(this.dictionary.deletePersonQuestion);
    if (confirm) {
      this.deletePerson(this.curEmployeeInfo.person_id, this.serviceId);
    }
  }

  validateMoveContract(theNode: NodeSelect) {
    this.isMoveContractDisplayed = false;
    this.refreshTree.emit(theNode);
  }

  updateEmployeeInfoFormData(){
    this.formEmployeeInfo?.patchValue({
      firstname: this.curEmployeeInfo?.first_name,
      lastname: this.curEmployeeInfo?.last_name,
      email: this.curEmployeeInfo?.email_address,
      externalemail: this.curEmployeeInfo?.external_email_address,
      login: this.curEmployeeInfo?.login,
      sexval: this.curEmployeeInfo?.sex_val,
      titleid: this.curEmployeeInfo?.title_id,
      matriculenr: this.curEmployeeInfo?.matricule_nr,
      externalflag: this.curEmployeeInfo?.external_flag,
      externalcode: this.curEmployeeInfo?.external_code,
      Tel : this.curEmployeeInfo?.Tel
    })
  }

  /*LAURENT*/
  updateEmployeeInfoFormDataMatricule(){
    this.formEmployeeInfo?.patchValue({
      matriculenr: this.curEmployeeInfo?.matricule_nr
    })
  }

  /*updateContractInfoFromData(){
    this.formContractInfo = this.fb.group({
      masterflag: [{value : this.con curContracts[0].master_flag, disabled : this.isRO}, Validators.required],
      statusid:   [{value : this.curContracts[0].contract_status_id, disabled : this.isRO}, Validators.required],
      fromdate:   [{value : this.curContracts[0].from_date, disabled : this.isRO}, Validators.required],
      todate:     [{value : this.curContracts[0].to_date, disabled : this.isRO}],
      contractval: [{value : this.curContracts[0].etp_contract_val, disabled : this.isRO}],
      prestperiodflag: [{value : this.curContracts[0].prest_period_flag, disabled : this.isRO}],
      shiftworkflag: [{value : this.curContracts[0].shift_work_flag, disabled : this.isRO}],
    });
  }*/

  updateEmployeeInfoFromForm(){
      this.curEmployeeInfo.last_name = this.employeeInfo_lastname.value;
      this.curEmployeeInfo.first_name = this.employeeInfo_firstname.value;
      this.curEmployeeInfo.email_address = this.employeeInfo_email.value;
      this.curEmployeeInfo.external_email_address = this.employeeInfo_externalemail.value;
      this.curEmployeeInfo.login = this.employeeInfo_login.value;
      this.curEmployeeInfo.sex_val = this.employeeInfo_sexval.value;
      this.curEmployeeInfo.title_id = this.employeeInfo_titleid.value;
      this.curEmployeeInfo.matricule_nr = this.employeeInfo_matriculenr.value;
      this.curEmployeeInfo.external_flag = this.employeeInfo_externalflag.value;
      this.curEmployeeInfo.external_code = this.employeeInfo_externalcode.value;
      this.curEmployeeInfo.Tel = this.employeeInfo_Tel.value;
    }
/*
  updateContractInfoFromForm(){
   // this.formContractInfo = this.fb.group({
   //   masterflag: [{value : this.curContracts[0].master_flag, disabled : this.isRO}, Validators.required],
   //   statusid:   [{value : this.curContracts[0].contract_status_id, disabled : this.isRO}, Validators.required],
   //   fromdate:   [{value : this.curContracts[0].from_date, disabled : this.isRO}, Validators.required],
   //   todate:     [{value : this.curContracts[0].to_date, disabled : this.isRO}],
   //   contractval: [{value : this.curContracts[0].etp_contract_val, disabled : this.isRO}],
   //   prestperiodflag: [{value : this.curContracts[0].prest_period_flag, disabled : this.isRO}],
    //  shiftworkflag: [{value : this.curContracts[0].shift_work_flag, disabled : this.isRO}],
    //});
  }*/


}
