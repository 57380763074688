import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { LoggedUser } from '@app/models';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) { }

  private get httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
  }

  login(aUsername: string, aPassword: string): Observable<any> {
    const data = { username: aUsername, password: aPassword }
    return this.http.post<any>(this.apiUrl + '/login', data, this.httpOptions)
      .pipe(map(response => {
        // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
        if (response && response.success) {
          const loggedUser = new LoggedUser();
          loggedUser.authdata = response.token;
          this.userService.setUser(loggedUser);
          return loggedUser;
        }
        else return null;
      }));
  }

  autoLogin(aToken: string) {
    const loggedUser = new LoggedUser();
    loggedUser.authdata = aToken;
    this.userService.setUser(loggedUser);
  }

  /*
  changePassword(aPassword: string): Observable<any> {
    const usr = {
      pwd: aPassword
    };
    return this.http.patch<any>(this.apiUrl + '/users/password', usr, this.httpOptions);
  }
  */
}
