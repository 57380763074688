import { Company, Department, Employee, Service } from ".";

export class TreeNode {
    id: number;
    nodes: TreeNode[];
    parentNode: TreeNode;
    currentObject: any;
    currentType: string;
    isOpen: boolean;
    isRoot: boolean;
    isFiltered: boolean;

    constructor(aId: number = 0, aTab: TreeNode[] = [], aParentNode: TreeNode = null, aCurObj: any = null, aCurType: string = "", aOpen: boolean = false, aRoot: boolean = false, aFilter: boolean = false) {
        this.id = aId;
        this.nodes = aTab;
        this.parentNode = aParentNode;
        this.currentObject = aCurObj;
        this.currentType = aCurType;
        this.isOpen = aOpen;
        this.isRoot = aRoot;
        this.isFiltered = aFilter;
    }

    get nodeTitle(): string {
        switch (this.currentType) {
            case 'company':
                return (this.currentObject as Company).name
                break;
            case 'department':
                return (this.currentObject as Department).name
                break;
            case 'service':
                return (this.currentObject as Service).name
                break;
            case 'employee':
                return (this.currentObject as Employee).fullname
                break;
        }
        return '';
    }

    get objectId(): number {
        switch (this.currentType) {
            case 'company':
                return (this.currentObject as Company).id
                break;
            case 'department':
                return (this.currentObject as Department).id
                break;
            case 'service':
                return (this.currentObject as Service).id
                break;
            case 'employee':
                return (this.currentObject as Employee).personId;
                break;
        }
        return -1;
    }

    get externalCode(): string {
        switch (this.currentType) {
            case 'company':
                return ""
                break;
            case 'department':
                return ""
                break;
            case 'service':
                return ""
                break;
            case 'employee':
                return (this.currentObject as Employee).externalUserCode;
                break;
        }
        return "";
    }

    get hasTS() : boolean {
        return this.currentObject.hasTimesheet;
    }

    get hasHR() : boolean {
        return this.currentObject.hasHRInterface;
    }

    get hasPark() : boolean {
        return this.currentObject.hasParking;
    }

    getNodeTitle(aMatch: string = ''): string {
        if (aMatch.trim().length) {
            var regex = new RegExp('(' + aMatch + ')', 'i');
            //return this.nodeTitle.replace(regex, '<span style="background: yellow">$1</span>');
            return this.nodeTitle.replace(regex, '<span style="background: yellow;color:#000000;">$1</span>');
        } else {
            return this.nodeTitle;
        }
    }

}

export class NodeSelect {
    objectId: number;
    objectType: string;
    
    constructor(anId: number, aType: string) {
        this.objectId = anId;
        this.objectType = aType;
    }
}