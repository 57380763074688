export class Task {

    desc_id: number;
    desc_val: string;

    constructor() { }
}

export class TaskDetail {
    night_enable_flag: boolean;
    remark_required_flag: boolean;
    task_bv_ref: string;
    task_desc: string;
    task_desc_1: string;
    task_desc_2: string;
    task_desc_3: null
    task_id: number
    top_flag: boolean;
}