import { Item } from "./item";

export class Department extends Item {
    id: number;
    name: string;
    companyId: number;
    companyName: string;

    constructor(anId: number, aName: string, aCompId: number, aCompName: string, aFlag: boolean, aHasTS: boolean, aHasHR: boolean, aHasPark: boolean) {
        super(aFlag, aHasTS, aHasHR, aHasPark);
        this.id = anId;
        this.name = aName;
        this.companyId = aCompId;
        this.companyName = aCompName;
    }
}

export class DepartmentHeader {
    department_id: number;
    department_name: string;
    department_shortname: string;

    constructor() {

    }
}

export class DepartmentInfos {
    department_id: number;
    department_name: string;
    department_shortname: string;
    company_id: number;
    responsible_id: number;
    scan_flag: boolean;
    archive_flag: boolean;
    is_deleted_flag: boolean;
    create_date: string;
    create_by: number;
    mod_date: string;
    mod_by: number;
    responsible_name: string;
    delete_enabled_flag: boolean;

    constructor() {
        this.department_id = -1;
        this.department_name = "";
        this.department_shortname = "";
        this.company_id = -1;
        this.responsible_id = -1;
        this.scan_flag = false;
        this.archive_flag = false;
        this.is_deleted_flag = false;
        this.create_date = "";
        this.create_by = null;
        this.mod_date = "";
        this.mod_by = null;
        this.responsible_name = "";
        this.delete_enabled_flag = false;
    }
}

export class DepartmentMail {
    send_mail_flag: boolean;
    pk_id: string;
    nb_days: number;
    date_rel: string;
    mail_type: string;
    dest_type: string;
    dest_id: number;
    mail_frequency: string;
    only_if_sup_flag: boolean;

    constructor() {
        this.send_mail_flag = false;
    }
}

export class DepartmentRole {
    person_role_id: number;
    department_id: number;
    service_id: number;
    person_id: number;
    role_val: string;
    is_deleted_flag: boolean;
    create_date: string;
    create_by: number;
    mod_date: string;
    mod_by: number
    person_name: string;
    archive_flag: boolean;
    to_add: boolean;

    constructor(aPersId: number, aPersName: string, addFlag: boolean) {
        this.person_id = aPersId;
        this.person_name = aPersName;
        this.to_add = addFlag;
    }

}

export class DepartmentMember {
    person_id: number;
    person_name: string;
    archive_flag: boolean;
    constructor() {
    }
}

export class DepartmentDict {

    newDepartment: string;
    sheets: string;
    rptName: string;
    abreviation: string;
    responsible: string;
    archived: string;
    mailPeriodEnd: string;
    mailReminderVal2: string;
    delayValLimit: string;
    labelDays: string;
    frequency: string;
    once: string;
    everyday: string;
    onlyIfSup: string;
    mailAllSrvVal: string;
    recipient: string;
    timeManagers: string;
    controllers: string;
    adminsRH: string;
    GDTCTR: string;
    members: string;
    rptExport: string;
    newService: string;
    new: string;
    tmgSave: string;
    titleDelete: string;
    cancel: string;
    nameOrAbbreviationExistsAlready: string;
    fillInName: string;
    fillAbbreviationIn: string;
    deleteDepartmentQuestion: string;
    confirmDeleteRole: string;

    constructor() {
        this.newDepartment = "";
        this.sheets = "";
        this.rptName = "";
        this.abreviation = "";
        this.responsible = "";
        this.archived = "";
        this.mailPeriodEnd = "";
        this.mailReminderVal2 = "";
        this.delayValLimit = "";
        this.labelDays = "";
        this.frequency = "";
        this.once = "";
        this.everyday = "";
        this.onlyIfSup = "";
        this.mailAllSrvVal = "";
        this.recipient = "";
        this.timeManagers = "";
        this.controllers = "";
        this.adminsRH = "";
        this.GDTCTR = "";
        this.members = "";
        this.rptExport = "";
        this.newService = "";
        this.new = "";
        this.tmgSave = "";
        this.titleDelete = "";
        this.cancel = "";
        this.nameOrAbbreviationExistsAlready = "";
        this.fillInName = "";
        this.fillAbbreviationIn = "";
        this.deleteDepartmentQuestion = "";
        this.confirmDeleteRole = "";
    }

}