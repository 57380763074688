<div id="adm-main" class="grh-fullcontainer" *ngIf="dicoLoaded">
    <div id="adm-navbar">
        <div id="adm-navbar-left">
            <img src="./assets/img/favicon.ico">
            <h1>TMS-Admin > <span (click)="goToTools()">{{dictionary.tool}}s </span>  {{currentTool}} </h1>
        </div>
        <div id="adm-navbar-right">
            <div class="mtb-item">
                <button id="bt-option" (click)="clickSubMenu()"><i class="icon fa fa-ellipsis-v"></i></button>
                <div class="mtb-submenu" id="option" *ngIf=showSubMenu>
                    <button (mouseover)="setLanguageShown(true)"><label>{{dictionary.currentLang}}</label><span
                            class="more caret"><i class="fa fa-caret-left"></i>
                        </span></button>
                    <button (mouseenter)="setLanguageShown(false)"
                        (click)="logout()">{{dictionary.logout}}</button>
                    <div class="languages" (mouseenter)="setLanguageShown(true)" (mouseleave)="setLanguageShown(false)"
                        *ngIf="languagesShown">
                        <button (click)="setLanguage('F')"><span class="check" *ngIf="currentLanguage == 'F'"><i
                                    class="fa fa-check"></i></span><span
                                class="menu-entry">{{dictionary.french}}</span></button>
                        <button (click)="setLanguage('N')"><span class="check" *ngIf="currentLanguage == 'N'"><i
                                    class="fa fa-check"></i></span><span
                                class="menu-entry">{{dictionary.english}}</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <router-outlet ></router-outlet>
</div>