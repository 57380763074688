export class Company {
    id: number;
    name: string;
    hasTimesheet: boolean;
    hasHRInterface: boolean;
    hasParking : boolean;

    constructor(anId: number, aName: string, aHasTS: boolean, aHasHR: boolean, aHasPark: boolean) {
        this.id = anId;
        this.name = aName;
        this.hasTimesheet = aHasTS;
        this.hasHRInterface = aHasHR;
        this.hasParking = aHasPark;
    }
}


export class CompanyInfos {
    company_id: number;
    company_name: string;
    company_displayname: string;
    company_shortname: string;
    company_domain: string;
    supplement_profile_id : number;
    quarter_round_flag: boolean;
    has_timesheet: boolean;
    has_planning: boolean;
    has_hr_interface: boolean;
    full_time_week_hours: number;
    archive_flag: boolean;
    create_date: string;
    create_by: number;
    mod_date: string;
    mod_by: number;
    delete_enabled_flag: boolean;

    constructor() {
        this.company_id = -1;
        this.company_name = "";
        this.company_shortname = "";
        this.company_shortname = "";;
        this.company_domain = "";
        this.supplement_profile_id = -1;
        this.quarter_round_flag = false;
        this.has_timesheet = false;
        this.has_planning = false;
        this.has_hr_interface = false;
        this.full_time_week_hours = 0;
        this.archive_flag = false;
        this.create_date = "";
        this.create_by = null;
        this.mod_date = "";
        this.mod_by = null;
        this.delete_enabled_flag = false;
    }
}


export class CompanyDict {

    sheets: string;
    name: string;
    displayName: string;
    abbreviation: string;
    domain: string;
    fullTimeWeekHours: string;
    quarterRound: string;
    hasTimesheet: string;
    hasPlanning : string;
    hasHRInterface: string;
    archived: string;
    newCompany: string;
    cancel: string;
    fillInName: string;
    fillAbbreviationIn: string;
    fillDisplayNameIn : string;
    abbrMaxChars: string;
    companyAlreadyExists: string;
    newDepartment: string;
    titleDelete: string;
    tmgSave: string;
    supplementProfile : string;
    noProfile : string;


    constructor() {
        this.sheets = "";
        this.name = "";
        this.displayName = "";
        this.abbreviation = "";
        this.hasPlanning = "";
        this.domain = "";
        this.fullTimeWeekHours = "";
        this.quarterRound = "";
        this.hasTimesheet = "";
        this.hasHRInterface = "";
        this.archived = "";
        this.newCompany = "";
        this.cancel = "";
        this.fillInName = "";
        this.fillAbbreviationIn = "";
        this.fillDisplayNameIn = "";
        this.abbrMaxChars = "";
        this.companyAlreadyExists = "";
        this.newDepartment = "";
        this.titleDelete = "";
        this.tmgSave = "";
        this.supplementProfile = "";
        this.noProfile = "";
    }
}

export class CompanySupplementsParam {
    company_supplements_param_id: number;
    company_id: number;
    supplement_type_id: number;
    description: string;
    shortname: string;
    supplement_part: string;
    repartition_mode: string;
    is_deleted_flag: boolean;
    create_date: string;
    create_by: number;
    mod_date: string;
    mod_by: number;
    
    constructor() {
        this.company_supplements_param_id = -1;
        this.company_id = -1;
        this.supplement_type_id = -1;
        this.description = '';
        this.shortname = '';
        this.supplement_part = '';
        this.repartition_mode = '';
        this.is_deleted_flag = false
        this.create_date = '';
        this.create_by = -1;
        this.mod_date = '';
        this.mod_by = -1;
    }
}