<div id="log-cont" class="grh-fullcontainer noselect">
    <app-people *ngIf="isPeopleDisplayed" [archiveFlag]="false" [managedPeopleFlag]="true" (close)="closePeople()"
        (add)="addPeople($event)">
    </app-people>
    <div id="log-main">
        <div id="log-top">
            <div id="log-flds">
                <div id="log-flds-1" class="log-top-grp">
                    <div class="log-field">
                        <label>Log type</label>
                        <select [(ngModel)]="curReport"> 
                            <option [value]="1">
                                Period status change
                            </option>
                            <option [value]="2">
                                Supplements repartition
                            </option>
                            <option [value]="3">
                                Supplements changes
                            </option>
                            <option [value]="4">
                                Actions in Timesheet
                            </option>
                            <option [value]="5">
                                Actions In Planning
                            </option>
                            <option [value]="6">
                                Sent emails report
                            </option>
                        </select>
                    </div>
                    <div class="log-field">
                        <label>People</label>
                        <input type="text" (click)="isPeopleDisplayed = true" [(ngModel)]="curPeople.person_name">
                    </div>
                </div>

                <div id="log-flds-2" class="log-top-grp">
                    <div id="log-prd" class="log-field">
                        <input type="radio" name="date-choice" [(ngModel)]="curDateChoice" value="period"
                            (change)="initRadio()">
                        <div id="log-prd-year">
                            <label>Year</label>

                            <select [(ngModel)]="curYear" (change)="getSupPeriods(curYear)"
                                [disabled]="curDateChoice != 'period'">
                                <option *ngFor="let year of curYears" [ngValue]="year.year_val">
                                    <!-- [value] for the value in string, [ngValue] for the whole object or another type-->
                                    {{year.year_val}}
                                </option>
                            </select>
                        </div>
                        <div id="log-prd-period">
                            <label>Period</label>
                            <select [(ngModel)]="curPeriod" [disabled]="curDateChoice != 'period'">
                                <option [ngValue]=emptyPeriod>0 - All periods</option>
                                <option *ngFor="let period of curSupplementPeriod" [ngValue]="period">
                                    {{period.period_val}} - {{period.from_date | date : 'dd/MM/yyyy'}} =>
                                    {{period.to_date |
                                    date : 'dd/MM/yyyy'}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div id="log-date" class="log-field">
                        <input type="radio" name="date-choice" [(ngModel)]="curDateChoice" value="date"
                            (change)="initRadio()">
                        <div id="log-date-from">
                            <label>From </label>
                            <input type="date" [(ngModel)]="curPeriod.from_date" [disabled]="curDateChoice != 'date'">
                        </div>
                        <div id="log-date-to">
                            <label>to </label>
                            <input type="date" [(ngModel)]="curPeriod.to_date" [disabled]="curDateChoice != 'date'">
                        </div>
                    </div>
                </div>
                <div id="log-flds-3" class="log-top-grp">
                    <button (click)="searchLogs()"><i class="fas fa-search"></i></button>
                </div>
            </div>
        </div>
        <div id="log-panel" *ngIf="!dataLoading">
            <div id="log-res" *ngIf="curLogs && curLogs.length > 0">
                <input type="search" placeholder="Filter" #inputSearch>
                <button (click)="exportExcel()" class="btnExcel"><i class="fas fa-file-excel"></i></button>
                <label *ngIf="curLogs && curLogs.length > 0 ">{{curLogs.length}} résultat(s)</label>
            </div>
            <div class="log-tbl" *ngIf="curLogs && curLogs.length > 0">
                <table>
                    <thead>
                        <tr>
                            <th *ngFor="let columns of curColumns" class="capitalize"
                                (click)="sortTable(columns.column_name, sortOrder)">

                                <label>{{columns.column_name}}</label>
                                <span *ngIf="sortColumn == columns.column_name && !sortOrder" class="caret">
                                    <i class="fa fa-caret-up"></i>
                                </span>
                                <span *ngIf="sortColumn == columns.column_name && sortOrder" class="caret">
                                    <i class="fa fa-caret-down"></i>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let log of getFilteredLogsList()">
                            <td *ngFor="let column of curColumns">

                                <ng-container [ngSwitch]="column.column_type">
                                    <span *ngSwitchCase="'date'">{{log[column.column_name] | date : 'dd/MM/yyyy
                                        HH:mm:ss':'+0000'}} </span>
                                    <span *ngSwitchCase="'html'"><button
                                            (click)="openPopupHtml(log[column.column_name])">Preview</button></span>
                                    <span *ngSwitchDefault>{{log[column.column_name]}} </span>
                                </ng-container>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="log-tbl" class="loader" *ngIf="curLogs && curLogs.length == 0">
                <span>No data ! </span>
            </div>
        </div>
        <div id="log-panel" *ngIf="dataLoading">
            <div class="loader">
                <img src="./assets/img/loader.gif" alt="loading...">
            </div>
        </div>
    </div>
</div>
<div class="popup-html" *ngIf="displayPopup">
    <div class="popup-windows">
        <div class="top-bar">
            <div class="close-button"(click)="closePopupHtml()">X</div>
        </div>
        <div class="popup-scroll" [innerHTML]="popupHtmlContent"></div>
    </div>
</div>