import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LogDict, People, Period } from '@app/models';
import { DictService, TmsApiService } from '@app/services';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { utils, WorkBook, WorkSheet, writeFileXLSX, Sheet2CSVOpts } from 'xlsx'
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})

export class LogsComponent implements OnInit {

  @ViewChild('inputSearch') inputSearch: ElementRef;
  curYears: any[];
  curYear: any;
  curPeriod: Period;
  emptyPeriod: Period;
  curSupplementPeriod: Period[];
  curPeople: People;
  curReport: string;
  curColumns: string[];
  curLogs: any[];
  curFromDate: string;
  curToDate: string;
  curDateChoice: string;
  sortOrder: boolean;
  sortColumn: string;
  searchText: string;
  isLoadingPeriodStatus: boolean;
  isLoadingPeriodRepartition: boolean;
  isLoadingPeriodSuplement: boolean;
  isLoadingTimesheetLogs: boolean;
  isLoadingPlanningLogs: boolean;
  isLoadingYears: boolean;
  isLoadingSupPeriods: boolean;
  isPeopleDisplayed: boolean;
  isHooked: boolean;
  emailInHTMLFormat :any ;
  displayPopup : boolean;
  popupHtmlContent: string;

  constructor(private datepipe: DatePipe, private tmsApiService: TmsApiService, private dictService: DictService, private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.curYear = -1;
    this.curPeriod = new Period();
    this.emptyPeriod = new Period();
    this.curReport = "";
    this.curYears = [];
    this.curSupplementPeriod = [];
    this.curLogs = null;
    this.curPeople = new People();
    this.curFromDate = "";
    this.curDateChoice = "period";
    this.isLoadingPeriodStatus = false;
    this.isLoadingYears = false;
    this.isLoadingSupPeriods = false;
    this.isPeopleDisplayed = false;
    this.isLoadingPeriodRepartition = false;
    this.isLoadingPeriodSuplement = false;
    this.isLoadingTimesheetLogs = false;
    this.isLoadingPlanningLogs = false;
    this.isHooked = false;
    this.sortOrder = false;
    this.sortColumn = "";
    this.searchText = "";
    this.getYears();
    this.displayPopup = false;
    this.popupHtmlContent = "";
  }
  
  getEmailData(data: string){
    this.emailInHTMLFormat = this.domSanitizer.bypassSecurityTrustHtml(data); 
  }


  //To use only for hidden input search.
  //Be careful, this function is regularly launched.
  //If condition is there to subscribe the event only once
  ngAfterViewChecked() { 
    if (this.curLogs && this.curLogs.length > 0 && !this.isHooked) {
      this.isHooked = true;
      console.log('resetSearchFilterabc',this.inputSearch);
      if (this.inputSearch!=null){
        fromEvent(this.inputSearch.nativeElement, 'input')
          .pipe(
            filter(Boolean),
            debounceTime(250),
            distinctUntilChanged(),
            tap((event) => {
              this.searchText = this.inputSearch.nativeElement.value;
              this.changeSearch()
            })
          )
         .subscribe();
      }
    }
  }

  get searchMod(): boolean {
    return this.searchText.trim().length > 0
  }

  get dictionary(): LogDict {
    return this.dictService.getLogDict();
  }

  changeSearch() {
    if (this.searchMod) {
      this.getFilteredLogsList();
    }
  }

  getFilteredLogsList() {
    if (this.searchMod) {
      return this.curLogs.filter((log) => {
        return JSON.stringify(log).toUpperCase().includes(this.searchText.toUpperCase());
      })
    } else {
      return this.curLogs;
    }
  }

  get dataLoading() {
    return this.isLoadingPeriodStatus || this.isLoadingPeriodRepartition || this.isLoadingPeriodSuplement || this.isLoadingTimesheetLogs || this.isLoadingPlanningLogs;
  }

  isDate(aName: string): boolean {
    return aName.indexOf("date") >= 0;
  }

  closePeople() {
    this.isPeopleDisplayed = false;
  }

  addPeople(aPeople: People) {
    this.curPeople = aPeople;
    this.isPeopleDisplayed = false;
  }

  checkFields(): string {
    if (this.curReport == "" || this.curReport == null) return "Il faut sélectionner un rapport"
    if (this.curPeople.person_name == "" || this.curPeople.person_name == null) return "Il faut sélectionner une personne"
    if (this.curDateChoice == "period") {
      if (this.curYear == -1 || this.curYear == null) return "Il faut sélectionner une année"
      if (this.curPeriod == null) return "Il faut sélectionner une période"
    }
    else if (this.curDateChoice == "date") {
      if (this.curPeriod.from_date == "" || this.curPeriod.from_date == null) return "Il faut sélectionner une date de début"
      if (this.curPeriod.to_date == "" || this.curPeriod.to_date == null) return "Il faut sélectionner une date de fin"
    }

    return "";
  }

  initRadio() {
    if (this.curDateChoice == "period") {
      this.curFromDate = "";
      this.curToDate = "";
    }
    else if (this.curDateChoice == "date") {
      this.curYear = -1;
      this.curPeriod = new Period();
    }
  }

  setParameters(aYear: number, aPeriod: Period): Period {

    let returnPeriod: Period = new Period();
    if (this.curDateChoice == "period") {

      returnPeriod.from_date = null;
      returnPeriod.to_date = null;
      returnPeriod.period_val = aPeriod.period_val;

    }
    else if (this.curDateChoice == "date") {
      let aDate: Date = new Date(aPeriod.to_date);
      aDate.setHours(23);
      aDate.setMinutes(59);
      aDate.setSeconds(59);
      returnPeriod.from_date = aPeriod.from_date;
      returnPeriod.to_date = this.datepipe.transform(aDate, "yyyy-MM-dd HH:mm:ss.SSS");
      returnPeriod.period_val = aPeriod.period_val;
    }
    return returnPeriod;
  }

  getFilename(reportType: string, ext: string): string {
    let fileName: string;

    switch (reportType) {
      case "1":
        fileName = "Period_status_";
        break;
      case "2":
        fileName = "Period_repartition_";
        break;
      case "3":
        fileName = "Period_suplements_";
        break;
      case "4":
        fileName = "Timesheet_logs_";
        break;
      case "5":
        fileName = "Planning_logs_";
        break;
      default:
        break;
    }

    fileName = fileName + this.curPeople.person_name + "_";

    if (this.curDateChoice == "period") {
      let thePeriod: string;
      thePeriod = this.curPeriod.period_val == -1 ? "ALL" : this.curPeriod.period_val.toString();
      fileName = fileName + this.curYear + "_" + thePeriod + "_";
    }
    else {
      fileName = fileName + this.curPeriod.from_date + "_" + this.curPeriod.to_date + "_";
    }

    fileName = fileName + this.datepipe.transform(new Date(), "yyyy-MM-dd_HH:mm:ss") + "." + ext;

    return fileName.replace(" ", "_");
  }


/*LAURENT*/
  exportExcel() {
    if (this.curReport == "6") {

      const myClonedArray = [];
      this.curLogs.forEach(val => myClonedArray.push(Object.assign({}, val)));
      myClonedArray.forEach(prop => delete prop.body);

      let wb: WorkBook = utils.book_new();
      let ws: WorkSheet = utils.json_to_sheet(myClonedArray);
      utils.book_append_sheet(wb, ws);
      writeFileXLSX(wb, this.getFilename(this.curReport, "xlsx"));
    }
    else{
      let wb: WorkBook = utils.book_new();
      let ws: WorkSheet = utils.json_to_sheet(this.curLogs);
      utils.book_append_sheet(wb, ws);
      writeFileXLSX(wb, this.getFilename(this.curReport, "xlsx"));
    }
  }

  exportCSV() {
    let wb: WorkBook = utils.book_new();
    let ws: WorkSheet = utils.json_to_sheet(this.curLogs);

    let options: Sheet2CSVOpts = {
      FS: ";",
      dateNF: "dd/MM/yyyy HH:mm:ss",
      forceQuotes: true,
    };

    let csv: string = utils.sheet_to_csv(ws, options);
    csv = "\uFEFF" + csv;
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = this.getFilename(this.curReport, "csv");
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  sortTable(columnName: string, order: boolean) {
    this.sortColumn = columnName;

    if (order == true) {
      this.curLogs.sort((a, b) => a[columnName] > b[columnName] ? 1 : a[columnName] < b[columnName] ? -1 : 0)
    }
    else {
      this.curLogs.sort((a, b) => a[columnName] < b[columnName] ? 1 : a[columnName] > b[columnName] ? -1 : 0)
    }
    this.sortOrder = !order
  }

  resetSearchFilter() {
    this.searchText = "";
    this.sortColumn = "";
    this.sortOrder = true;
    if (this.isHooked) {
      this.inputSearch.nativeElement.value = "";
    }
  }

  /*LAURENT*/
  searchLogs() {
    let errorMessage: string = this.checkFields();

    if (errorMessage != "") {
      window.alert(errorMessage);
    }
    else {
      this.sortOrder =false;
      this.sortColumn = "";
      this.isHooked = false;
      let theFinalPeriod: Period
      theFinalPeriod = this.setParameters(this.curYear, this.curPeriod);

      switch (this.curReport) {
        case "1":
          this.getPeriodStatus(this.curPeople.person_id, this.curPeriod.from_date, this.curPeriod.to_date, this.curYear, this.curPeriod.period_val);
          break;
        case "2":
          this.getPeriodRepartition(this.curPeople.person_id, this.curPeriod.from_date, this.curPeriod.to_date, this.curYear, this.curPeriod.period_val);
          break;
        case "3":
          this.getPeriodSuplement(this.curPeople.person_id, this.curPeriod.from_date, this.curPeriod.to_date, this.curYear, this.curPeriod.period_val);
          break;
        case "4":
          this.getTimesheetLogs(this.curPeople.person_id, this.curPeriod.from_date, this.curPeriod.to_date, this.curYear, this.curPeriod.period_val);
          break;
        case "5":
          this.getPlanningLogs(this.curPeople.person_id, this.curPeriod.from_date, this.curPeriod.to_date, this.curYear, this.curPeriod.period_val);
          break;
        case "6":
          this.getEmailLogs(this.curPeople.person_id, this.curPeriod.from_date, this.curPeriod.to_date, this.curYear, this.curPeriod.period_val);
          break;
        default:
          break;
      }

    }
  }

  getPeriodStatus(aPersId: number, fromDate: string, toDate: string, aYear: number, aPeriodNo: number) {
    this.isLoadingPeriodStatus = true;
    this.curLogs = [];
    this.tmsApiService.getPeriodStatus(aPersId, fromDate, toDate, aYear, aPeriodNo).subscribe((response) => {
      if (response) {
        this.curColumns = response.columns;
        this.curLogs = response.data;
        this.resetSearchFilter();
      }
      this.isLoadingPeriodStatus = false;
    });
  }

  getPeriodRepartition(aPersId: number, fromDate: string, toDate: string, aYear: number, aPeriodNo: number) {
    this.isLoadingPeriodRepartition = true;
    this.tmsApiService.getPeriodRepartition(aPersId, fromDate, toDate, aYear, aPeriodNo).subscribe((response) => {
      if (response) {
        this.curColumns = response.columns;
        this.curLogs = response.data;
      }
      this.isLoadingPeriodRepartition = false;
    });
  }

  getPeriodSuplement(aPersId: number, fromDate: string, toDate: string, aYear: number, aPeriodNo: number) {
    this.isLoadingPeriodSuplement = true;
    this.tmsApiService.getPeriodSuplement(aPersId, fromDate, toDate, aYear, aPeriodNo).subscribe((response) => {
      if (response) {
        this.curColumns = response.columns;
        this.curLogs = response.data;
      }
      this.isLoadingPeriodSuplement = false;
    });
  }

  getTimesheetLogs(aPersId: number, fromDate: string, toDate: string, aYear: number, aPeriodNo: number) {
    this.isLoadingTimesheetLogs = true;
    this.tmsApiService.getTimesheetLogs(aPersId, fromDate, toDate, aYear, aPeriodNo).subscribe((response) => {
      if (response) {
        this.curColumns = response.columns;
        this.curLogs = response.data;
      }
      this.isLoadingTimesheetLogs = false;
    });
  }

  getPlanningLogs(aPersId: number, fromDate: string, toDate: string, aYear: number, aPeriodNo: number) {
    this.isLoadingPlanningLogs = true;
    this.tmsApiService.getPlanningLogs(aPersId, fromDate, toDate, aYear, aPeriodNo).subscribe((response) => {
      if (response) {
        this.curColumns = response.columns;
        this.curLogs = response.data;
      }
      this.isLoadingPlanningLogs = false;
    });
  }

  getEmailLogs(aPersId: number, fromDate: string, toDate: string, aYear: number, aPeriodNo: number) {
    this.isLoadingPlanningLogs = true;
    this.tmsApiService.getEmailLogs(aPersId, fromDate, toDate, aYear, aPeriodNo).subscribe((response) => {
      if (response) {
        this.curColumns = response.columns;
        this.curLogs = response.data;
      }
      this.isLoadingPlanningLogs = false;
    });
  }

  getYears() {
    this.isLoadingYears = true;
    this.curYears = [];
    this.tmsApiService.getSupYears().subscribe((response) => {
      if (response) {
        this.curYears = response;
        this.curYear = new Date().getFullYear(); //- 1;
        this.getSupPeriods(this.curYear)
      }
      this.isLoadingYears = false;
    });
  }

  getSupPeriods(aYear: number): void {
    this.isLoadingSupPeriods = true;
    this.tmsApiService.getSupPeriods(aYear).subscribe((response) => {
      if (response.length) {
        this.curSupplementPeriod = response;
        this.curPeriod = this.curPeriod.period_val == -1 ? this.emptyPeriod : this.curSupplementPeriod.find(aPeriod => aPeriod.period_val == this.curPeriod.period_val);
      }
      this.isLoadingSupPeriods = false;
    });
  }

  openPopupHtml(aHtmlContent: string) : void {
    this.popupHtmlContent = aHtmlContent;
    this.displayPopup = true;
  }

  closePopupHtml(): void {
    this.displayPopup = false;
  }



}
