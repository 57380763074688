<div id="dep-main" *ngIf="dataLoading">
    <div class="loader">
        <img src="./assets/img/loader.gif" alt="loading...">
    </div>
</div>
<app-people *ngIf="isPeopleDisplayed" [archiveFlag]="true" [managedPeopleFlag]="false" (close)="closePeople()" (add)="addPeople($event)"></app-people> 
<app-people-contract *ngIf="isPeopleContractDisplayed" [archiveFlag]="true" (close)="closePeopleContract()"
    (create)=createNewEmployee() (modify)="modifyEmployee($event)"></app-people-contract> 
<div id="ser-main" class="noselect" *ngIf="!dataLoading">
    <div id="ser-top">
        <div id="ser-left">
            <div id="ser-sheet" class="grh-section ser-item">
                <h2 class="grh-section-title">
                    <label>
                        <span>{{dictionary.record}}</span>
                    </label>
                </h2>
                <div class="grh-section-content" *ngIf="!curServiceInfos">
                    <span>No data ! </span>
                </div>
                <div class="grh-section-content" *ngIf="curServiceInfos">
                    <div class="ser-field">
                        <label>{{dictionary.rptName}} </label>
                        <input type="text" [disabled]="isRO" [(ngModel)]="curServiceInfos.service_name">
                        <label for="chk-archived">{{dictionary.tmgArchived}} </label>
                        <input type="checkbox" id="chk-archived" [disabled]="isRO" [(ngModel)]="curServiceInfos.archive_flag">
                    </div>
                    <div class="ser-field">
                        <label>{{dictionary.sectionN}}</label>
                        <input type="text" [disabled]="isRO" [(ngModel)]="curServiceInfos.section_num">
                        <label>{{dictionary.responsible}}</label>
                        <select [disabled]="isRO" [(ngModel)]="curServiceInfos.responsible_id">
                            <option *ngFor="let resp of curResponsible" [ngValue]="resp.person_id">{{resp.person_name}}
                            </option>
                        </select>
                    </div>
                    <div class="ser-field">
                        <label>{{dictionary.taskMgmtDesc}}</label>
                        <input type="text" [disabled]="isRO" [(ngModel)]="curServiceInfos.service_desc">
                    </div>
                </div>
            </div>
            <div id="ser-various" class="grh-section ser-item" *ngIf="hasTS">
                <h2 class="grh-section-title" (click)="toggleSection('ser-various')">
                    <label>
                        <span *ngIf="!isSectionOpened('ser-various')" class="caret"><i
                                class="fa fa-caret-right"></i></span>
                        <span *ngIf="isSectionOpened('ser-various')" class="caret"><i
                                class="fa fa-caret-down"></i></span>
                        <span>{{dictionary.labelOther}}</span>
                    </label>
                </h2>
                <div class="grh-section-content" *ngIf="!curServiceInfos">
                    <span>No data ! </span>
                </div>
                <div class="grh-section-content" *ngIf="curServiceInfos && isSectionOpened('ser-various')">
                    <div id="ser-varcol">
                        <div id="ser-var1">
                            <div class="ser-field">
                                <label>{{dictionary.validationLevel}}</label>
                                <select [disabled]="isRO" [(ngModel)]="curServiceInfos.validation_level">
                                    <option value="SRV">{{dictionary.rptServ}}</option>
                                    <option value="DPT">{{dictionary.department}}</option>
                                </select>
                            </div>
                            <!--<div *ngIf="!newService">
                                <div class="ser-field">
                                    <label>{{curServiceInfos.period_type_desc}}</label>
                                </div>
                                <div class="ser-field" *ngIf="curServiceInfos.period_type_val == '4WEEK'">
                                    <label>{{dictionary.moveToAYearPeriod}}</label>
                                    <input type="checkbox" [(ngModel)]="curServiceInfos.next_period_flag">
                                </div>
                                <div class="ser-field" *ngIf="curServiceInfos.next_period_flag">
                                    <label>{{dictionary.fromPeriod}}</label>
                                    <select>
                                        <option *ngFor="let period of curSupplementPeriod" value="period.period_val">
                                            {{period.period_val}} </option>
                                    </select>
                                </div>
                            </div>-->
                            <div *ngIf="newService">
                                <div class="ser-field">
                                    <label>{{dictionary.typeOfPeriod}}</label>
                                    <select [disabled]="isRO" [(ngModel)]="curServiceInfos.period_type_val">
                                        <option></option>
                                        <option value="4WEEK">{{dictionary.fourWeeks}}</option>
                                        <option value="1YEAR">{{dictionary.oneYear}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div id="ser-var2">
                            <div class="ser-field">
                                <label for="chk-prest-flag">{{dictionary.perfomedTasksEncoding }}</label>
                                <input type="checkbox" id="chk-prest-flag" [disabled]="isRO" [(ngModel)]="curServiceInfos.presta_flag">
                            </div>
                            <div class="ser-field">
                                <label for="chk-sup-prest-flag">{{dictionary.onlyExtraHours}}</label>
                                <input type="checkbox" id="chk-sup-prest-flag" [disabled]="isRO"
                                    [(ngModel)]="curServiceInfos.only_sup_presta_flag">
                            </div>
                            <div class="ser-field">
                                <label for="chk-period-flag">{{dictionary.periodEncoding}}</label>
                                <input type="checkbox" id="chk-period-flag" [disabled]="isRO" [(ngModel)]="curServiceInfos.period_flag">
                            </div>
                            <div class="ser-field">
                                <label for="chk-fact-flag">{{dictionary.labelInvoicedTime}}</label>
                                <input type="checkbox" id="chk-fact-flag" [disabled]="isRO"
                                    [(ngModel)]="curServiceInfos.fact_length_flag">

                            </div>
                        </div>
                        <div id="ser-var3">
                            <div class="ser-field" *ngIf="curServiceInfos.period_type_desc == dictionary.oneYearPeriod">
                                <label for="chk-automanaged">{{dictionary.serviceAutomanaged}}</label>
                                <input type="checkbox" id="chk-automanaged" [disabled]="isRO"
                                    [(ngModel)]="curServiceInfos.is_service_automanaged">
                            </div>
                            <!--<div class="ser-field">
                                <label for="chk-pointing">{{dictionary.timePeriod}}</label>
                                <input type="checkbox" id="chk-pointing" [(ngModel)]="curServiceInfos.pointing_flag">
                            </div>
                            <div *ngIf="curServiceInfos.pointing_flag">
                                <div class="ser-field">
                                    <label>{{dictionary.default}}</label>
                                </div>
                                <div class="ser-field">
                                    <label>{{dictionary.AM}}</label>
                                    <input type="time" step="900" [(ngModel)]="curServiceInfos.am_from_time">→
                                    <input type="time" step="900" [(ngModel)]="curServiceInfos.am_to_time">
                                </div>
                                <div class="ser-field">
                                    <label>{{dictionary.PM}}</label>
                                    <input type="time" step="900" [(ngModel)]="curServiceInfos.pm_from_time">→
                                    <input type="time" step="900" [(ngModel)]="curServiceInfos.pm_to_time">
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div id="ser-mails" class="grh-section ser-item" *ngIf="hasTS">
                <h2 class="grh-section-title">
                    <label>
                        <span class="caret">
                            <i class="fa fa-caret-right"></i>
                        </span>
                        <span>{{dictionary.mailPeriodEnd}}</span>
                    </label>
                </h2>
              <!--  <div class="grh-section-content" *ngIf="!curMailEncoding || !curMailValidation">
                    <span>No data ! </span>
                </div>-->
                <div class="grh-section-content" *ngIf="curMailEncoding && curMailValidation">
                    <div class="ser-header">
                        <label for="chk-encod-mail">{{dictionary.mailReminderEncoder}}</label>
                        <input type="checkbox" id="chk-encod-mail" [disabled]="isRO" [(ngModel)]="curMailEncoding.send_mail_flag">
                    </div>
                    <div class="ser-field" *ngIf="curMailEncoding.send_mail_flag">
                        <label>{{dictionary.delayPeriodEnd}}</label>
                        <input type="number" min="0" [disabled]="isRO" [(ngModel)]="curMailEncoding.nb_days">
                        <label>{{dictionary.days}}</label>
                    </div>
                    <div class="ser-field" *ngIf="curMailEncoding.send_mail_flag">
                        <label>{{dictionary.recipient}}</label>
                        <select [disabled]="isRO" [(ngModel)]="curMailEncoding.dest_type">
                            <option value="USER">{{dictionary.employee}}</option>
                            <option value="MAN">{{dictionary.supervisor}}</option>
                        </select>
                    </div>
                    <div class="ser-field" *ngIf="curMailEncoding.send_mail_flag">
                        <label>{{dictionary.frequency}}</label>
                        <select [disabled]="isRO" [(ngModel)]="curMailEncoding.mail_frequency">
                            <option value="ONCE">{{dictionary.once}}</option>
                            <option value="EVERYDAY">{{dictionary.everyday}}</option>
                        </select>
                    </div>
                    <div class="ser-header">
                        <label for="chk-valid-mail">{{dictionary.mailReminderVal1}}</label>
                        <input type="checkbox" id="chk-valid-mail" [disabled]="isRO" [(ngModel)]="curMailValidation.send_mail_flag">
                    </div>
                    <div class="ser-field" *ngIf="curMailValidation.send_mail_flag">
                        <label>{{dictionary.delayPeriodEnd}}</label>
                        <input type="number" min="0" [disabled]="isRO" [(ngModel)]="curMailValidation.nb_days">
                        <label>{{dictionary.days}}</label>
                    </div>
                    <div class="ser-field" *ngIf="curMailValidation.send_mail_flag">
                        <label>{{dictionary.frequency}}</label>
                        <select [disabled]="isRO" [(ngModel)]="curMailValidation.mail_frequency">
                            <option value="ONCE">{{dictionary.once}}</option>
                            <option value="EVERYDAY">{{dictionary.everyday}}</option>
                        </select>
                    </div>
                    <div class="ser-field" *ngIf="curMailValidation.send_mail_flag">
                        <label for="chk-only-sup">{{dictionary.onlyIfSup}}</label>
                        <input type="checkbox" id="chk-only-sup" [disabled]="isRO" [(ngModel)]="curMailValidation.only_if_sup_flag">
                    </div>
                </div>
            </div>
            <div id="ser-bottom" *ngIf="hasTS">
                <div id="ser-init" class="grh-section ser-item" *ngIf="false">
                    <h2 class="grh-section-title">
                        <label>
                            <span class="caret">
                                <i class="fa fa-caret-right"></i>
                            </span>
                            <span>{{dictionary.initGrid}}</span>
                        </label>
                    </h2>
                    <div class="grh-section-content">
                        <div class="ser-field">
                            <label>Monday</label>
                            <button> + </button>
                            <button> - </button>
                            <label>{{dictionary.total}} 00.00</label>
                        </div>
                        <div class="ser-field">
                            <label>Tuesday</label>
                            <button> + </button>
                            <button> - </button>
                            <label>{{dictionary.total}} 00.00</label>
                        </div>
                        <div class="ser-field">
                            <label>Wednesday</label>
                            <button> + </button>
                            <button> - </button>
                            <label>{{dictionary.total}} 00.00</label>
                        </div>
                        <div class="ser-field">
                            <label>Thursday</label>
                            <button> + </button>
                            <button> - </button>
                            <label>{{dictionary.total}} 00.00</label>
                        </div>
                        <div class="ser-field">
                            <label>Friday</label>
                            <button> + </button>
                            <button> - </button>
                            <label>{{dictionary.total}} 00.00</label>
                        </div>
                        <div class="ser-field">
                            <label>Saturday</label>
                            <button> + </button>
                            <button> - </button>
                            <label>{{dictionary.total}} 00.00</label>
                        </div>
                        <div class="ser-field">
                            <label>Sunday</label>
                            <button> + </button>
                            <button> - </button>
                            <label>{{dictionary.total}} 00.00</label>
                        </div>
                    </div>
                </div>
                <div id="ser-param" class="grh-section ser-item">
                    <h2 class="grh-section-title">
                        <label>
                            <span class="caret">
                                <i class="fa fa-caret-right"></i>
                            </span>
                            <span>{{dictionary.serviceParameters}}</span>
                        </label>
                    </h2>
                    <div class="grh-section-content" *ngIf="!curSupplementParams">
                        <span>No data ! </span>
                    </div>
                    <div class="grh-section-content" *ngIf="curSupplementParams">
                        <div id="ser-param-col">
                            <div id="ser-param-col1">
                                <div class="ser-field">
                                    <label for="chk-work-hours">{{dictionary.labelWorkHours}}</label>
                                    <input type="checkbox" id="chk-work-hours" [disabled]="isRO"
                                        [(ngModel)]="curSupplementParams.work_hours_flag">
                                </div>
                                <div class="ser-field">
                                    <label for="chk-overtime">{{dictionary.labelOvertime}}</label>
                                    <input type="checkbox" id="chk-overtime" [disabled]="isRO"
                                        [(ngModel)]="curSupplementParams.overtime_flag">
                                </div>
                                <div class="ser-field">
                                    <label for="chk-bank">{{dictionary.labelBankHoliday}}</label>
                                    <input type="checkbox" id="chk-bank" [disabled]="isRO"
                                        [(ngModel)]="curSupplementParams.bank_holiday_flag">
                                </div>
                                <div class="ser-field">
                                    <label for="chk-night-hours">{{dictionary.labelNightHours}}</label>
                                    <input type="checkbox" id="chk-night-hours" [disabled]="isRO"
                                        [(ngModel)]="curSupplementParams.night_hours_flag">
                                </div>
                                <div class="ser-field">
                                    <label for="chk-oncall">{{dictionary.labelOnCallHours}}</label>
                                    <input type="checkbox" id="chk-oncall" [disabled]="isRO"
                                        [(ngModel)]="curSupplementParams.on_call_hours_flag">
                                </div>
                                <div class="ser-field">
                                    <label for="chk-work-call">{{dictionary.labelWorkHoursOnCall}}</label>
                                    <input type="checkbox" id="chk-work-call" [disabled]="isRO"
                                        [(ngModel)]="curSupplementParams.work_hours_on_call_flag">
                                </div>
                            </div>
                            <div id="ser-param-col2">
                                <div class="ser-field">
                                    <label for="chk-sunday">{{dictionary.labelSundayHours}}</label>
                                    <input type="checkbox" id="chk-sunday" [disabled]="isRO"
                                        [(ngModel)]="curSupplementParams.sunday_hours_flag">
                                </div>
                                <div class="ser-field">
                                    <label for="chk-night">{{dictionary.labelNights}}</label>
                                    <input type="checkbox" id="chk-night" [disabled]="isRO" [(ngModel)]="curSupplementParams.nights_flag">
                                </div>
                                <div class="ser-field">
                                    <label for="chk-hpylone">{{dictionary.labelHPylone}}</label>
                                    <input type="checkbox" id="chk-hpylone" [disabled]="isRO"
                                        [(ngModel)]="curSupplementParams.hpylone_flag">
                                </div>
                                <div class="ser-field">
                                    <label for="chk-mpylone">{{dictionary.labelMPylone}}</label>
                                    <input type="checkbox" id="chk-mpylone" [disabled]="isRO"
                                        [(ngModel)]="curSupplementParams.mpylone_flag">
                                </div>
                                <div class="ser-field">
                                    <label for="chk-fmaj">{{dictionary.labelFMaj}}</label>
                                    <input type="checkbox" id="chk-fmaj" [disabled]="isRO" [(ngModel)]="curSupplementParams.fmaj_flag">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="ser-right">
            <div id="ser-ppl">
                <div id="ser-time" class="grh-section ser-item">
                    <h2 class="grh-section-title" (click)="toggleSection('ser-time')">
                        <label>
                            <span *ngIf="!isSectionOpened('ser-time')" class="caret"><i
                                    class="fa fa-caret-right"></i></span>
                            <span *ngIf="isSectionOpened('ser-time')" class="caret"><i
                                    class="fa fa-caret-down"></i></span>
                            <span>{{dictionary.timeManagers}}</span>
                        </label>
                        <div class="actions">
                            <span class="action-item" *ngIf="!isRO" (click)="showPeople(); $event.stopPropagation();"> (+) {{dictionary.new}}
                            </span>
                        </div>
                    </h2>
                    <div class="grh-section-content" *ngIf="!curServiceTime">
                        <span>No data ! </span>
                    </div>
                    <div class="grh-section-content" *ngIf="curServiceTime && isSectionOpened('ser-time')">
                        <div class="list">
                            <div class="list-item" *ngFor="let time of curServiceTime">
                                <div class="data">{{time.person_name}}</div>
                                <div class="actions">
                                    <span class="action-item" *ngIf="!isRO" (click)="deleteRole(time,'TIME')"><i
                                            class="fa fa-trash"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="ser-bottombar">
        <div id="left-btn-grp" class="button-group">
            <button *ngIf="!newService" [disabled]="isRO" (click)="showPeopleContract()">{{dictionary.new}} {{dictionary.newContractEmp}}</button>
            <button *ngIf="newService" (click)="cancelAction()">{{dictionary.cancel}}</button>
            <button [disabled]="isRO" (click)="saveService()">{{dictionary.tmgSave}}</button>
            <button  *ngIf="!newService && isUserSA" (click)="delete()">{{dictionary.titleDelete}}</button>
        </div>
    </div>
</div>