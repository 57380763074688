import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { Company, CompanyDict, CompanyInfos, CompanySupplementsParam, NodeSelect, SupplementProfile } from '@app/models';
import { DictService, TmsApiService, UserService } from '@app/services';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  @Input() companyId: number;
  @Input() newCompany: Company;
  @Input() isRO : boolean;
  @Output() createDepartment = new EventEmitter();
  @Output() createCompany = new EventEmitter();
  @Output() refreshTree = new EventEmitter<any>();
  @Output() cancel = new EventEmitter();

  curCompanyInfos: CompanyInfos;
  curCompanySupplementsParams: CompanySupplementsParam[];
  isLoadingCompanyInfos: boolean;
  isLoadingSupProfileList : boolean;
  curSupProfileList : SupplementProfile[];
  curSupProfile : SupplementProfile;

  constructor(private dictService: DictService, private tmsApiService: TmsApiService, private userService : UserService) { }

  ngOnInit(): void {
    this.curCompanyInfos = null;
    this.isLoadingCompanyInfos = false;
    this.isLoadingSupProfileList = false;
    this.curSupProfileList = null;
    this.curSupProfile = null;
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName === 'companyId') {
        if (this.newCompany == null && this.companyId > -1) {
          this.getCompany();
        }
        else {
          this.initNew();
        }
      }
    }
  }

  get dictionary(): CompanyDict {
    return this.dictService.getCompanycDict();
  }

  public get dataLoading(): boolean {
    return this.isLoadingCompanyInfos && this.isLoadingSupProfileList;
  }

  public get isUserSA(): boolean {
    return this.userService.isSa;
  }

  newDepartment() {
    this.createDepartment.emit();
  }

  createNewCompany() {
    this.createCompany.emit();
  }

  initNew() {
    this.curCompanyInfos = new CompanyInfos();
  }
  
  checkFields(): string {

    if (this.curCompanyInfos.company_name == "" || this.curCompanyInfos.company_name == null)  return this.dictionary.fillInName;
    if (this.curCompanyInfos.company_displayname == "" || this.curCompanyInfos.company_displayname == null) return this.dictionary.fillDisplayNameIn;
    if (this.curCompanyInfos.company_shortname == "" || this.curCompanyInfos.company_shortname == null) return this.dictionary.fillAbbreviationIn
    if (this.curCompanyInfos.company_shortname.length > 3) return this.dictionary.abbrMaxChars;
    //if (this.curCompanyInfos.company_domain == "" || this.curCompanyInfos.company_domain == null) return this.dictionary.fillAbbreviationIn

    return "";
  }

  saveCompany() {
    let errorMessage: string = this.checkFields();

    if (errorMessage != "") {
      window.alert(errorMessage);
    }
    else {

      this.tmsApiService.getCmpUnicity(this.curCompanyInfos.company_id, this.curCompanyInfos.company_name).subscribe((response) => {
        if (response.length) {
          if (!response[0].ok_flag) {
            //window.alert(this.dictionary.nameOrAbbreviationExistsAlready);
            window.alert(this.dictionary.companyAlreadyExists);
          }
          else {
            let theData: any = {
              companyInfos: {
                company_id: this.curCompanyInfos.company_id,
                company_name: this.curCompanyInfos.company_name,
                company_shortname: this.curCompanyInfos.company_shortname,
                company_displayname: this.curCompanyInfos.company_displayname,
                company_domain: this.curCompanyInfos.company_domain,
                supplement_profile_id : this.curCompanyInfos.supplement_profile_id,
                quarter_round_flag: this.curCompanyInfos.quarter_round_flag,
                has_timesheet: this.curCompanyInfos.has_timesheet ,
                has_planning: this.curCompanyInfos.has_planning,
                has_hr_interface: this.curCompanyInfos.has_hr_interface,
                archive_flag: this.curCompanyInfos.archive_flag,
                full_time_week_hours: this.curCompanyInfos.full_time_week_hours
              },
              supplementsParams: this.curCompanySupplementsParams
            }
            this.postSaveCompany(theData);
          }
        }
      });
    }
  }

  cancelAction() {
    this.cancel.emit();
  }

  //API CALLS

  public getCompany() {
    this.getCompanyInfo(this.companyId);
    this.getSupProfileList();
  }

  getCompanyInfo(aCompId: number): void {
    this.isLoadingCompanyInfos = true;
    this.tmsApiService.getCmpInfos(aCompId).subscribe((response) => {
      if (response) {
        this.curCompanyInfos = response.compagny_infos;
        this.curCompanySupplementsParams = response.compagny_suppl_params;
      }
      this.isLoadingCompanyInfos = false;
    });
  }

  getSupProfileList() : void {
    this.isLoadingCompanyInfos = true;
    this.tmsApiService.getSupProfileList().subscribe((response) => {
      if (response) {
        this.curSupProfileList = response[0];
      }
      this.isLoadingCompanyInfos = false;
    });
  }

  postSaveCompany(aCom: any) {
    this.tmsApiService.postSaveCom(aCom).subscribe((response) => {
      if (response) {
        let theNewComId: number = response[0].company_id;
        this.getCompanyInfo(theNewComId);
        this.refreshTree.emit(new NodeSelect(theNewComId, "company"));
      }
    });
  }

}
