import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DictService, TmsApiService,UserService } from '@app/services';
import { PublicHoliday, PublicHolidayType } from '@app/models/publicHolidays';
import { DatePipe } from '@angular/common';
import {formatDate} from '@angular/common';

@Component({
    selector: 'app-publicHolidays',
    templateUrl: './publicHolidays.component.html',
    styleUrls: ['./publicHolidays.component.scss'],
    providers: [DatePipe]
  })
  
  export class PublicHolidaysComponent implements OnInit {
    displayPpAddNewPublicHoliday : boolean;
    displayPpAddNewTypePublicHoliday : boolean;
    listOfPublicHolidayType : PublicHolidayType[];
    listOfPublicHolidays : PublicHoliday[];
    publicHolidayTypeLoading : boolean;
    publicHolidayLoading : boolean;
    selectedPublicHolidayType : PublicHolidayType;
    listOfCompaniesCB : any[];
    listOfCompanies : any[];
    listOfYears : any[];
    selectedCompanies : any[];
    curDate : string;
    curYear : string;
    displayTable : boolean;

    constructor(private tmsApiService: TmsApiService, private dictService: DictService,private userService: UserService){}

    ngOnInit(): void {
      this.displayPpAddNewPublicHoliday = false;
      this.displayPpAddNewTypePublicHoliday = false;     
      this.publicHolidayTypeLoading = false;
      this.selectedPublicHolidayType = null;
      this.selectedCompanies = [];
      this.publicHolidayLoading = true;
      this.curDate = null;
      this.curYear = null;
      this.listOfYears = [];
      this.displayTable = false;
      this.listOfCompanies = [];
      this.loadListOfCompanies();
      this.loadListOfYears();
    }

    onChangeCompany(){
      this.publicHolidayLoading = true;
      this.loadListOfPublicHolidays();
    }

    //Charge la liste des années de 2015 à curren year + 1 pour alimenter la CB
    loadListOfYears(){
      this.listOfYears = [];
      const CurrentD = new Date();
      const NbCount = CurrentD.getFullYear()+1 - (2015-1);
      
      for (let i: number = 0; i < NbCount; i++) {
        this.listOfYears.push(i+2015);
      }
      this.curYear = CurrentD.getFullYear().toString();
      this.loadListOfPublicHolidays();
    }

    //Charge la liste de toutes les companies de la DB pour alimenter la CB
    loadListOfCompanies(){
      this.listOfCompaniesCB = [];
      this.tmsApiService.getCompanyList().subscribe((response) => {
        this.listOfCompaniesCB = response;
        //1.company_id
        //2.company_displayname
        //3.archive_flag
      });
    }

    //Charge la liste des fériés dans la grille principale par rapport à l'année sélectionnée.
    loadListOfPublicHolidays(){
      if ((this.selectedCompanies) && (this.selectedCompanies.length > 0)) {
        this.displayTable = true;
        this.listOfPublicHolidays = [];
        this.tmsApiService.getloadListOfPublicHolidays(Number(this.curYear)).subscribe((response) => {
          this.listOfCompanies.push(response[1]);
          this.listOfPublicHolidays.push(response[0]);

          let TmpL = [];
          let TempList = []; 
          TempList.push(this.listOfPublicHolidays[0]);

          for (const aComp of this.selectedCompanies){
            var t = this.getCompanyNameByIDD(aComp);
            for (const aTmp of TempList[0]) {
              var newobj = Object.assign({}, aTmp[t], {t : 2015});
              TmpL.push(newobj);
              //delete aTmp[t];              
            }
          }

          for (const aPublicH of TempList[0]) {
            var count = Object.keys(aPublicH).length;
            for (let i:number = 0;i<count;i++){
              var ColName = Object.keys(this.listOfPublicHolidays[0][0])[i];
              if (ColName != "holiday_description" && ColName != "holiday_id" && ColName != "test"){
                  let Cellval = aPublicH[ColName];
                  if(Cellval){
                    const aJOUR = Cellval.substring(0, 2);
                    const aMOIS = Cellval.substring(3, 5);
                    const aYEAR = Cellval.substring(6, 11);
                    aPublicH[ColName] = new Date(aMOIS+'/'+aJOUR+'/'+aYEAR);
                  }
              }
            }
          }
          this.publicHolidayLoading = false;
        });
      }
    }

    getCompanyIDByName(aName:any){   //aName='BCE'   RESULT=1
      if (this.selectedCompanies){
        for (const aComp of this.selectedCompanies) {
          if(aComp==aName){
            return aComp;
          }
        }
      }
      return '';
    }

    getCompanyNameByID(aID:any){   //aID=1    RESULT='BCE'
      if (this.listOfCompanies[0]){
        for (const aComp of this.listOfCompanies[0]) {
          if(aComp.company_id==aID){
            return aComp.company_displayname;
          }
        }
      }
      return '';
    }

    getCompanyNameByIDD(aName:any){   //aID=1    RESULT='BCE'
      if (this.listOfCompanies[0]){
        for (const aComp of this.listOfCompanies[0]) {
          if(aComp.company_displayname==aName){
            return aComp.company_id;
          }
        }
      }
      return '';
    }

    //Evénement lancé quand on change d'année dans la CB
    onChangeYear(){
      this.publicHolidayLoading = true;
      this.loadListOfPublicHolidays();
    }

    convertDate(aDate:any){
      return new Date(aDate);
    }


    loadListOfPublicHolidayType(){
      this.publicHolidayTypeLoading = true;
      this.listOfPublicHolidayType = [];
      this.tmsApiService.getloadListOfPublicHolidayType().subscribe((response) => {
        this.listOfPublicHolidayType.push(response);
        this.publicHolidayTypeLoading = false;
      });
    }

    openTypePublicHoliday(){
      this.loadListOfPublicHolidayType();      
      this.displayPpAddNewTypePublicHoliday = true;
    }

    addNewTypePublicHolidayToDB(){
      const Val = prompt('Veuillez entrer un nouveau type de jour férié:');
      if ((Val !=null) && (Val.trim() !='')){
        let data: any = {
          holiday_desc : Val,
          create_date : new Date(),
          create_by : this.userService.userId, 
          mod_date : new Date(),
          mod_by : this.userService.userId,        
        };

        this.tmsApiService.insertPublicHolidayType(data).subscribe((response) => {
          this.loadListOfPublicHolidayType(); 
        });
      }
      else{
        alert("La valeur entrée n'est pas correcte");
      }
    }

    updatePublicHolidayType(){
      const Val = prompt('Veuillez entrer une nouvelle description:',this.selectedPublicHolidayType.holiday_desc);
      if ((Val !=null) && (Val.trim() !='')){
        let data: any = {
          holiday_id : this.selectedPublicHolidayType.holiday_id,
          holiday_desc : Val,
          mod_date : new Date(),
          mod_by : this.userService.userId,        
        };

        this.tmsApiService.updatePublicHolidayType(data).subscribe((response) => {
          this.loadListOfPublicHolidayType();
        });
      }
      else{
        alert("La valeur entrée n'est pas correcte");
      }
    }

    deletePublicHolidayType(){
      const Val = confirm('Voulez-vous vraiment supprimer :'+this.selectedPublicHolidayType.holiday_desc);
      if (Val){
        const aID = this.selectedPublicHolidayType.holiday_id;

        this.tmsApiService.deletePublicHolidayType(aID).subscribe((response) => {
          this.loadListOfPublicHolidayType(); 
        });
      }
    }

    selectPublicHolidayType(aPublicHolidayType: PublicHolidayType) {
      this.selectedPublicHolidayType = aPublicHolidayType;
    }

    ClosePopupType(){
      this.displayPpAddNewTypePublicHoliday = false;
      this.loadListOfPublicHolidays();
    }

    /*openPublicHoliday(){

      this.loadListOfPublicHolidays();
      this.displayPpAddNewPublicHoliday = true;
    }*/
    
    getColumnsCount(): number{
      return 3;
    }

   /* onDateChange(event: Event): void {
      const inputElement = event.target as HTMLInputElement;
      const dateArray = inputElement.value.split('-').map(Number);
      this.curDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2], 12, 0, 0, 0).toISOString().split('T')[0];
      console.log('CurrentDate: ',this.curDate);
    }*/


    onDateCellChange(aLine: any, aComa :any, aEvt : Event): void {
      const inputElement = aEvt.target as HTMLInputElement;
      const dateArray = inputElement.value.split('-').map(Number);

      let aDelFlag = 1;
      if (aLine[aComa.company_id]){
        aDelFlag = 0;
      }  

      let aData: any = {
        //holiday_det_id
        company_id : aComa.company_id,
        holiday_id : aLine.holiday_id,
        date_val : new Date(aLine[aComa.company_id]),
        year : this.curYear,//new Date(aLine[aComa.company_id]).getFullYear(),
        is_deleted_flag : aDelFlag,
        create_date : new Date(),
        create_by : this.userService.userId, 
        mod_date : new Date(),
        mod_by : this.userService.userId 
      };

      this.tmsApiService.insertOrUpdateHolidayDet(aData).subscribe((response) => {
      });

    }
  }