import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { Department, DepartmentDict, DepartmentHeader, DepartmentInfos, DepartmentMail, DepartmentMember, DepartmentRole, NodeSelect, People, Responsible } from '@app/models';
import { DictService, TmsApiService, UserService } from '@app/services';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  @Input() departmentId: number;
  @Input() newDepartment: Department;
  @Input() hasTS: boolean;
  @Input() isRO : boolean;
  @Output() createService = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() refreshTree = new EventEmitter<any>();

 //curDepartmentHeader: DepartmentHeader;
  curDepartmentInfos: DepartmentInfos;
  curDepartmentMail: DepartmentMail[];
  curValidateurN2: DepartmentMail;
  curValidateurN1: DepartmentMail;
  curDepartmentRoleTime: DepartmentRole[];
  curDepartmentRoleCTR: DepartmentRole[];
  curDepartmentRoleHR: DepartmentRole[];
  curDepartmentMember: DepartmentMember[];
  curResponsible: Responsible[];
  curRolePeople: string;
  openedSection: string[];
  isPeopleDisplayed: boolean;
  isLoadingDepartmentHeader: boolean;
  isLoadingDepartmentInfos: boolean;
  isLoadingDepartmentRoles: boolean;
  isLoadingDepartmentMembers: boolean;
  isLoadingResponsible: boolean;
  deleteTime: DepartmentRole[];
  deleteCTR: DepartmentRole[];
  deleteHR: DepartmentRole[];
  countWaitingCommits: number;

  constructor(private tmsApiService: TmsApiService, private userService: UserService, private dictService: DictService) {
    this.curDepartmentInfos = null;
    this.curValidateurN2 = new DepartmentMail();
    this.curValidateurN1 = new DepartmentMail();
    this.curDepartmentRoleTime = [];
    this.curDepartmentRoleCTR = [];
    this.curDepartmentRoleHR = [];
  }

  ngOnInit(): void {
    this.openedSection = [];
    this.isPeopleDisplayed = false;
    //this.curDepartmentHeader = null;
    this.curDepartmentMember = [];
    this.curDepartmentMail = [];
    this.curResponsible = [];
    this.isLoadingDepartmentHeader = false;
    this.isLoadingDepartmentInfos = false;
    this.isLoadingDepartmentRoles = false;
    this.isLoadingDepartmentMembers = false;
    this.isLoadingResponsible = false;
    this.deleteTime = [];
    this.deleteCTR = [];
    this.deleteHR = [];
    this.countWaitingCommits = 0;
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName === 'departmentId') {
        if (this.newDepartment == null && this.departmentId > -1) {
          this.openedSection = [];
          this.getDepartment(this.departmentId);
        }
        else {
          this.initNew();
        }
      }
    }
  }

  initNew() {
    this.openedSection = [];
    this.curDepartmentInfos = new DepartmentInfos();
    this.curDepartmentInfos.company_id = this.newDepartment.companyId;
    //this.curDepartmentHeader = null;
    this.curDepartmentMember = [];
    this.curDepartmentMail = [];
    this.curValidateurN2 = new DepartmentMail();
    this.curValidateurN1 = new DepartmentMail();
    this.curDepartmentRoleCTR = [];
    this.curDepartmentRoleTime = [];
    this.curDepartmentRoleHR = [];
    this.getResponsible();
  }

  public get isUserSA(): boolean {
    return this.userService.isSa;
  }

  public get dataLoading(): boolean {
    return (this.isLoadingDepartmentHeader || this.isLoadingDepartmentInfos || this.isLoadingDepartmentRoles
      || this.isLoadingDepartmentMembers || this.isLoadingResponsible);
  }

  get dictionary(): DepartmentDict {
    return this.dictService.getDepartmentDict();
  }

  closePeople(): void {
    this.isPeopleDisplayed = false;
  }

  showPeople(aRole: string): void {
    this.curRolePeople = aRole;
    this.isPeopleDisplayed = true;
  }

  toggleSection(aSectionName: string): void {
    let i = this.openedSection.indexOf(aSectionName);
    if (i < 0) {
      this.openedSection.push(aSectionName);
    } else {
      this.openedSection.splice(i, 1);
    }
  }

  openSection(aSectionName: string): void {
    if (!this.isSectionOpened(aSectionName)) {
      this.openedSection.push(aSectionName);
    }
  }

  closeSection(aSectionName: string): void {
    let i = this.openedSection.indexOf(aSectionName);
    if (i > -1) {
      this.openedSection.splice(i, 1);
    }
  }

  isSectionOpened(aSectionName: string): boolean {
    return this.openedSection.indexOf(aSectionName) > -1;
  }

  newService() {
    this.createService.emit();
  }

  addPeople(aPerson: People) {
    let theNewDepRole: DepartmentRole = new DepartmentRole(aPerson.person_id, aPerson.person_name, true);

    switch (this.curRolePeople) {
      case "TIME":
        this.curDepartmentRoleTime.push(theNewDepRole);
        this.openSection('dep-time');
        break;
      case "CTR":
        this.curDepartmentRoleCTR.push(theNewDepRole);
        this.openSection('dep-cont');
        break;
      case "ADMINRH":
        this.curDepartmentRoleHR.push(theNewDepRole);
        this.openSection('dep-hrad');
        break;
    }
  }

  deleteRole(aPerson: DepartmentRole, aRole: string) {
    let confirmMessage: string = this.dictionary.confirmDeleteRole + " ";
    switch (aRole) {
      case "TIME":
        confirmMessage += this.dictionary.timeManagers.toLowerCase() +" ?";
        break;
      case "CTR":
        confirmMessage += this.dictionary.controllers.toLowerCase()+" ?";
        break;
      case "ADMINRH":
        confirmMessage += this.dictionary.adminsRH.toLowerCase()+" ?";
        break;
      default:
        break;
    }
    let confirm = window.confirm(confirmMessage);
    if (confirm) {
      switch (aRole) {
        case "TIME":
          this.deleteTime.push(aPerson);
          this.curDepartmentRoleTime.splice(this.curDepartmentRoleTime.indexOf(aPerson), 1);
          break;
        case "CTR":
          this.deleteCTR.push(aPerson);
          this.curDepartmentRoleCTR.splice(this.curDepartmentRoleCTR.indexOf(aPerson), 1);
          break;
        case "ADMINRH":
          this.deleteHR.push(aPerson);
          this.curDepartmentRoleHR.splice(this.curDepartmentRoleHR.indexOf(aPerson), 1);
          break;
        default:
          break;
      }
    }
  }

  checkFields(): string {

    if (this.curDepartmentInfos.department_name == "" || this.curDepartmentInfos.department_name == null) return this.dictionary.fillInName;
    if (this.curDepartmentInfos.department_shortname == "" || this.curDepartmentInfos.department_shortname == null) return this.dictionary.fillAbbreviationIn

    return "";
  }

  saveDepartment() {
    
    let errorMessage: string = this.checkFields();

    if (errorMessage != "") {
      window.alert(errorMessage);
    }
    else {

      this.tmsApiService.getDeptUnicity(this.curDepartmentInfos.department_id, this.curDepartmentInfos.department_name, this.curDepartmentInfos.company_id).subscribe((response) => {
        if (response.length) {
          if (!response[0].ok_flag) {
            window.alert(this.dictionary.nameOrAbbreviationExistsAlready);
          }
          else {
            let theData: any = {
              department_id: this.curDepartmentInfos.department_id,
              department_name: this.curDepartmentInfos.department_name,
              company_id: this.curDepartmentInfos.company_id,
              department_shortname: this.curDepartmentInfos.department_shortname,
              responsible_id: this.curDepartmentInfos.responsible_id,
              archive_flag: this.curDepartmentInfos.archive_flag,
              mailsParamsValidation: this.curValidateurN2,
              mailsParamsAllVal: this.curValidateurN1,
            }
            this.postSaveDepartment(theData);
          }
        }
      });
    }
  }

  manageDepRoles(aDepId: number) {

    this.countWaitingCommits = this.curDepartmentRoleTime.filter(time => time.to_add == true).length + this.deleteTime.length
      + this.curDepartmentRoleCTR.filter(CTR => CTR.to_add == true).length + this.deleteCTR.length
      + this.curDepartmentRoleHR.filter(HR => HR.to_add == true).length + this.deleteHR.length;

    if (this.countWaitingCommits == 0) {
      this.checkisOver(aDepId);
    }
    else {
      for (const time of this.curDepartmentRoleTime) {
        if (time.to_add) {
          let theTime: any = {
            person_id: time.person_id,
            department_id: aDepId
          }
          this.postAddDptTime(theTime, aDepId);
        }
      }

      for (const time of this.deleteTime) {
        let theTime: any = {
          person_id: time.person_id,
          department_id: aDepId
        }
        this.postDeleteDptTime(theTime, aDepId);
      }

      for (const ctr of this.curDepartmentRoleCTR) {
        if (ctr.to_add) {
          let theCTR: any = {
            person_id: ctr.person_id,
            department_id: aDepId
          }
          this.postAddDptCtrl(theCTR, aDepId);
        }
      }

      for (const ctr of this.deleteCTR) {
        let theCTR: any = {
          person_id: ctr.person_id,
          department_id: aDepId
        }
        this.postDeleteDptCtrl(theCTR, aDepId);
      }

      for (const hr of this.curDepartmentRoleHR) {
        if (hr.to_add) {
          let theHR: any = {
            person_id: hr.person_id,
            department_id: aDepId
          }
          this.postAddDptHR(theHR, aDepId);
        }
      }

      for (const HR of this.deleteHR) {
        let theHR: any = {
          person_id: HR.person_id,
          department_id: aDepId
        }
        this.postDeleteDptHR(theHR, aDepId);
      }
    }
  }

  checkisOver(aDepId: number) {
    if (this.countWaitingCommits > 0) {
      this.countWaitingCommits--;
    }
    if (this.countWaitingCommits == 0) {
      this.getDepartment(aDepId);
      this.refreshTree.emit(new NodeSelect(aDepId, "department"));
      
    }
  }

  delete() {
    let confirm = window.confirm(this.dictionary.deleteDepartmentQuestion);
    if (confirm) {
      this.postDeleteDepartment(this.curDepartmentInfos.department_id, this.curDepartmentInfos.company_id);
    }
  }

  cancelAction() {
    this.cancel.emit();
  }

  //API CALLS

  /*getDepartmentHeader(aDepId: number): void {
    this.isLoadingDepartmentHeader = true;
    this.tmsApiService.getDptHeader(aDepId).subscribe((response) => {
      if (response.length) {
        this.curDepartmentHeader = response[0];
      }
      this.isLoadingDepartmentHeader = false;
    });
  }*/

  getDepartmentInfos(aDepId: number): void {
    this.isLoadingDepartmentInfos = true;
    this.tmsApiService.getDptInfos(aDepId).subscribe((response) => {
      if (response.length) {
        this.curDepartmentInfos = response[0][0];
        this.curDepartmentMail = response[1];
        for (const mail of this.curDepartmentMail) {
          if (mail.mail_type == "VALPERIODDPT") {
            this.curValidateurN2 = mail
          }
          else {
            this.curValidateurN1 = mail
          }
        }
      }
      this.isLoadingDepartmentInfos = false;
    });
  }

  postSaveDepartment(aDep: any) {
    this.tmsApiService.postSaveDept(aDep).subscribe((response) => {
      if (response) {
        let theNewDepId: number = response[0].department_id;
        this.manageDepRoles(theNewDepId);
      }
    });
  }

  getDepartmentRole(aDepId: number, aRole: string): void {
    this.isLoadingDepartmentRoles = true;
    this.tmsApiService.getDptRole(aDepId, aRole).subscribe((response) => {

      switch (aRole) {
        case "TIME":
          if (response.length) {
            this.curDepartmentRoleTime = response;
            this.toggleSection("dep-time");
          }
          else {
            this.curDepartmentRoleTime = [];
          }
          break;
        case "CTR":
          if (response.length) {
            this.curDepartmentRoleCTR = response;
            this.toggleSection("dep-cont");
          }
          else {
            this.curDepartmentRoleCTR = [];
          }
          break;
        case "ADMINRH":
          if (response.length) {
            this.curDepartmentRoleHR = response;
            this.toggleSection("dep-hrad");
          }
          else {
            this.curDepartmentRoleHR = [];
          }
          break;
        default:
          break;
      }
      this.isLoadingDepartmentRoles = false;
    });
  }

  public postAddDptTime(aDepTime: any, aDepId: number) {
    this.tmsApiService.postAddDptGDT(aDepTime).subscribe((response) => {
      if (response) {
        this.checkisOver(aDepId);
      }
    });
  }

  public postDeleteDptTime(aDepTime: any, aDepId: number) {
    this.tmsApiService.postDeleteDptGDT(aDepTime).subscribe((response) => {
      if (response) {
        this.checkisOver(aDepId);
      }
    });
  }

  public postAddDptCtrl(aDepCtrl: any, aDepId: number) {
    this.tmsApiService.postAddDptCtrl(aDepCtrl).subscribe((response) => {
      if (response) {
        this.checkisOver(aDepId);
      }
    });
  }

  public postDeleteDptCtrl(aDepCtrl: any, aDepId: number) {
    this.tmsApiService.postDeleteDptCtrl(aDepCtrl).subscribe((response) => {
      if (response) {
        this.checkisOver(aDepId);
      }
    });
  }

  public postAddDptHR(aDepHR: any, aDepId: number) {
    this.tmsApiService.postAddDptAdm(aDepHR).subscribe((response) => {
      if (response) {
        this.checkisOver(aDepId);
      }
    });
  }

  public postDeleteDptHR(aDepHR: any, aDepId: number) {
    this.tmsApiService.postDeleteDptAdm(aDepHR).subscribe((response) => {
      if (response) {
        this.checkisOver(aDepId);
      }
    });
  }

  public postDeleteDepartment(aDepId: number, aCompId: number) {
    this.tmsApiService.postDeleteDepartment({ department_id: aDepId }).subscribe((response) => {
      if (response) {
        this.refreshTree.emit(new NodeSelect(aCompId, "company"));
      }
    });
  }

  getDepartmentMembers(aDepId: number): void {
    this.isLoadingDepartmentMembers = true;
    this.tmsApiService.getDptMembers(aDepId).subscribe((response) => {
      if (response.length) {
        this.curDepartmentMember = response;
      }
      this.isLoadingDepartmentMembers = false;
    });
  }

  getResponsible(): void {
    this.isLoadingResponsible = true;
    this.tmsApiService.getResp().subscribe((response) => {
      if (response.length) {
        this.curResponsible = response;
      }
      this.isLoadingResponsible = false;
    });
  }

  getDeptUnicity(aDepId: number, aDepName: string, aCompId: number) {
    this.tmsApiService.getDeptUnicity(aDepId, aDepName, aCompId).subscribe((response) => {
      if (response.length) {
      }
    });
  }

  public getDepartment(aDepId: number): void {
    //this.getDepartmentHeader(aDepId);
    this.getDepartmentInfos(aDepId);
    this.getDepartmentRole(aDepId, "TIME");
    this.getDepartmentRole(aDepId, "CTR");
    this.getDepartmentRole(aDepId, "ADMINRH");
    this.getDepartmentMembers(aDepId);
    this.getResponsible();
  }

}
