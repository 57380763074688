export class Period {

    from_date: string;
    period_val: number;
    to_date: string;
    constructor() {
        this.from_date = "";
        this.period_val = -1;
        this.to_date = "";
     }
}