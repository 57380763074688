<div id="cmp-main" *ngIf="dataLoading">
    <div class="loader">
        <img src="./assets/img/loader.gif" alt="loading...">
    </div>
</div>
<div id="cmp-main" class="noselect" *ngIf="!dataLoading">
    <div id="cmp-top">
        <div id="cmp-left">
            <div id="cmp-sheet" class="grh-section cmp-item">
                <h2 class="grh-section-title">
                    <label>
                        <span>{{dictionary.sheets}}</span>
                    </label>
                </h2>
                <div class="grh-section-content" *ngIf="!curCompanyInfos">
                    <span>No data ! </span>
                </div>
                <div class="grh-section-content" *ngIf="curCompanyInfos">
                    <div class="cmp-field">
                        <label>{{dictionary.name}}</label>
                        <input type="text" [disabled]="isRO && !isUserSA" [(ngModel)]="curCompanyInfos.company_name">
                        <label for="chk-quarter">{{dictionary.quarterRound}}</label>
                        <input type="checkbox" [disabled]="isRO && !isUserSA" id="chk-quarter" [(ngModel)]="curCompanyInfos.quarter_round_flag">
                    </div>
                    <div class="cmp-field">
                        <label>{{dictionary.displayName}}</label>
                        <input type="text" [disabled]="isRO && !isUserSA" [(ngModel)]="curCompanyInfos.company_displayname">
                        <label for="chk-archived">{{dictionary.archived}}</label>
                        <input type="checkbox" [disabled]="isRO && !isUserSA" id="chk-archived" [(ngModel)]="curCompanyInfos.archive_flag">
                    </div>
                    <div class="cmp-field">
                        <label>{{dictionary.abbreviation}}</label>
                        <input type="text" [disabled]="isRO && !isUserSA" [(ngModel)]="curCompanyInfos.company_shortname">
                        <label for="chk-hasTMS">{{dictionary.hasTimesheet}}</label>
                        <input type="checkbox" [disabled]="isRO && !isUserSA" id="chk-hasTMS" *ngIf="isUserSA" [(ngModel)]="curCompanyInfos.has_timesheet">
                        <input type="checkbox" *ngIf="!isUserSA" disabled [ngModel]="curCompanyInfos.has_timesheet">
                    </div>
                    <div class="cmp-field">
                        <label>{{dictionary.domain}}</label>
                        <input type="text" [disabled]="isRO && !isUserSA" [(ngModel)]="curCompanyInfos.company_domain">
                        <label for="chk-hasHR" >{{dictionary.hasHRInterface}}</label>
                        <input type="checkbox" [disabled]="isRO && !isUserSA" id="chk-hasHR" *ngIf="isUserSA" [(ngModel)]="curCompanyInfos.has_hr_interface">
                        <input type="checkbox" *ngIf="!isUserSA" disabled [ngModel]="curCompanyInfos.has_hr_interface">
                    </div>
                    <div class="cmp-field">
                        <label>{{dictionary.fullTimeWeekHours}}</label>
                        <input type="number" [disabled]="isRO && !isUserSA" [(ngModel)]="curCompanyInfos.full_time_week_hours">
                        <label>{{dictionary.supplementProfile}}</label>
                        <select [(ngModel)]="curCompanyInfos.supplement_profile_id" [disabled]="isRO && !isUserSA">
                            <option [ngValue]="null">{{dictionary.noProfile}}</option>
                            <option *ngFor="let profile of curSupProfileList" [ngValue]="profile.supplement_profile_id">{{profile.profile_name}}</option>
                        </select>

                        <select *ngIf="!isUserSA" [ngModel]="curCompanyInfos.supplement_profile_id" disabled>
                            <option [ngValue]="null">{{dictionary.noProfile}}</option>
                            <option *ngFor="let profile of curSupProfileList" [ngValue]="profile.supplement_profile_id">{{profile.profile_name}}</option>
                        </select>
                    </div>
                    <div class="cmp-field">
                        <label for="chk-hasPlanning">{{dictionary.hasPlanning}}</label>
                        <input type="checkbox" [disabled]="isRO && !isUserSA" id="chk-hasPlanning" *ngIf="isUserSA" [(ngModel)]="curCompanyInfos.has_planning">
                        <input type="checkbox" *ngIf="!isUserSA" disabled [ngModel]="curCompanyInfos.has_planning">
                    </div>
                </div>
            </div>

            <div id="cmp-sheet-suppl" class="grh-section cmp-item" *ngIf="curCompanyInfos && curCompanyInfos.company_id != -1 && curCompanyInfos.has_timesheet">
                <h2 class="grh-section-title">
                    <label>
                        <span>Supplements</span>
                    </label>
                </h2>
                <div class="grh-section-content" *ngIf="!curCompanyInfos">
                    <span>No data ! </span>
                </div>
                <div class="grh-section-content" *ngIf="curCompanyInfos">
                    <div *ngFor="let supplParam of curCompanySupplementsParams" class="comp-supp-param-line">
                        <label class="comp-supp-param-label">{{supplParam.shortname}} ({{supplParam.supplement_part}}) : </label>
                        <div class="cspl-radios">
                            <input type="radio" name="repartition_mode-{{supplParam.company_supplements_param_id}}" id="sup-{{supplParam.company_supplements_param_id}}-pay" [(ngModel)]="supplParam.repartition_mode" value="PAY" [disabled]="isRO && !isUserSA">
                            <label for="sup-{{supplParam.company_supplements_param_id}}-pay">Pay</label>

                            <input type="radio" name="repartition_mode-{{supplParam.company_supplements_param_id}}" id="sup-{{supplParam.company_supplements_param_id}}-recup" [(ngModel)]="supplParam.repartition_mode" value="RECUP" [disabled]="isRO && !isUserSA">
                            <label for="sup-{{supplParam.company_supplements_param_id}}-recup">Recup.</label>
    
                            <input type="radio" name="repartition_mode-{{supplParam.company_supplements_param_id}}" id="sup-{{supplParam.company_supplements_param_id}}-choice" [(ngModel)]="supplParam.repartition_mode" value="CHOICE" [disabled]="isRO && !isUserSA">
                            <label for="sup-{{supplParam.company_supplements_param_id}}-choice">Choice</label>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div id="cmp-right">

        </div>
    </div>
    <div id="cmp-bottombar">
        <div id="left-btn-grp" class="button-group">
            <button *ngIf="isUserSA && !newCompany" (click)="createNewCompany()">{{dictionary.newCompany}}</button>
            <button *ngIf="isUserSA && newCompany" (click)="cancelAction()">{{dictionary.cancel}}</button>
            <button *ngIf="isUserSA" (click)="saveCompany()">{{dictionary.tmgSave}}</button>
            <button *ngIf="!newCompany" [disabled]="isRO" (click)="newDepartment()">{{dictionary.newDepartment}}</button>
        </div>
    </div>
</div>