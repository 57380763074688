<div class="fullsize-popup-container noselect">
    <div id="pfe-main">
        <div id="pfe-header">
            <span class="pfe-header-title">
                <img>
                <label>{{dictionary.onTimeEdition}}</label>
            </span>
            <button (click)="closeWindow()">X</button>
        </div>
        <div id="pfe-body">
            <div id="pfe-label">
                <label>{{dictionary.enterProfileName}} </label>
            </div>
            <div id="pfe-input">
                <input type="text" [(ngModel)]="curName" #inputName>
            </div>
            <div id="pfe-button">
                <button (click)="addProfile()">{{dictionary.save}}</button>
            </div>
        </div>
    </div>
</div>