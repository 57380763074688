import { Component, OnInit } from '@angular/core';
import { Profile, ToolDict, WindowProfile } from '@app/models';
import { DictService, TmsApiService } from '@app/services';

@Component({
  selector: 'app-tool-selector',
  templateUrl: './tool-selector.component.html',
  styleUrls: ['./tool-selector.component.scss']
})
export class ToolSelectorComponent implements OnInit {

  constructor(private dictService: DictService, private tmsApiService: TmsApiService) { }

  countWaitingResp: number;
  isCheckingModule: boolean;
  curWindows: number[];

  ngOnInit(): void {
    this.countWaitingResp = 0;
    this.isCheckingModule = false;
    this.curWindows = [];
    this.getAuthorizedWindows();
  }

  get dictionary(): ToolDict {
    return this.dictService.getToolDict();
  }

  public get dataLoading(): boolean {
    return this.isCheckingModule;
  }

  hasUserAccess(windowId: number) {
    return this.curWindows.includes(windowId);
  }

  checkisOver() {
    if (this.countWaitingResp > 0) {
      this.countWaitingResp--;
    }
    if (this.countWaitingResp == 0) {
      this.isCheckingModule = false;
    }
  }

  getAuthorizedWindows() {
    this.isCheckingModule = true;
    this.tmsApiService.getUserProfile().subscribe((response) => {
      if (response && response.length > 0) {
        let theProfiles: Profile[] = response;
        this.countWaitingResp = theProfiles.length;
        for (const aProfile of theProfiles) {
          this.tmsApiService.getUserProfileWindows(aProfile.profile_id).subscribe((responsePrf) => {
            if (responsePrf && responsePrf.length) {
              let theWindows: WindowProfile[] = responsePrf;
              for (const window of theWindows) {
                if (this.curWindows.indexOf(window.window_id) == -1) {
                  this.curWindows.push(window.window_id);
                }
              }
              this.checkisOver();
            }
          });
        }
      }
    });
  }
}
