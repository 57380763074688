type ToastStatus = 'new' | 'show' | 'hide';
type ToastType = 'message' | 'alert' | 'sucess'  | 'information';

export class Toast {
    id: number;
    status: ToastStatus;
    type: ToastType;
    text: string;

    constructor(aText: string, aType?: ToastType) {
        this.text = aText;
        this.status = 'new';
        this.type = aType ? aType : 'message';
     }
}
