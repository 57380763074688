export class ToolDict {

    selectTool: string;
    signaletics: string;
    report: string;
    profilesManagement : string;
    delegations : string;
    logs : string;
    publicHolidays : string;

    constructor() {
        this.selectTool = "";
        this.signaletics = "";
        this.report = "";
        this.profilesManagement = "";
        this.delegations = "";
        this.logs = "";
        this.publicHolidays = "";
    }

}