import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import {  People, PeopleDict } from '@app/models';
import { DictService, TmsApiService } from '@app/services';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit {

  @ViewChild('inputSearch') inputSearch: ElementRef;

  @Input() archiveFlag: boolean;
  @Input() managedPeopleFlag : boolean;
  @Output() close = new EventEmitter();
  @Output() add = new EventEmitter<People>();

  curPeople: People[];
  selectedPeople: People;
  searchText: string;
  peopleLoading : boolean;
  constructor(private tmsApiService: TmsApiService, private dictService : DictService) { }

  ngOnInit(): void {
    this.curPeople = [];
    this.searchText = "";
    this.peopleLoading = false;
    this.getEmployees(this.archiveFlag);
  }

  ngAfterViewInit() {
    // server-side search
    this.inputSearch.nativeElement.focus();
    fromEvent(this.inputSearch.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(250),
        distinctUntilChanged(),
        tap((event) => {
          this.searchText = this.inputSearch.nativeElement.value;
          this.changeSearch()
        })
      )
      .subscribe();
  }

  get searchMod(): boolean {
    return this.searchText.trim().length > 0
  }

  get dictionary(): PeopleDict {
    return this.dictService.getPeopleDict();
  }

  changeSearch() {
    if (this.searchMod) {
      this.filterNameList();
    }
  }

  filterNameList() {
    if (this.searchMod) {
      for (const people of this.curPeople) {
        people.isFiltered = people.person_name.toUpperCase().includes(this.searchText.toUpperCase());
      }
    }
  }

  getPeopleFiltered(): People[] {
    if (this.searchMod) {
      return this.curPeople.filter((p) => {
        return p.isFiltered && (this.archiveFlag || (!this.archiveFlag && !p.archive_flag))
      })
    } else {
      return this.curPeople.filter((p) => {
        return this.archiveFlag || (!this.archiveFlag && !p.archive_flag )
      })
    }
  }

  selectPeople(aPeople: People) {
    this.selectedPeople = aPeople;
  }

  getEmployees(aFlag : boolean) {
    this.peopleLoading = true;
    if (this.managedPeopleFlag) {
      this.tmsApiService.getManagedEmployees(aFlag).subscribe((response) => {
        this.peopleLoading = false;
        if (response.length) {
          this.curPeople = response.map((e: People) => {
            e.isFiltered = false;
            return e;
          });

          this.curPeople = this.curPeople.sort((p1: People, p2: People) => {
            if ( p1.person_name < p2.person_name) return -1;
            if ( p1.person_name > p2.person_name) return 1;
            return 0;
          });

        }
      });
    }
    else
    {
      this.tmsApiService.getEmployees(aFlag).subscribe((response) => {
        this.peopleLoading = false;
        if (response.length) {
          this.curPeople = response.map((e: People) => {
            e.isFiltered = false;
            return e;
          });

          this.curPeople = this.curPeople.sort((p1: People, p2: People) => {
            if ( p1.person_name < p2.person_name) return -1;
            if ( p1.person_name > p2.person_name) return 1;
            return 0;
          });

        }
      });


    }

    
    
  }

  closeWindow() {
    this.close.emit();
  }

  select(aPeople: People) {
    this.add.emit(aPeople);
    this.closeWindow();
  }

}
