<div id="sig-main" class="grh-fullcontainer noselect">
    <div id="grh-main">
        <div id="grh-searchtree">
            <div id="grh-searchbar">
                <div class="grh-field" >
                    <label for="chk-inact" >{{dictionary.inactivePeopleEntities}}</label>
                    <input type="checkbox" id="chk-inact" [(ngModel)]="showInactive" (change)="searchMod && changeSearch()">
                </div>
                <div class="grh-field">
                    <input type="search" placeholder={{dictionary.labelSearch}} #inputSearch> 
                </div>
            </div>
            <div id="grh-treeview" >
                <div class="grh-treelvl noselect" *ngFor="let com of treeviewRoot.nodes"
                    [class.hidden]="searchMod && !com.isFiltered">
                    <div class="grh-treeitem" [class.selected]="com.id == selectedNode?.id"
                        (click)="selectNode(com); $event.stopPropagation();">
                        <label>
                            <span *ngIf="!com.isOpen" class="caret"><i class="fa fa-caret-right"></i></span>
                            <span *ngIf="com.isOpen" class="caret"><i class="fa fa-caret-down"></i></span>
                            <span class="nodetext">
                                <span class="nodename" [innerHTML]="com.getNodeTitle(searchText) | safeHtml"></span>
                                <span class="counter">({{getCountPeopleCompany(com)}})</span>
                            </span>
                            <!--<span>{{com.nodeTitle}}</span>-->
                        </label>
                    </div>
                    <div class="grh-treecont" *ngIf="com.isOpen">
                        <div class="grh-treelvl" *ngFor="let dep of getActive(com)"
                            [class.hidden]="searchMod && !dep.isFiltered">
                            <div class="grh-treeitem" [class.selected]="dep.id == selectedNode?.id"
                                [class.inactive]="isInactive(dep)" (click)="selectNode(dep); $event.stopPropagation();">
                                <label>
                                    <span *ngIf="!dep.isOpen" class="caret"><i class="fa fa-caret-right"></i></span>
                                    <span *ngIf="dep.isOpen" class="caret"><i class="fa fa-caret-down"></i></span>
                                    <span class="nodetext">
                                        <span class="nodename" [innerHTML]="dep.getNodeTitle(searchText) | safeHtml"></span>
                                        <span class="counter">({{getCountPeopleDepartment(dep)}})</span>
                                    </span>
                                </label>
                            </div>
                            <div class="grh-treecont" *ngIf="dep.isOpen">
                                <div class="grh-treelvl" *ngFor="let ser of getActive(dep)"
                                    [class.hidden]="searchMod && !ser.isFiltered ">
                                    <div class="grh-treeitem" [class.selected]="ser.id == selectedNode?.id"
                                        [class.inactive]="isInactive(ser)"
                                        (click)="selectNode(ser); $event.stopPropagation();">
                                        <label>
                                            <span *ngIf="!ser.isOpen" class="caret"><i class="fa fa-caret-right"></i></span>
                                            <span *ngIf="ser.isOpen" class="caret"><i class="fa fa-caret-down"></i></span>
                                            <span class="nodetext">
                                                <span class="nodename" [innerHTML]="ser.getNodeTitle(searchText) | safeHtml"></span>
                                                <span class="counter">({{getCountPeopleService(ser)}})</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div class="grh-treecont" *ngIf="ser.isOpen">
                                        <div class="grh-treelvl" *ngFor="let emp of getActive(ser)"
                                            [class.hidden]="searchMod && !emp.isFiltered">
                                            <div class="grh-treeitem" [class.selected]="emp.id == selectedNode?.id"
                                                [class.inactive]="isInactive(emp)"
                                                (click)="selectNode(emp); $event.stopPropagation();">
                                                <label>
                                                    <span [innerHTML]="emp.getNodeTitle(searchText) | safeHtml"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div id="grh-datapanel">
            <app-company *ngIf="selectedNode?.currentType == 'company'" [companyId]="selectedNode?.objectId" [newCompany]="newCompany" [hasTS]="selectedNode?.hasTS" [hasHR]="selectedNode?.hasHR" [isRO]="isReadOnly" (createDepartment)="createDepartment()" (createCompany)="createCompany()" (refreshTree)="refreshTree($event)" (cancel)="cancelCompany()"></app-company> 
            <app-departments *ngIf="selectedNode?.currentType == 'department'" [departmentId]="selectedNode?.objectId" [hasTS]="selectedNode?.hasTS" (createService)="createService()" [newDepartment]="newDepartment" [isRO]="isReadOnly" (refreshTree)="refreshTree($event)" (cancel)="cancelDepartment()"></app-departments>
            <app-services *ngIf="selectedNode?.currentType == 'service'" [serviceId]="selectedNode?.objectId" [newService]="newService" [hasTS]="selectedNode?.hasTS" [isRO]="isReadOnly" (createEmployee)="createEmployee()" (updateEmployee)="modifyEmployee($event)"
            (cancel)="cancelService()" (refreshTree)="refreshTree($event)"></app-services>
            <app-employees *ngIf="selectedNode?.currentType == 'employee'" [employeeId]="selectedNode?.objectId" [serviceId]="serviceId" [extCode]="selectedNode?.externalCode" [hasTS]="selectedNode?.hasTS" [hasHR]="selectedNode?.hasHR" [hasPark]="selectedNode?.hasPark"
            [newEmployee]="newEmployee" [newContract]="newContract" [isRO]="isReadOnly" (create)="createEmployee()" (update)="modifyEmployee($event)" (cancel)="cancelEmployee()" (refreshTree)="refreshTree($event)"></app-employees> 
        </div>
    </div>
</div>