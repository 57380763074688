import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ToolSelectorComponent } from './components/admin/tool-selector/tool-selector.component';
import { ReportsComponent } from './components/admin/tools/reports/reports.component';
import { SignaleticsComponent } from './components/admin/tools/signaletics/signaletics.component';
import { AuthGuard } from './helpers';
import { AdminComponent } from './components/admin/admin.component';
import { RightsGuard } from './helpers/rights.guard';
import { ProfilesComponent } from './components/admin/tools/profiles/profiles.component';
import { DelegationsComponent } from './components/admin/tools/delegations/delegations.component';
import { LogsComponent } from './components/admin/tools/logs/logs.component';
import { PublicHolidaysComponent } from './components/admin/tools/publicHolidays/publicHolidays.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    // canActivate: [AuthGuard],
    // data: { mustBeAuth: false }
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    canActivateChild: [RightsGuard],
    // data: { mustBeAuth: false }
    children: [
      {
        path: '',
        redirectTo: 'tools',
        //redirectTo: 'signaletics',
        pathMatch: 'full'
      },
      {
        path: 'tools',
        component: ToolSelectorComponent,
        canActivate: [AuthGuard],
        // data: { mustBeAuth: true }
      },
      {
        path: 'signaletics',
        component: SignaleticsComponent,
        canActivate: [AuthGuard],
        // data: { mustBeAuth: true }
      },
      {
        path: 'profiles',
        component: ProfilesComponent,
        canActivate: [AuthGuard],
        // data: { mustBeAuth: true }
      },
      {
        path: 'delegations',
        component: DelegationsComponent,
        canActivate: [AuthGuard],
        // data: { mustBeAuth: true }
      },
      {
        path: 'logs',
        component: LogsComponent,
        canActivate: [AuthGuard],
        // data: { mustBeAuth: true }
      },
      {
        path: 'publicHolidays',
        component: PublicHolidaysComponent,
        canActivate: [AuthGuard],
        // data: { mustBeAuth: true }
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [AuthGuard],
        // data: { mustBeAuth: true }
      }
    ]
  }, {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
