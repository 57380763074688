export class Person {
    person_id: number;
    person_name: string;
    is_deleted_flag: boolean;
    isFiltered: boolean;

    constructor() {
        this.person_id = -1;
        this.person_name = "";
        this.is_deleted_flag = false;
        this.isFiltered = false;
    }
}

export class PersonParamProfile {
    person_profile_id: number;
    person_id: number;
    profile_id: number;
    is_deleted_flag: boolean;
    create_date: string;
    create_by: number;
    mod_date: string;
    mod_by: number;
    profile_name: string;

    constructor() { }

}

export class ParamProfile {
    profile_id: number;
    profile_name: string;
    person_flag: boolean;
    is_deleted_flag: boolean

    constructor() { }
}