import { EventEmitter, Injectable } from '@angular/core';
import { Toast } from '@app/models/toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  notificationList: Toast[];
  private notificationDuration = 5000;

  toastsChange: EventEmitter<Toast[]> = new EventEmitter();

  constructor() {
    this.notificationList = [];
  }

  private addToast(aToast: Toast, aDuration?: number) {
    const notifDuration = aDuration ? aDuration : this.notificationDuration;
    this.notificationList.unshift(aToast);
    setTimeout(() => {
      aToast.status = 'show';
      setTimeout(() => {
        aToast.status = 'hide';
        setTimeout(() => {
          this.notificationList.pop();
        }, 500);
      }, notifDuration);
    }, 10);
  }

  pushMessage(aText: string, aDuration?: number) {
    const newNotification = new Toast(aText, 'message');
    this.addToast(newNotification, aDuration);
  }

  pushInfo(aText: string, aDuration?: number) {
    const newNotification = new Toast(aText, 'information');
    this.addToast(newNotification, aDuration);
  }

  pushSucess(aText: string, aDuration?: number) {
    const newNotification = new Toast(aText, 'sucess');
    this.addToast(newNotification, aDuration);
  }

  pushAlert(aText: string, aDuration?: number) {
    const newNotification = new Toast(aText, 'alert');
    this.addToast(newNotification, aDuration);
  }
}
