export class Item {
    activeFlag : boolean;
    hasTimesheet : boolean;
    hasHRInterface : boolean;
    hasParking : boolean;

    constructor(aFlag : boolean, aHasTS : boolean, aHasHR : boolean, aHasPark : boolean)
    {
        this.activeFlag = aFlag;
        this.hasTimesheet = aHasTS;
        this.hasHRInterface = aHasHR;
        this.hasParking = aHasPark;
    }
}