<div id="prf-cont" class="grh-fullcontainer noselect">
    <div id="prf-main">
        <div id="prf-top">
            <div id="prf-tab">
                <div id="prf-win" class="prf-tab-menu" (click)="selectTab('winProfile')" [class.selected]="curTab == 'winProfile'">
                    <label>
                        <span>{{dictionary.profilesWindows}}</span>
                    </label>
                </div>
                <div id="prf-users" class="prf-tab-menu" (click)="selectTab('usersProfile')" [class.selected]="curTab == 'usersProfile'">
                    <label>
                        <span>{{dictionary.profilesUsers}}</span>
                    </label>
                </div>
            </div>
        </div>
        <div id="prf-panel">
            <div id="prf-pnl-top">
                <div id="prf-left">
                    <div class="prf-head-title">
                        <div class="prf-head-left">
                            <h3 class="title-label" *ngIf="curTab == 'winProfile'">{{dictionary.userProfiles}}</h3>
                            <span *ngIf="curTab == 'winProfile'" id="prf-new-prof" (click)="displayNew()"> (+) {{dictionary.new}} </span>
                            <app-profile-editor *ngIf="displayProfileEditor" (close)="closeEditor()" (add)="addNewProfile($event)"  ></app-profile-editor> 
                            <h3 class="title-label" *ngIf="curTab == 'usersProfile'">{{dictionary.onTimeUsers}}</h3>
                        </div>
                        <div class="prf-head-right">
                            <input type="search" placeholder="{{dictionary.labelSearch}}" #inputSearch>
                        </div>
                    </div>
                    <div *ngIf="curTab == 'winProfile'" class="prf-list">
                        <ul>
                            <li *ngFor="let profile of getFilteredProfileList()" [class.selected]="profile.profile_id == curProfile.profile_id" [class.deleted]="profile.is_deleted_flag" (click)="selectProfile(profile)">
                                {{profile.profile_name}}</li>
                        </ul>
                    </div>
                    <div *ngIf="curTab == 'winProfile'" class="prf-count">{{getFilteredProfileList().length}} {{dictionary.profiles}}</div>
                    <div *ngIf="curTab == 'usersProfile'" class="prf-list">
                        <ul>
                            <li *ngFor="let person of getFilteredPersonList()" [class.selected]="person.person_id == curPerson.person_id" [class.deleted]="person.is_deleted_flag" (click)="selectPerson(person)">
                                {{person.person_name}}</li>
                        </ul>
                    </div>
                    <div *ngIf="curTab == 'usersProfile'" class="prf-count">{{getFilteredPersonList().length}} {{dictionary.users}}</div>
                </div>
                <div id="prf-right">
                    <div id="prf-select">
                        <div class="prf-head-title">
                            <div class="prf-head-left">
                                <h3 *ngIf="curTab == 'winProfile'" class="title-label">{{dictionary.linkedWindows}}</h3>
                                <h3 *ngIf="curTab == 'usersProfile'" class="title-label">{{dictionary.linkedProfiles}}</h3>
                            </div>
                        </div>
                        <div *ngIf="curTab == 'winProfile'" class="prf-table">
                            <table>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let profileWin of curProfileWinList"  >
                                        <td (dblclick)="removeWindowProfile(profileWin)"><label>{{profileWin.window_title}}</label> </td>
                                        <td><select [(ngModel)]="profileWin.read_mode_id" (ngModelChange)="updateReadMode(profileWin)">
                                                <option value="1">{{dictionary.total}}</option>
                                                <option value="2">{{dictionary.readonly}}</option>
                                            </select></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="curTab == 'winProfile'" class="prf-count">{{curProfileWinList.length}} {{dictionary.windows}}</div>
                        <div *ngIf="curTab == 'usersProfile'" class="prf-table">
                            <table>
                                <thead>
                                    
                                </thead>
                                <tbody>
                                    <tr *ngFor="let personParam of curPersonParamProfList" >
                                        <td (dblclick)="removePersonParam(personParam)"><label>{{personParam.profile_name}}</label></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="curTab == 'usersProfile'" class="prf-count">{{curPersonParamProfList.length}} {{dictionary.profile_s}}</div>
                    </div>
                    <div id="prf-avail">
                        <div class="prf-head-title">
                            <div class="prf-head-left">
                                <h3 *ngIf="curTab == 'winProfile'" class="title-label">{{dictionary.onTimeWindows}}</h3>
                                <h3 *ngIf="curTab == 'usersProfile'" class="title-label">{{dictionary.profile}}</h3>
                            </div>
                        </div>
                        <div *ngIf="curTab == 'winProfile'" class="prf-table">
                            <table>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let windowParam of getNonSelectedWindow()" (dblclick)="addWindowProfile(windowParam)">
                                        <td><label>{{windowParam.window_title}}</label></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="curTab == 'winProfile'" class="prf-count">{{getNonSelectedWindow().length}} {{dictionary.windows}}</div>
                        <div *ngIf="curTab == 'usersProfile'" class="prf-table">
                            <table>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let paramProfile of getNonSelectedParamProfile()" (dblclick)="addPersonParam(paramProfile)"> 
                                        <td><label>{{paramProfile.profile_name}}</label></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="curTab == 'usersProfile'" class="prf-count">{{getNonSelectedParamProfile().length}} {{dictionary.profile_s}}</div>
                    </div>

                </div>
            </div>
            <div *ngIf="curTab == 'winProfile'" class="prf-pnl-bottom">
                <label for="chk-del">{{dictionary.showDeletedProfiles}}</label>
                <input type="checkbox" id="chk-del" [(ngModel)]="deletedProfile" (change)="getProfileList(deletedProfile)">
            </div>
            <div *ngIf="curTab == 'usersProfile'" class="prf-pnl-bottom">
                <label for="chk-inact">{{dictionary.showInactiveUsers}}</label>
                <input type="checkbox" id="chk-inact" [(ngModel)]="showInactiveUsers" (change)="getPersonList(showInactiveUsers)">
            </div>
        </div>
    </div>
</div>