import { Component, OnInit } from '@angular/core';
import { Toast } from './models';
import { ToastService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private toastService: ToastService
  ) { }

  ngOnInit(): void { }

  get toasts(): Toast[] {
    return this.toastService.notificationList;
  }

}