import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConnectionMode, Contract, ContractMove, Delegate, Delegation, DepartmentHeader, DepartmentMember, DepartmentRole, EmployeeAutoComp, EmployeeGrid, EmployeeInfos, EmployeeSupplementParam, Holder, ParamProfile, ParkPool, People, Period, Person, PersonParamProfile, Profile, Responsible, ServiceGrid, ServiceHeader, ServiceMember, ServiceTM, SupplementParam, Task, TaskDetail, TmsUser, WindowParam, WindowProfile } from '@app/models';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { PublicHoliday, PublicHolidayType } from '@app/models/publicHolidays';

@Injectable({
  providedIn: 'root'
})
export class TmsApiService {
  private tmsApiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  private get httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
  }
  // TREEVIEW
  public getGrhOrgData(): Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + '/grh_org_data', this.httpOptions);
  }

  // VARIOUS
  public getResp(): Observable<Responsible[]> {
    return this.http.get<Responsible[]>(this.tmsApiUrl + "/responsible", this.httpOptions);
  }

  public getCurYearPeriod(): Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/curYearPeriod", this.httpOptions);
  }

  //COMPANY 
  public getCmpInfos(aCmpId: number): Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/company_infos?company_id=" + aCmpId, this.httpOptions);
  }

  public getCmpUnicity(aCmpId: number, aCmpName: string): Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/cmp_unicity?company_id=" + aCmpId + "&company_name=" + aCmpName, this.httpOptions);
  }

  // DEPARTMENT
  public getDptHeader(aDepId: number): Observable<DepartmentHeader[]> {
    return this.http.get<DepartmentHeader[]>(this.tmsApiUrl + "/department_header?department_id=" + aDepId, this.httpOptions);
  }

  public getDptInfos(aDepId: number): Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/department_infos?department_id=" + aDepId, this.httpOptions);
  }

  public postSaveCom(aCom: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/save_comp", aCom, this.httpOptions);
  }

  public postSaveDept(aDept: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/save_dept", aDept, this.httpOptions);
  } 

  public getDptRole(aDepId: number, aRole: string): Observable<DepartmentRole[]> {
    return this.http.get<DepartmentRole[]>(this.tmsApiUrl + "/department_roles?department_id=" + aDepId + "&role_val=" + aRole, this.httpOptions);
  }

  public postAddDptGDT(aDepGDT: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/add_dpt_gdt", aDepGDT, this.httpOptions);
  }

  public postDeleteDptGDT(aDepGDT: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/delete_dpt_gdt", aDepGDT, this.httpOptions);
  }

  public postAddDptCtrl(aDepCtrl: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/add_dpt_ctrl", aDepCtrl, this.httpOptions);
  }

  public postDeleteDptCtrl(aDepCtrl: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/delete_dpt_ctrl", aDepCtrl, this.httpOptions);
  }

  public postAddDptAdm(aDepAdm: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/add_dpt_adm", aDepAdm, this.httpOptions);
  }

  public postDeleteDptAdm(aDepAdm: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/delete_dpt_adm", aDepAdm, this.httpOptions);
  }

  public getDptMembers(aDepId: number): Observable<DepartmentMember[]> {
    return this.http.get<DepartmentMember[]>(this.tmsApiUrl + "/department_members?department_id=" + aDepId, this.httpOptions);
  }

  public getDeptUnicity(aDepId: number, aDepName: string, aCompId: number): Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/dpt_unicity?department_id=" + aDepId + "&department_name=" + aDepName + "&company_id=" + aCompId, this.httpOptions);
  }

  public postDeleteDepartment(aDepId: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/delete_dept", aDepId, this.httpOptions);
  }

  // SERVICE
  public getSerHeader(aSerId: number): Observable<ServiceHeader[]> {
    return this.http.get<ServiceHeader[]>(this.tmsApiUrl + "/service_header?service_id=" + aSerId, this.httpOptions);
  }

  public getSerInfos(aSerId: number): Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/service_infos?service_id=" + aSerId, this.httpOptions);
  }

  public getSerMembers(aSerId: number): Observable<ServiceMember[]> {
    return this.http.get<ServiceMember[]>(this.tmsApiUrl + "/srv_members?service_id=" + aSerId, this.httpOptions);
  }

  public getSerTasks(aSerId: number): Observable<Task[]> {
    return this.http.get<Task[]>(this.tmsApiUrl + "/tasksServiceGrid?service_id=" + aSerId, this.httpOptions);
  }

  public getSerGDTs(aSerId: number): Observable<ServiceTM[]> {
    return this.http.get<ServiceTM[]>(this.tmsApiUrl + "/service_gdt?service_id=" + aSerId, this.httpOptions);
  }

  public getSerGrid(aSerId: number): Observable<ServiceGrid[]> {
    return this.http.get<ServiceGrid[]>(this.tmsApiUrl + "/service_grid?service_id=" + aSerId, this.httpOptions);
  }

  public getServUnicity(aDepId: number, aSerName: string, aSerId: number): Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/srv_unicity?department_id=" + aDepId + "&service_name=" + aSerName + "&service_id=" + aSerId, this.httpOptions);
  }

  public postSaveService(aService: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/save_service", aService, this.httpOptions);
  }

  public postDeleteService(aServId: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/delete_service", aServId, this.httpOptions);
  }

  public postAddSrvGDT(aServGDT: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/add_srv_gdt", aServGDT, this.httpOptions);
  }

  public postDeleteSrvGDT(aServGDT: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/delete_srv_gdt", aServGDT, this.httpOptions);
  }
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//PUBLIC HOLIDAY START
  public getloadListOfPublicHolidayType(): Observable<PublicHolidayType>{
    return this.http.get<PublicHolidayType>(this.tmsApiUrl + "/public_holiday_type", this.httpOptions);
  }

  public updatePublicHolidayType(data:PublicHolidayType): Observable<any>{    
    return this.http.put<any>(this.tmsApiUrl + "/public_holiday_type",data, this.httpOptions);
  }

  public getloadListOfPublicHolidays(aCurrentYear: number): Observable<any>{
    return this.http.get<any>(this.tmsApiUrl + "/public_holiday?year="+aCurrentYear, this.httpOptions);
  }  

  public getCompanyList(): Observable<any>{
    return this.http.get<any>(this.tmsApiUrl + "/company_list?display_inact_flag=false", this.httpOptions);
  }

  public deletePublicHolidayType(aID: number): Observable<any> {
    return this.http.delete<any>(this.tmsApiUrl + "/public_holiday_type?id=" + aID, this.httpOptions);    
  }

  public insertPublicHolidayType(data:PublicHolidayType): Observable<any>{    
    return this.http.post<any>(this.tmsApiUrl + "/public_holiday_type",data, this.httpOptions);
  }

  public insertOrUpdateHolidayDet(data:any): Observable<any> {
    return this.http.put<any>(this.tmsApiUrl + "/public_holiday_det",data,this.httpOptions);
  }

//PUBLIC HOLIDAY END
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // SUPPLEMENT
  public getSupParams(aSerList: any, aSerId: number): Observable<SupplementParam> {
    return this.http.get<SupplementParam>(this.tmsApiUrl + "/supplementsParameters?serviceList=" + aSerList + "&service_id=" + aSerId, this.httpOptions);
  }

  public postServiceSupplementsParameters(aSupp: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/serviceSupplementsParameters", aSupp, this.httpOptions);
  }

  public getSupPeriods(aYear: number): Observable<Period[]> {
    return this.http.get<Period[]>(this.tmsApiUrl + "/sup_periods?year_val=" + aYear, this.httpOptions);
  }

  public getSupYears(): Observable<any[]> {
    return this.http.get<any[]>(this.tmsApiUrl + "/sup_years", this.httpOptions);
  }

  public getSupProfileList(): Observable<any[]> {
    return this.http.get<any[]>(this.tmsApiUrl + "/sup_profile_list", this.httpOptions);
  }

  //PERSON-EMPLOYEE
  public getEmployeesMatricule(): Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/employeesMatricule", this.httpOptions);
  }

  public getEmployees(aFlag: boolean): Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/employees?archive_flag=" + aFlag, this.httpOptions);
  }

  public getManagedEmployees (aFlag: boolean): Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/managed_employees?archive_flag=" + aFlag, this.httpOptions);
  }

  public getAllEmployees(): Observable<People[]> {
    return this.http.get<People[]>(this.tmsApiUrl + "/all_employees", this.httpOptions);
  }

  public getPersonInfos(aPersId: number): Observable<EmployeeInfos> {
    return this.http.get<EmployeeInfos>(this.tmsApiUrl + "/person_infos?person_id=" + aPersId, this.httpOptions);
  }

  public getAutocompletePersonInfos(anExtCode: string): Observable<EmployeeAutoComp> {
    return this.http.get<EmployeeAutoComp>(this.tmsApiUrl + "/autocomplete_person_infos?external_code=" + anExtCode, this.httpOptions);
  }

  public getPersonUnicity(aPersId: number, aMatr: string, aLogin: string, aMail: string): Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/person_unicity?person_id=" + aPersId + "&matricule_nr=" + aMatr + "&login=" + aLogin + "&email_address=" + aMail, this.httpOptions);
  }

  public postPersonInfos(anEmployee: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/save_person_infos", anEmployee, this.httpOptions);
  }

  public deletePerson(aPersId: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/delete_person", aPersId, this.httpOptions);
  }

  public getPersonSuppParams(aPersId: number, aServiceId: number): Observable<EmployeeSupplementParam[]> {
    return this.http.get<EmployeeSupplementParam[]>(this.tmsApiUrl + "/person_supplement_params?person_id=" + aPersId + '&service_id=' + aServiceId, this.httpOptions);
  }

  public getPplContracts(aPersId: number, aSerId: number): Observable<Contract[]> {
    return this.http.get<Contract[]>(this.tmsApiUrl + "/ppl_contracts?person_id=" + aPersId + "&service_id=" + aSerId, this.httpOptions);
  }

  public getMoveContractDestList(aPersonContractId: number): Observable<ContractMove[]> {
    return this.http.get<Contract[]>(this.tmsApiUrl + "/move_contract_dests?person_contract_id=" + aPersonContractId, this.httpOptions);
  }

  public moveContractToService(aContract: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/move_contract_to", aContract, this.httpOptions);
  }

  public postSavePersonContract(aContract: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/save_person_contract", aContract, this.httpOptions);
  }

  public postSavePersonSuppParams(aPersonId: number, aServiceId: number, aPersonSuppParams: EmployeeSupplementParam[]): Observable<EmployeeSupplementParam[]> {
    return this.http.post<any>(this.tmsApiUrl + "/save_person_supplement_params", {personId: aPersonId, serviceId: aServiceId, personSuppParams: aPersonSuppParams}, this.httpOptions);
  }

  public getSigHistory(anExtUserCode: string): Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/signaleticsHistory?external_user_code=" + anExtUserCode, this.httpOptions);
  }

  public getTaskPerson(aPersId: number, aSerId: number): Observable<Task[]> {
    return this.http.get<Task[]>(this.tmsApiUrl + "/tasksPersonGrid?person_id=" + aPersId + "&service_id=" + aSerId, this.httpOptions);
  }

  public getPersonGrid(aPersId: number): Observable<EmployeeGrid[]> {
    return this.http.get<EmployeeGrid[]>(this.tmsApiUrl + "/personGrid?person_id=" + aPersId, this.httpOptions);
  }

  public getDetailTask(aPersId: number, aSerId: number): Observable<TaskDetail[]> {
    return this.http.get<TaskDetail[]>(this.tmsApiUrl + "/detail_tasks?person_id=" + aPersId + "&service_id=" + aSerId, this.httpOptions);
  }

  public getParkPool(aPersId: number, aSerId: number): Observable<ParkPool[]> {
    return this.http.get<ParkPool[]>(this.tmsApiUrl + "/get_park_pool?person_id=" + aPersId + "&service_id=" + aSerId, this.httpOptions);
  }

  //TECHNICAL
  public getConModeList(): Observable<ConnectionMode[]> {
    return this.http.get<ConnectionMode[]>(this.tmsApiUrl + "/connection_modes", this.httpOptions);
  }

  // USER
  public getUser(): Observable<TmsUser> {
    return this.http.get<TmsUser>(this.tmsApiUrl + "/user", this.httpOptions);
  }

  public canAccessWindow(aWindowId: number): Observable<boolean> {
    return this.http.get<boolean>(this.tmsApiUrl + "/has_access_window?window_id=" + aWindowId, this.httpOptions);
  }

   public getUserProfile(): Observable<Profile[]> {
     return this.http.get<Profile[]>(this.tmsApiUrl + "/user_profile", this.httpOptions);
   }
 
   public getUserProfileWindows(aProfileId: number): Observable<WindowProfile[]> {
     return this.http.get<WindowProfile[]>(this.tmsApiUrl + "/prf_profile_windows?profile_id=" + aProfileId, this.httpOptions);
   }

  //PROFILE-WINDOW

  public getProfileList(aFlag: boolean): Observable<Profile[]> {
    return this.http.get<Profile[]>(this.tmsApiUrl + "/profile_list?is_deleted_flag=" + aFlag, this.httpOptions);
  }

  public getProfileWindowsList(aProfId: number): Observable<WindowProfile[]> {
    return this.http.get<WindowProfile[]>(this.tmsApiUrl + "/prf_profile_windows?profile_id=" + aProfId, this.httpOptions);
  }

  public getWindowsList(aProfId: number): Observable<WindowParam[]> {
    return this.http.get<WindowParam[]>(this.tmsApiUrl + "/prf_windows?profile_id=" + aProfId, this.httpOptions);
  }

  public postAddProfile(data: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/add_profile", data, this.httpOptions);
  }

  public postAddWindowPrf(data: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/add_window_prf", data, this.httpOptions);
  }

  public postRemoveWindowPrf(data: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/remove_window_prf", data, this.httpOptions);
  }

  public postUpdateReadMode(data: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + '/update_read_mode', data, this.httpOptions);
  }

  //PROFILE-PERSON

  public getPrfPplList(aFlag: boolean): Observable<Person[]> {
    return this.http.get<Person[]>(this.tmsApiUrl + "/prf_ppl_list?display_deleted_flag=" + aFlag, this.httpOptions);
  }

  public getPersAssocProfiles(aPersId: number): Observable<PersonParamProfile[]> {
    return this.http.get<PersonParamProfile[]>(this.tmsApiUrl + "/person_assoc_profiles?person_id=" + aPersId, this.httpOptions);
  }

  public getPersProfiles(aPersId: number): Observable<ParamProfile[]> {
    return this.http.get<ParamProfile[]>(this.tmsApiUrl + "/person_profiles?person_id=" + aPersId, this.httpOptions);
  }

  public postAddPersPrf(data: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/add_pers_prf", data, this.httpOptions);
  }

  public postRemovePersPrf(data: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/remove_pers_prf", data, this.httpOptions);
  }

  //DELEGATION

  public getDelegates(aPersId: number, aDelType: string): Observable<Delegate[]> {
    return this.http.get<Delegate[]>(this.tmsApiUrl + "/delegates?person_id=" + aPersId + "&delegation_type=" + aDelType, this.httpOptions);
  }

  public getRights(aPersId: number, aDelType: string): Observable<Holder[]> {
    return this.http.get<Holder[]>(this.tmsApiUrl + "/rights?person_id=" + aPersId + "&delegation_type=" + aDelType, this.httpOptions);
  }

  public postDeleteDelegate(data: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/suppr_deleg", data, this.httpOptions);
  }

  public postAddDelegation(data: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/add_delegation", data, this.httpOptions);
  }

  public getDelegations(aDelType : string, aFlag : boolean): Observable<Delegation[]> {
    return this.http.get<Delegation[]>(this.tmsApiUrl + "/delegations?delegation_type=" + aDelType + "&display_inactive_flag=" + aFlag, this.httpOptions);
  }

  //DICO
  public getDico(): Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/dico", this.httpOptions);
  }

  public postChangeLanguage(data: any): Observable<any> {
    return this.http.post<any>(this.tmsApiUrl + "/changeLanguage", data, this.httpOptions);
  }

  //LOGS
  public getPeriodStatus(aPersonId : number,fromDate: string, toDate: string,aYear: number,aPeriod : number) : Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/get_logs_period_status?person_id=" + aPersonId+"&from_date=" + fromDate+"&to_date=" + toDate+"&year="+aYear+"&period=" + aPeriod, this.httpOptions);
  }

  public getPeriodRepartition(aPersonId : number,fromDate: string, toDate: string,aYear: number,aPeriod : number) : Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/get_logs_period_repartition?person_id=" + aPersonId+"&from_date=" + fromDate+"&to_date=" + toDate+"&year="+aYear+"&period=" + aPeriod, this.httpOptions);
  }

  public getPeriodSuplement(aPersonId : number,fromDate: string, toDate: string,aYear: number,aPeriod : number) : Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/get_logs_period_supplements?person_id=" + aPersonId+"&from_date=" + fromDate+"&to_date=" + toDate+"&year="+aYear+"&period=" + aPeriod, this.httpOptions);
  }

  public getTimesheetLogs(aPersonId : number,fromDate: string, toDate: string,aYear: number,aPeriod : number) : Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/get_logs_timesheet?person_id=" + aPersonId+"&from_date=" + fromDate+"&to_date=" + toDate+"&year="+aYear+"&period=" + aPeriod, this.httpOptions);
  }

  public getPlanningLogs(aPersonId : number,fromDate: string, toDate: string,aYear: number,aPeriod : number) : Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/get_logs_planning?person_id=" + aPersonId+"&from_date=" + fromDate+"&to_date=" + toDate+"&year="+aYear+"&period=" + aPeriod, this.httpOptions);
  }

  public getEmailLogs(aPersonId : number,fromDate: string, toDate: string,aYear: number,aPeriod : number) : Observable<any> {
    return this.http.get<any>(this.tmsApiUrl + "/get_logs_Emails?person_id=" + aPersonId+"&from_date=" + fromDate+"&to_date=" + toDate+"&year="+aYear+"&period=" + aPeriod, this.httpOptions);
  }




  



}
