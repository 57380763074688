import { Item } from "./item";

export class Service extends Item {
    id: number;
    name: string;
    departmentId: number;
    departmentName: string;
    companyId: number;
    companyName: string;
    hasTimesheet : boolean;
    hasHRInterface : boolean;

    constructor(anId: number, aName: string, aDepId: number, aDepName: string, aCompId: number, aCompName: string, aFlag: boolean,  aHasTS : boolean, aHasHR : boolean, aHasPark: boolean) {
        super(aFlag, aHasTS, aHasHR,aHasPark);
        this.id = anId;
        this.name = aName;
        this.departmentId = aDepId;
        this.departmentName = aDepName;
        this.companyId = aCompId;
        this.companyName = aCompName;
        this.activeFlag = aFlag;
    }
}


export class ServiceHeader {
    department_name: string;
    responsible_name: string;
    service_id: number;
    service_name: string;

    constructor() {

    }
}

export class ServiceInfos {
    am_from_time: string;
    am_to_time: string;
    archive_enabled_flag: boolean;
    archive_flag: boolean;
    create_by: number;
    create_date: string;
    delete_enabled_flag: boolean;
    department_id: number;
    fact_length_flag: boolean;
    is_deleted_flag: boolean;
    is_service_automanaged: boolean;
    mod_by: number;
    mod_date: string;
    next_period_flag: boolean;
    next_period_type_val: string;
    next_period_val: string;
    only_sup_presta_flag: boolean;
    period_flag: boolean;
    period_type_val: string;
    period_type_desc: string;
    pm_from_time: string;
    pm_to_time: string;
    pointing_flag: boolean;
    presta_flag: boolean;
    responsible_id: number;
    responsible_name: string;
    section_num: string;
    service_desc: string;
    service_id: number;
    service_name: string;
    validation_level: string;

    constructor() {
        this.am_from_time = "00:00";
        this.am_to_time = "00:00";
        this.archive_enabled_flag = false;
        this.archive_flag = false;
        this.create_by = -1;
        this.create_date = "";
        this.delete_enabled_flag = false;
        this.department_id = -1;
        this.fact_length_flag = false;
        this.is_deleted_flag = false;
        this.is_service_automanaged = false;
        this.mod_by = -1;
        this.mod_date = "";
        this.next_period_flag = false;
        this.next_period_type_val = "";
        this.next_period_val = "";
        this.only_sup_presta_flag = false;
        this.period_flag = false;
        this.period_type_val = "";
        this.period_type_desc = "";
        this.pm_from_time = "00:00";
        this.pm_to_time = "00:00";
        this.pointing_flag = false;
        this.presta_flag = false;
        this.responsible_id = -1;
        this.responsible_name = "";
        this.section_num = "";
        this.service_desc = "";
        this.service_id = -1;
        this.service_name = "";
        this.validation_level = "";
    }
}

export class ServiceMail {
    send_mail_flag: boolean;
    pk_id: string;
    nb_days: number;
    date_rel: string;
    mail_type: string;
    dest_type: string;
    dest_id: number;
    mail_frequency: string;
    only_if_sup_flag: boolean;

    constructor() {
        this.send_mail_flag = false;
    }
}

export class ServiceTM {
    person_id: number;
    person_name: string;
    to_add: boolean;

    constructor(aPersId: number, aPersName: string, addFlag: boolean) {
        this.person_id = aPersId;
        this.person_name = aPersName;
        this.to_add = addFlag;
    }

}

export class ServiceGrid {
    count_val: number;
    create_by: number;
    create_date: string;
    day_val: number;
    from_time: string;
    function_desc: string;
    function_id: number;
    grid_id: number;
    is_deleted_flag: boolean;
    mod_by: number;
    mod_date: string;
    service_id: number;
    task_desc: string;
    task_id: number;
    to_time: string;
    constructor() {
    }
}

export class ServiceMember {
    person_id: number;
    person_name: string;
    archive_flag: boolean;
    constructor() {
    }
}

export class ServiceDict {
    record: string;
    rptName: string;
    tmgArchived: string;
    sectionN: string;
    responsible: string;
    taskMgmtDesc: string;
    timeManagers: string;
    new: string;
    labelOther: string;
    validationLevel: string;
    rptServ: string;
    department: string;
    yearPeriod: string;
    moveToAYearPeriod: string;
    fromPeriod: string;
    typeOfPeriod: string;
    fourWeeks: string;
    fourWeeksPeriod: string;
    oneYear: string;
    oneYearPeriod: string;
    perfomedTasksEncoding: string;
    onlyExtraHours: string;
    periodEncoding: string;
    labelInvoicedTime: string;
    serviceAutomanaged: string;
    timePeriod: string;
    default: string;
    AM: string;
    PM: string;
    mailPeriodEnd: string;
    mailReminderEncoder: string;
    delayPeriodEnd: string;
    days: string;
    recipient: string;
    employee: string;
    supervisor: string;
    frequency: string;
    once: string;
    everyday: string;
    mailReminderVal1: string;
    onlyIfSup: string;
    serviceParameters: string;
    labelWorkHours: string;
    labelOvertime: string;
    labelBankHoliday: string;
    labelNightHours: string;
    labelOnCallHours: string;
    labelWorkHoursOnCall: string;
    labelSundayHours: string;
    labelNights: string;
    labelDaysAbroad: string;
    labelHPylone: string;
    labelMPylone: string;
    labelFMaj: string;
    labelFMajYrRest: string;
    initGrid: string;
    total: string;
    newContractEmp: string;
    tmgSave: string;
    titleDelete: string;
    cancel: string;
    nameAlreadyExist: string;
    fillServiceName: string;
    serviceManagerNotSpecified: string;
    deleteServiceQuestion:string;
    confirmDeleteRole : string;

    constructor() {
        this.record = "";
        this.rptName = "";
        this.tmgArchived = "";
        this.sectionN = "";
        this.responsible = "";
        this.taskMgmtDesc = "";
        this.timeManagers = "";
        this.new = "";
        this.labelOther = "";
        this.validationLevel = "";
        this.rptServ = "";
        this.department = "";
        this.yearPeriod = "";
        this.moveToAYearPeriod = "";
        this.fromPeriod = "";
        this.typeOfPeriod = "";
        this.fourWeeks = "";
        this.fourWeeksPeriod = "";
        this.oneYear = "";
        this.oneYearPeriod = "";
        this.perfomedTasksEncoding = "";
        this.onlyExtraHours = "";
        this.periodEncoding = "";
        this.labelInvoicedTime = "";
        this.serviceAutomanaged = "";
        this.timePeriod = "";
        this.default = "";
        this.AM = "";
        this.PM = "";
        this.mailPeriodEnd = "";
        this.mailReminderEncoder = "";
        this.delayPeriodEnd = "";
        this.days = "";
        this.recipient = "";
        this.employee = "";
        this.supervisor = "";
        this.frequency = "";
        this.once = "";
        this.everyday = "";
        this.mailReminderVal1 = "";
        this.onlyIfSup = "";
        this.serviceParameters = "";
        this.labelWorkHours = "";
        this.labelOvertime = "";
        this.labelBankHoliday = "";
        this.labelNightHours = "";
        this.labelOnCallHours = "";
        this.labelWorkHoursOnCall = "";
        this.labelSundayHours = "";
        this.labelNights = "";
        this.labelDaysAbroad = "";
        this.labelHPylone = "";
        this.labelMPylone = "";
        this.labelFMaj = "";
        this.labelFMajYrRest = "";
        this.initGrid = "";
        this.total = "";
        this.newContractEmp = "";
        this.tmgSave = "";
        this.titleDelete = "";
        this.cancel = "";
        this.nameAlreadyExist= "";
        this.fillServiceName= "";
        this.serviceManagerNotSpecified= "";
        this.deleteServiceQuestion="";
        this.confirmDeleteRole ="";
    }
}




