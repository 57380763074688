<div class="fullsize-popup-container noselect">
    <app-people *ngIf="isPeopleDisplayed" [archiveFlag]="true" [managedPeopleFlag]="false" (close)="closePeople()" (add)="addPeople($event)">
    </app-people>
    <div id="ndl-main">
        <div id="ndl-header">
            <span class="ndl-header-title">
                <img>
                <label>{{dictionary.newRightsDelegation}}</label>
            </span>
            <button (click)="closeWindow()">X</button> 
        </div>
        <div id="ndl-body">
            <div id="ndl-hold" class="ndl-field">
                <label>{{dictionary.holder}}</label>
                <input type="text" (click)="choosePeople('titulaire')" [(ngModel)]="delegation.titulaire_name"  [disabled]="!isAdmin"> 
            </div>
            <div id="ndl-delg" class="ndl-field">
                <label>{{dictionary.delegate}}</label>
                <input type="text" (click)="choosePeople('delegate')" [(ngModel)]="delegation.delegate_name" [disabled]="!isAdmin">
            </div>
            <div id="ndl-dates" >
                <div id="ndl-date-left">
                <label>{{dictionary.from}} </label>
                <input type="date" [(ngModel)]="curFromDate" > 
            </div>
            <div id="ndl-date-right">
                <label>{{dictionary.until}}</label>
                <input type="date" [(ngModel)]="curToDate" > 
            </div>
            </div>
            <div id="ndl-save">
                <button (click)="select()">{{dictionary.tmgSave}}</button> 
            </div>
        </div>
    </div>
</div>
