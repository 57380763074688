<section>
    <div class="imgBx">
        <img src="./assets/img/login-bg.jpg">
        <!--<img src="/assets/img/ant1-background.jpg">-->
    </div>
    <div class="contentBx">
        <div class="formBx">
            <h2>TMS <span>Admin</span></h2>
            <form [formGroup]="loginForm">
                <div class="inputBx">
                    <span>Login</span>
                    <input type="text" placeholder="Login" formControlName="login">
                </div>
                <div class="inputBx">
                    <span>Password</span>
                    <input type="password" placeholder="Password" formControlName="password">
                </div>
                <div class="remember">
                    <label><input type="checkbox" name=""> Remember me</label>
                </div>
                <div class="inputBx">
                    <input type="submit" value="Sign in" (click)="submit()">
                </div>
                <div class="inputBx">
                    <!--<p>Password lost ? <a href="#">Reset here</a></p>-->
                    <p>Need help ? <a href="mailto:timesheet_support@bce.lu">Contact us</a></p>
                </div>
                <div class="inputBx compagny">
                    <p><span>powered by</span> <a href="https://bce.lu" target="_blank"><img src="./assets/img/bce.png" alt="BCE"></a></p>
                </div>
            </form>
        </div>
    </div>
</section>