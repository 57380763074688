export class Signaletic {
    bareme: string;
    company: string;
    cost_center: string;
    department: string;
    email_address: string;
    etp_val: number;
    expiry_date: string;
    external_user_code: string;
    first_name: string;
    gender: string;
    is_deleted_flag: false
    last_name: string;
    mod_date: string;
    position: string;
    position_entry_date: string;
    ref_primary_id: string;
    salutation: string;
    service: string;
    status: string;
    sub_department: string;
    supervisor: string;
    workschedule: string;
}

export class SignaleticHist {
    external_user_code: string;
    from_date: string;
    mod_date: string;
    signal_field_id: number;
    signal_field_name: string;
    signal_field_value: string;
    signal_history_id: string;
    to_date: string;
}

export class SignaleticDict {
    signaletics: string;
    inactivePeopleEntities: string;
    labelSearch: string;
    french: string;
    english: string;
    logout: string;
    options : string;
    currentLang : string;
    changeLine : string;
    finished : string;
    tool : string;
    report: string;
    profilesManagement : string;
    delegations : string;
    publicHolidays : string;

    constructor() {
        this.signaletics = "";
        this.inactivePeopleEntities = "";
        this.labelSearch = "";
        this.french = "";
        this.english = "";
        this.logout = "";
        this.options ="";
        this.currentLang = "";
        this.changeLine = "";
        this.finished ="";
        this.tool = "";
        this.report = "";
        this.profilesManagement = "";
        this.delegations = "";
        this.publicHolidays = "";
    }
}