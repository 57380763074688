import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SignaleticDict } from '@app/models';
import { DictService, TmsApiService,UserService } from '@app/services';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  currentLanguage: string;
  showSubMenu : boolean;
  languagesShown : boolean;
  isOptionMenuOpen : boolean;

  constructor(private userService: UserService, private dictService: DictService, private tmsApiService: TmsApiService, private router: Router,) {
   }

  ngOnInit(): void {
    this.currentLanguage = this.userService.currentUserValue.language;
    this.showSubMenu = false;
    this.languagesShown = false;
    this.isOptionMenuOpen = false;
  }

  get dictionary(): SignaleticDict {
    return this.dictService.getSignaleticDict();
  }

  get dicoLoaded(): boolean {
    return this.dictService.dicoIsLoaded;
  }

  logout() {
    this.userService.logout();
  }

  get currentTool() : string {

    let splittedRoute : string[] = this.router.url.split('/')
    
    switch(splittedRoute[splittedRoute.length-1]){
      case "profiles" : return " > " + this.dictionary.profilesManagement;
      case "signaletics" : return " > "+ this.dictionary.signaletics;
      case "delegations" : return " > "+ this.dictionary.delegations;
      case "logs" : return " > "+ "Logs";
      case "publicHolidays" : return  " > "+ this.dictionary.publicHolidays;
      default : return "";
    }
  }

  goToTools()
  {
    this.router.navigate(['/admin']);
  }

  openOptionMenu(aFlag : boolean) {
    this.isOptionMenuOpen = aFlag;
  }

  clickSubMenu() {
    this.showSubMenu = !this.showSubMenu;
  }

  setLanguageShown(aValue : boolean) {
    this.languagesShown = aValue;
  }

  setLanguage(aLang : string) {

    let data: any = {
      user_id: this.userService.userId,
      language_val: aLang
    };
    this.tmsApiService.postChangeLanguage(data).subscribe(response => {
      if (response) {
        this.dictService.loadDict();
        this.currentLanguage = aLang;
      }
    });

  }

}
