export class Supplement {

    constructor() { }
}

export class SupplementParam {
    bank_holiday_flag: boolean;
    create_by: number;
    create_date: string;
    fmaj_flag: boolean;
    hpylone_flag: boolean;
    mod_by: number;
    mod_date: string;
    mpylone_flag: boolean;
    night_hours_flag: boolean;
    nights_flag: boolean;
    on_call_hours_flag: boolean;
    overtime_flag: boolean;
    service_id: number;
    sunday_hours_flag: boolean;
    work_hours_flag: boolean;
    work_hours_on_call_flag: boolean;

    constructor() {
        this.bank_holiday_flag = true;
        this.create_by = -1;
        this.create_date = "";
        this.fmaj_flag = true;
        this.hpylone_flag = true;
        this.mod_by = -1;
        this.mod_date = "";
        this.mpylone_flag = true;
        this.night_hours_flag = true;
        this.nights_flag = true;
        this.on_call_hours_flag = true;
        this.overtime_flag = true;
        this.service_id = -1;
        this.sunday_hours_flag = true;
        this.work_hours_flag = true;
        this.work_hours_on_call_flag = true;
    }
}

export class SupplementProfile {
    supplement_profile_id: number;
    profile_name: string;
    profile_country: string;
    night_from_time: string;
    night_to_time: string;
    limit_day_hours: number;
    limit_week_hours: number;
    is_deleted_flag: boolean;
    constructor() {
        this.supplement_profile_id = -1;
        this.profile_name = "";
        this.profile_country = "";
        this.night_from_time = "";
        this.night_to_time = "";
        this.limit_day_hours = -1;
        this.limit_week_hours = -1;
        this.is_deleted_flag = false;
    }
}