import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Delegate, Delegation, Holder, People } from '@app/models';
import { DelegationDict } from '@app/models/delegation';
import { DictService, TmsApiService, UserService } from '@app/services';
import { fromEvent } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-delegations',
  templateUrl: './delegations.component.html',
  styleUrls: ['./delegations.component.scss']
})
export class DelegationsComponent implements OnInit {

  @ViewChild('inputSearch') inputSearch: ElementRef;

  curPersFullName: string;
  curTab: string;
  curDelegates: Delegate[];
  curHolders: Holder[];
  curDelegation: Delegation;
  curDelegations: Delegation[];
  searchText: string;
  isLoadingDelegates: boolean;
  isLoadingHolder: boolean;
  isLoadingDelegations: boolean;
  isPeopleDisplayed: boolean;
  isNewDelegDisplayed: boolean;
  displayInactive: boolean;
  curLogs: any[];
  isAdmin: boolean;
  isHooked: boolean;
  sortColumn: string;
  sortOrder: boolean;

  constructor(private userService: UserService, private tmsApiService: TmsApiService, private dictService: DictService) { }

  ngOnInit(): void {
    this.curPersFullName = this.userService.fullName;
    this.curDelegates = [];
    this.curHolders = [];
    this.curDelegations = [];
    this.curDelegation = new Delegation();
    this.searchText = "";
    this.curLogs = null;
    this.isLoadingDelegates = false;
    this.isLoadingHolder = false;
    this.isLoadingDelegations = false;
    this.isPeopleDisplayed = false;
    this.isNewDelegDisplayed = false;
    this.isAdmin = false;
    this.displayInactive = false;
    this.isHooked = false;
    this.sortColumn = "";
    this.sortOrder = false;

    if(this.hasTimesheet || this.isUserSA) {
      this.selectTab("tms");
    } else {
      this.selectTab("plan");
    }
  }

  ngAfterViewChecked() {
    if (this.isAdmin && !this.isHooked) {
      this.isHooked = true;
      fromEvent(this.inputSearch.nativeElement, 'input')
        .pipe(
          filter(Boolean),
          debounceTime(250),
          distinctUntilChanged(),
          tap((event) => {
            this.searchText = this.inputSearch.nativeElement.value;
            this.changeSearch()
          })
        )
        .subscribe();
    }
  }

  get searchMod(): boolean {
    return this.searchText.trim().length > 0
  }

  get dictionary(): DelegationDict {
    return this.dictService.getDelegationDict();
  }

  changeSearch() {
    if (this.searchMod) {
      this.filterDelegations();
    }
  }

  filterDelegations() {
    for (const delegation of this.curDelegations) {
      delegation.isFiltered = (delegation.titulaire_name.toUpperCase().includes(this.searchText.toUpperCase())
        || delegation.delegate_name.toUpperCase().includes(this.searchText.toUpperCase()));
    }
  }

  getFilteredDelegations() {
    if (this.searchMod) {
      return this.curDelegations.filter((p) => {
        return p.isFiltered;
      })
    } else {
      return this.curDelegations;
    }
  }

  public get userId(): number {
    return this.userService.userId;
  }

  public get isUserSA(): boolean {
    return this.userService.isSa;
  }

  public get hasTimesheet(): boolean {
    return this.userService.hasTimesheet;
  }

  public selectTab(aTabName: string) {
    this.curTab = aTabName;
    this.getData(this.userId, aTabName, this.displayInactive);
  }

  displayPeople() {
    this.isPeopleDisplayed = true;
  }

  closePeople() {
    this.isPeopleDisplayed = false;
  }

  addPeople(aPeople: People) {
    this.curDelegation.delegate_id = aPeople.person_id;
    this.curDelegation.delegate_name = aPeople.person_name;
    this.curDelegation.titulaire_id = this.userId;
    this.curDelegation.titulaire_name = this.curPersFullName;
    if (!this.isAdmin) this.isNewDelegDisplayed = true;
  }

  openNewDeleg() {
    this.isNewDelegDisplayed = true;
  }

  closeNewDeleg() {
    this.isNewDelegDisplayed = false;
    if (!this.isAdmin)  this.isPeopleDisplayed = true;
  }

  saveDeleg(aDeleg: Delegation) {
    if (aDeleg) {
      this.curDelegation = aDeleg
      this.isNewDelegDisplayed = false;
      this.isPeopleDisplayed = false;

      this.addDelegation(this.curDelegation, this.curTab);
    }
  }

  getData(aPersId: number, aDelType: string, aFlag: boolean) {
    this.curDelegation = new Delegation();
    if (this.isAdmin) {
      this.getDelegations(aDelType, aFlag)
    }
    else {
      this.getDelegates(aPersId, aDelType);
      this.getHolders(aPersId, aDelType);
    }
  }

  delete(aDelId: number, aDelType: string) {
    let confirm = window.confirm(this.dictionary.deleteDelegQuestion);

    if (confirm) {
      this.deleteDelegate(aDelId, aDelType);
    }
  }

  sortTable(columnName: string, order: boolean) {
    this.sortColumn = columnName;

    if (order == true) {
      this.curLogs.sort((a, b) => a[columnName] > b[columnName] ? 1 : a[columnName] < b[columnName] ? -1 : 0)
    }
    else {
      this.curLogs.sort((a, b) => a[columnName] < b[columnName] ? 1 : a[columnName] > b[columnName] ? -1 : 0)
    }
    this.sortOrder = !order
  }

  //API CALLS

  getDelegates(aPersId: number, aDelType: string): void {
    this.isLoadingDelegates = true;
    this.curDelegates = [];
    this.curLogs = [];
    this.tmsApiService.getDelegates(aPersId, aDelType).subscribe((response) => {
      if (response) {
        this.curDelegates = response;
      }
      this.isLoadingDelegates = false;
    });
  }

  getHolders(aPersId: number, aDelType: string): void {
    this.isLoadingHolder = true;
    this.curHolders = [];
    this.tmsApiService.getRights(aPersId, aDelType).subscribe((response) => {
      if (response) {
        this.curHolders = response;
      }
      this.isLoadingHolder = false;
    });
  }

  deleteDelegate(aDelId: number, aDelType: string): void {
    let data: any = {
      delegation_id: aDelId,
      tms_flag: aDelType == "tms"
    };

    this.tmsApiService.postDeleteDelegate(data).subscribe((response) => {
      if (response) {
        this.getData(this.userId, aDelType, this.displayInactive);
      }
    });
  }

  addDelegation(aDeleg: Delegation, aDelType: string): void {
    let data: any = {
      titulaire: { person_id: aDeleg.titulaire_id },
      delegate: { person_id: aDeleg.delegate_id },
      from_date: aDeleg.from_date,
      to_date: aDeleg.to_date,
      tms_flag: aDelType == "tms"
    };

    this.tmsApiService.postAddDelegation(data).subscribe((response) => {
      if (response) {
        this.getData(this.userId, aDelType, this.displayInactive);
      }
    });
  }

  getDelegations(aDelType: string, aFlag: boolean): void {
    this.isLoadingDelegations = true;
    this.curDelegations = [];
    this.tmsApiService.getDelegations(aDelType, aFlag).subscribe((response) => {
      if (response) {
        this.curLogs = response;
        this.curDelegations = response;
      }
      this.isLoadingDelegations = false;
    });
  }

}
